import { format } from 'date-fns';
import React, { Fragment } from 'react';
import { Datagrid, EditButton, List, NotFound, TextField, DeleteWithConfirmButton } from 'react-admin';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CustomButton from '../shared/CustomButton';
import CustomLabel from '../shared/CustomLabel';
import ActionDropdown from '../shared/ActionDropdown';

const ScheduledField = ({ record }) => {
    const { isScheduled, scheduledTime } = record;
    const dateTime = new Date(scheduledTime);

    return (
        <>
            {isScheduled ?
                <span>{format(dateTime, 'dd.MM.yyyy, HH:mm')}</span>
                :
                <span>-</span>

            }
        </>
    );
};

// const DeleteConfirmationDialog = ({ record = {}, ...props }) => {
//     const { title } = record;
//     const dialogTitle = `Delete announcement "${title}"`;

//     return (
//         <DeleteWithConfirmButton confirmTitle={dialogTitle} {...props} record={record} />
//     );
// };

const AnnouncementsList = props => {
    const permissions = useSelector(state =>
        state.permissions
    );
    const history = useHistory();

    const StatusField = ({ record }) => {
        const { isScheduled, isPublished } = record;

        if (isPublished && isScheduled) {
            return <span style={{ color: '#f6bd60' }}>Scheduled</span>;
        }
        if (isPublished && !isScheduled) {
            return <span style={{ color: '#62C333' }}>Published</span>;
        }

        return <span style={{ color: 'rgb(177, 167, 166)' }}>Draft</span>
    };

    const handleCreate = () => {
        history.push('announcements/create');
    }

    return (
        <Fragment>
            {permissions === 'admin' ? (
                <Fragment>
                    <CustomLabel title={'Announcements'} />
                    <List
                        {...props}
                        actions={<CustomButton basePath={'/announcements'} type="primary" fullWidth={true} label="Create new" onClick={handleCreate} style={{ marginBottom: 30 }} />}
                        bulkActionButtons={false}
                        sort={{ field: 'id', order: 'DESC' }}
                    >
                        <Datagrid>
                            <TextField source="id" label="ID" />
                            <TextField source="title" />
                            <TextField source="description" label="Short description" />
                            <StatusField label="Status" />
                            <ScheduledField label="Scheduled for" source="scheduledTime" />
                            <ActionDropdown label="Actions" entity="announcement"/>
                        </Datagrid>
                    </List>
                </Fragment>
            ) : <NotFound />}
        </Fragment>
    );
};

export default AnnouncementsList;