import React, { useState, Fragment } from 'react';
import { Datagrid, TextField, DateField, useQuery, Pagination, Loading, useNotify } from 'react-admin';
import keyBy from 'lodash/keyBy';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import config from '../../../config/config';
import CustomLabel from '../../shared/CustomLabel';
import CustomButton from '../../shared/CustomButton';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    completed: { color: '#80C783' },
    pending: { color: '#5EBDE9' },
    ordered: { color: '#58BBB1' },
    canceled: { color: '#db574d' },
    paid: { color: '#58bbb1'},
    search: { display: 'flex', width: '100%', justifyContent: 'flex-end' },
    customerField: { width: '12%' },
    noResults: { fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)', borderRadius: '4px', padding: '16px', marginTop: '15px' },
    completeOrderField: { width: '15%' }
});


const OrderStatusField = props => {
    const classes = useStyles();
    const statusValues = {
        0: 'Pending',
        1: 'Ordered',
        2: 'Completed',
        3: 'Cancelled'
    };

    const { status } = props.record;
    const orderStatus = statusValues[status];

    return (
        <span
            className={classnames({
                [classes.pending]: status === 0, // pending
                [classes.ordered]: status === 1, // ordered
                [classes.completed]: status === 2, // completed
                [classes.canceled]: status === 3 // canceled
            })}
        >
            {orderStatus}
        </span>
    );
};

const PaidAmountField = ({ record = {} }) => {
    const { doorPrice } = record;
    return (<span>
        {doorPrice}
    </span>)
}

const PaymentStatusField = props => {
    const classes = useStyles();
    const statusValues = {
        0: 'Not Paid',
        1: 'Paid',
        2: 'Refunded',
        3: 'PartiallyRefunded',
        4: 'Locked'
    };

    const { paymentStatus } = props.record;
    const orderPaymentStatus = statusValues[paymentStatus];

    return (
        <span
            className={classnames({
                [classes.canceled]: paymentStatus === 0, // not paid
                [classes.paid]: paymentStatus === 1, // paid
                [classes.pending]: paymentStatus === 2, // refunded
                [classes.ordered]: paymentStatus === 3 // partially refunded
            })}
        >
            {orderPaymentStatus}
        </span>
    );
};

const zeroPad = (num, places) => String(num).padStart(places, '0')

const DurationField = ({ record = {} }) => {
    const { usedFrom, usedTo } = record;

    let duration = "00:00:00";
    if(usedTo) {
        const usedToDate = new Date(usedTo);
        const usedFromDate = new Date(usedFrom);

        let durationSeconds = Math.floor((usedToDate.getTime() - usedFromDate.getTime()) / 1000);
        const hours = Math.floor(durationSeconds/3600);
        durationSeconds -= hours * 3600;
        const minutes = Math.floor(durationSeconds/ 60 );
        durationSeconds -= minutes * 60;
        
        duration = `${zeroPad(hours,2)}:${zeroPad(minutes,2)}:${zeroPad(durationSeconds,2)}`
    }

    return <span>{duration}</span>
}

const Customer = ({ record = {} }) => {
    const { invoiceName } = record;

    return <span style={{ color: '#62C333' }}>{invoiceName}</span>
};

const PhoneNumber = ({ record = {} }) => {
    const { invoicePhone } = record;

    return <span>{invoicePhone}</span>
};

const CompleteOrder = ({ record = {}, handleRefresh }) => {
    const notify = useNotify();

    const { id, status } = record;

    const completeOrder = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Order/ChangeOrderStatusToCompleted?orderId=${id}`;

        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                    handleRefresh();
                    return notify('Order completed');
                }
            })
            .catch(error => {
                notify(error.message, 'warning');
            });
    };

    return (
        <Fragment>
            {!(status === 2 || status === 3) && (
                <CustomButton
                    label="Complete order"
                    type="primary"
                    onClick={(e) => completeOrder(e)}
                    style={{ width: 'auto', height: 26, lineHeight: 1 }}
                />
            )}
        </Fragment>
    );
};

const LocationOrders = ({ id }) => {
    const classes = useStyles();
    const history = useHistory();

    const permissions = useSelector(state => state.permissions);
    const hasAccess = (permissions === 'admin');

    const [refresh, setRefresh] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({ field: 'creationTime', order: 'DESC' });

    const changePerPage = (value) => {
        setPage(1);
        setPerPage(value);
    };

    const changeSort = (field) => {
        setPage(1);
        const order = sort.order === 'ASC' ? 'DESC' : 'ASC';
        setSort({ field, order });
    };

    const handleRefresh = () => {
        setRefresh(refresh + 1);
    };

    const { data, total, loading, error } = useQuery({
        type: 'GET_LIST',
        resource: 'orders',
        payload: {
            pagination: { page, perPage },
            sort,
            filter: {
                'LocationId': id,
                'showAll': true
            }
        }
    }, [refresh]);

    if (loading) {
        return <Loading />
    }

    if (error) {
        return <p>ERROR: {error}</p>
    }

    const goToOrder = (id) => {
        history.push(`/orders/${id}/show`)
    };


    return (
        <Fragment>
            {(data && data.length) ? (
                <Fragment>
                    <CustomLabel title={'Location Orders'} style={{ marginBottom: 20 }} />
                    <Datagrid
                        data={keyBy(data, 'id')}
                        ids={data.map(({ id }) => id)}
                        currentSort={sort}
                        setSort={changeSort}
                        rowClick={goToOrder}
                    >
                        <TextField source="id" label="ID" />
                        <Customer label="Customer" headerClassName={classes.customerField} />
                        <PhoneNumber label="Phone Number" headerClassName={classes.customerField} />
                        <DateField source="creationTime" label="Order date" locales="nb-NO" />
                        <OrderStatusField source="status" />
                        <PaymentStatusField source="paymentStatus" />
                        <PaidAmountField label="Paid" source="doorPrice"/>
                        <DurationField label="Duration"/>
                        {hasAccess && (
                            <CompleteOrder label="" headerClassName={classes.completeOrderField} handleRefresh={handleRefresh} />
                        )}
                    </Datagrid>
                    <Pagination
                        page={page}
                        perPage={perPage}
                        setPage={setPage}
                        setPerPage={changePerPage}
                        total={total}
                    />
                </Fragment>
            ) : <div className={classes.noResults}>No results found</div>}
        </Fragment>
    );
};

const LocationsOrdersTab = ({ record, activeTab }) => {
    if(activeTab.name !== 'orders') return null;
    const { id } = record;
    return (
        <LocationOrders id={id} />
    );
};

export default LocationsOrdersTab;