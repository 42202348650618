import React, { Fragment, useEffect, useState } from 'react';
import { TextInput, SelectInput, NumberInput, required } from 'react-admin';

import config from '../../../../config/config';
import { useFormState } from 'react-final-form'

import { makeStyles } from '@material-ui/core';

const styles = {
    flexContainer: { width: '100%', minHeight: '56vh' },
    inputField: { width: '48%' },
    row: { display: 'flex', justifyContent: 'space-between', marginBottom: '18px', width: '100%' },
    description: { marginBottom: '0' },
};

const useStyles = makeStyles(styles);

const DoorsLockDetailsTab = () => {
    const classes = useStyles();
    const formState = useFormState();
    const { values } = formState;
    const [locks, setLocks] = useState([]);
    const [selectedLock] = useState(values.lockType);

    useEffect(() => {
        if (selectedLock === 1) return; // don't fetch locks if selected lock type is PLC
    
        const token = localStorage.getItem('access_token');
        let endpoint = `${config.apiBaseUrl}/door/GetUnusedNBLocks`;
        if(selectedLock === 3) {
            endpoint = `${config.apiBaseUrl}/door/GetUnusedBLELocks`;
        }
        const request = new Request(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                if (response && response.result) {
                    const data = response.result;
                    let values = [];
                    Object.keys(data).forEach(item => {
                        values.push({
                            id: item,
                            name: `${item} - ${data[item]}`
                        });
                    });
                    setLocks(values);
                }
            });
        // eslint-disable-next-line
    }, [selectedLock]);

    return (
        <Fragment>
            <div className={classes.flexContainer}>
                <div className={classes.row}>
                    <TextInput
                        source="description"
                        fullWidth={true}
                        rows="4"
                        multiline
                        variant="outlined"
                        className={classes.inputField}
                    // validate={required()}
                    />
                </div>

                <div className={classes.row}>
                    {/* when selected lock type is 'NB lock' display dropdown with available locks */}
                    {values.lockType === 2 || values.lockType === 3 ? (
                        <SelectInput
                            source="localAccessKey"
                            fullWidth={true}
                            className={classes.inputField}
                            variant="standard"
                            choices={locks}
                            validate={required()}
                            key={values.lockType}
                        />
                    ) : (
                        <NumberInput
                            style={{ display: 'block' }}
                            source="localAccessKey"
                            label="Local access key for PLC device"
                            fullWidth={true}
                            className={classes.inputField}
                            variant="standard"
                            validate={required()}
                            key={values.lockType}
                            inputProps={{
                                min: 0
                            }}
                            format={v => (v < 0) ? 0 : v}
                        />
                    )}
                    <NumberInput
                        source="maxConcurrent"
                        fullWidth={true}
                        className={classes.inputField}
                        variant="standard"
                        defaultValue={1}
                        inputProps={{
                            min: 1
                        }}
                        format={v => (v === 0 || v < 0) ? 1 : v}
                    // validate={required()}
                    />

                </div>
            </div>
        </Fragment>
    );
};

export default DoorsLockDetailsTab;