import React, { Fragment, useState } from "react";
import { TextInput, SelectInput, Datagrid, TextField, DateField, useNotify } from "react-admin";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FormLabel, makeStyles, Dialog, DialogTitle, DialogContent, DialogContentText, IconButton } from "@material-ui/core";
import { darkTheme, lightTheme } from "../../layout/themes";

import keyBy from 'lodash/keyBy';
import CustomButton from "../../shared/CustomButton";
import CloseIcon from '@material-ui/icons/Close';
import config from "../../../config/config";
import classnames from 'classnames';

const PriceField = ({ record = {}, field }) => {
    let value = record[field] || 0;
    value = value.toFixed(2);

    return (
        <span>{config.currency} {value}</span>
    );
};
const useStyles = makeStyles({
    completed: { color: '#80C783' },
    pending: { color: '#5EBDE9' },
    ordered: { color: '#58BBB1' },
    canceled: { color: '#db574d' },
    paid: { color: '#58bbb1' },
    search: { display: 'flex', width: '100%', justifyContent: 'flex-end' },
    customerField: { width: '12%' },
    completeOrderField: { width: '15%' }
});

const PaymentStatusField = props => {
    const classes = useStyles();
    const statusValues = {
        0: 'Not Paid',
        1: 'Paid',
        2: 'Refunded',
        3: 'PartiallyRefunded',
        4: 'Locked'
    };

    try {
        const { status } = props.record;
        const orderPaymentStatus = statusValues[status];

        return (
            <span
                className={classnames({
                    [classes.canceled]: status === 0, // not paid
                    [classes.paid]: status === 1, // paid
                    [classes.pending]: status === 2, // refunded
                    [classes.ordered]: status === 3 // partially refunded
                })}
            >
                {orderPaymentStatus}
            </span>
        );
    } catch (e) {
        return (
            <span
                className={classnames({
                    [classes.canceled]: true, // not paid
                })}
            >
                No Payment Status
            </span>
        );
    }
};

const OrderDetailsTab = ({ record }) => {
    const theme = useSelector(state =>
        state.theme === "dark" ? darkTheme.palette : lightTheme.palette
    );
    const notify = useNotify();
    const history = useHistory();

    const permissions = useSelector(state => state.permissions);
    const hasAccess = (permissions === 'admin');

    const useStyles = makeStyles({
        formLabel: { marginTop: 20, marginBottom: 10, fontWeight: 700, color: theme.secondary.main },
        flexContainer: { width: '100%' },
        select: { maxWidth: '29%' },
        row: { display: 'flex', justifyContent: 'space-between', marginBottom: 40, width: '100%' },
        totalPriceRow: { display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: 40, width: '100%', fontSize: '14px' },
        priceRow: { width: '300px', display: 'flex', padding: '5px' },
        paymentDetailsDiv: { backgroundColor: 'grey', width: "100%", height: 300, marginTop: 20, marginBottom: 30 },
        dialog: { '& .MuiDialog-paperWidthSm': { margin: 0, width: 450, padding: 10 } },
        textField: { '& .MuiOutlinedInput-input': { padding: '12.5px 14px' }, width: 328 },
        closeBtn: { position: 'absolute', right: 0, top: 0 },
        dialogActions: { display: 'flex', justifyContent: 'flex-end', marginTop: 50 }
    });

    const classes = useStyles();

    const data = [record];
    const orderId = record.id;
    const [refundRecord, setRefundRecord] = useState(null);
    const [refundDialogOpen, setRefundDialogOpen] = useState(false);

    const TotalPrice = () => {
        const { doorPriceExVat, doorPriceVat, doorPrice } = record;

        return (
            <Fragment>
                <div className={classes.priceRow}>
                    <span style={{ flex: '2 0 0', fontWeight: '700' }}>Total ex VAT:</span> <span style={{ flex: '1 0 0', textAlign: 'right' }}>{config.currency} {doorPriceExVat ? doorPriceExVat.toFixed(2) : '0.00'}</span>
                </div>
                <div className={classes.priceRow}>
                    <span style={{ flex: '2 0 0', fontWeight: '700' }}>Total VAT:</span> <span style={{ flex: '1 0 0', textAlign: 'right' }}>{config.currency} {doorPriceVat ? doorPriceVat.toFixed(2) : '0.00'}</span>
                </div>
                <div className={classes.priceRow} style={{ backgroundColor: '#eee' }}>
                    <span style={{ flex: '2 0 0', fontWeight: '700' }}>Total</span> <span style={{ flex: '1 0 0', textAlign: 'right' }}>{config.currency} {doorPrice ? doorPrice.toFixed(2) : '0.00'}</span>
                </div>
            </Fragment>
        );
    };

    const RefundButton = ({ record }) => {
        const { status } = record;

        return (
            <Fragment>
                {status === 1 && (
                    <CustomButton
                        label="Refund"
                        type="primary"
                        onClick={() => {
                            setRefundRecord(record);
                            setRefundDialogOpen(true);
                        }}
                        style={{ width: 'auto', height: 33, margin: '5px 0', padding: '5px 10px' }}
                    />
                )}
                {status === 2 && (
                    <span>Refunded</span>
                )}

            </Fragment>
        );
    };

    const RefundDialog = ({ open, record }) => {
        const classes = useStyles();

        const onRefund = (paymentId) => {
            const token = localStorage.getItem('access_token');
            const endpoint = `${config.apiBaseUrl}/Order/Refund`;
            const request = new Request(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    paymentId: paymentId
                })
            });

            fetch(request)
                .then(res => res.json())
                .then(response => {
                    setRefundRecord(null);
                    setRefundDialogOpen(false);
                    if (response.success) {

                        history.go(0);
                        notify('Order refund success', 'success');
                    } else {
                        notify(response.error.message, 'error');
                    }
                });
        };

        const handleClose = () => {
            setRefundRecord(null);
            setRefundDialogOpen(false);
        };

        if(record === null) {
            return (<></>)
        }
        return (
            <Fragment>
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className={classes.dialog}>
                    <DialogTitle id="simple-dialog-title">
                        Payment refund
                        <IconButton onClick={handleClose} className={classes.closeBtn}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {`Are you sure you want to refund ${record.amount} from order ${record.orderId}?`}
                        </DialogContentText>
                        <div className={classes.dialogActions}>
                            <CustomButton label="Cancel" type="default" onClick={handleClose} style={{ marginRight: 20, width: 91 }} />
                            <CustomButton label="Refund" type="primary" onClick={onRefund(record.id)} style={{ width: 91 }} />
                        </div>
                    </DialogContent>
                </Dialog>
            </Fragment>
        )
    };
    console.log(data[0])
    return (
        <Fragment>
            <FormLabel component="legend" className={classes.formLabel}>Order details</FormLabel>
            <div className={classes.flexContainer}>
                <div className={classes.row}>
                    <SelectInput
                        source="status"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        choices={[
                            { id: 0, name: 'Pending' },
                            { id: 1, name: 'Ordered' },
                            { id: 2, name: 'Completed' },
                            { id: 3, name: 'Cancelled' },
                            { id: 4, name: 'Wait device to complete order' }
                        ]}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <SelectInput
                        source="status"
                        label="Payment status"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        choices={[
                            { id: 0, name: 'Not Paid' },
                            { id: 1, name: 'Paid' },
                            { id: 2, name: 'Refunded' },
                            { id: 3, name: 'Partially Refunded' }
                        ]}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextInput
                        label="Customer"
                        source="invoiceName"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        InputProps={{
                            readOnly: true
                        }}
                    />
                </div>
            </div>
            <FormLabel component="legend" className={classes.formLabel}>Order</FormLabel>
            <div className={classes.flexContainer}>
                <div className={classes.row} style={{ marginBottom: '20px' }}>
                    <Datagrid
                        data={keyBy(data, 'id')}
                        ids={data.map(({ id }) => id)}
                        currentSort={{ field: 'id', order: 'ASC' }}
                    >
                        <TextField source="location.name" label="Location name" sortable={false} />
                        <TextField source="doorId" label="Door ID" sortable={false} />
                        <TextField source="door.name" label="Door name" sortable={false} />
                        <DateField source="usedFrom" locales="nb-NO" showTime sortable={false} />
                        <DateField source="usedTo" locales="nb-NO" showTime sortable={false} />
                        <PriceField field="doorPrice" label="Price" sortable={false} />
                        <PriceField field="doorPriceVat" label="VAT" sortable={false} />
                        <PriceField field="doorPrice" label="Total" sortable={false} />
                    </Datagrid>
                </div>
                <div className={classes.totalPriceRow}>
                    <TotalPrice />
                </div>
            </div>
            <FormLabel component="legend" className={classes.formLabel}>Payments</FormLabel>
            <div className={classes.flexContainer}>
                <div className={classes.row} style={{ marginBottom: '20px' }}>
                    <Datagrid
                        data={keyBy(data[0].payments, 'id')}
                        ids={data[0].payments.map(({ id }) => id)}
                        currentSort={{ field: 'id', order: 'ASC' }}
                    >
                        <TextField source="id" label="Payment ID" sortable={false} />
                        <TextField source="reference" label="Reference" sortable={false} />
                        <DateField source="creationTime" locales="nb-NO" label="Paid at" showTime sortable={false} />
                        <PaymentStatusField source="status" label="Status" sortable={false} />
                        <TextField source="amount" label="Amount" sortable={false} />

                        {hasAccess && (
                            <RefundButton label={'Refund'} />
                        )}
                    </Datagrid>
                </div>

            </div>
            <RefundDialog open={refundDialogOpen} record={refundRecord} />
        </Fragment>
    );
};

export default OrderDetailsTab;