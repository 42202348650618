import React, { Fragment, useState } from 'react';
import { List, Datagrid, TextField, DateField, Filter, useNotify, refreshView, AutocompleteInput, useQuery, Pagination, BooleanInput } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../config/config';
import CustomLabel from '../shared/CustomLabel';
import CustomButton from '../shared/CustomButton';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    completed: { color: '#80C783' },
    pending: { color: '#5EBDE9' },
    ordered: { color: '#58BBB1' },
    canceled: { color: '#db574d' },
    paid: { color: '#58bbb1' },
    search: { display: 'flex', width: '100%', justifyContent: 'flex-end' },
    customerField: { width: '12%' },
    completeOrderField: { width: '15%' }
});

const PostPagination = () => <Pagination
    rowsPerPageOptions={[30, 50, 100]}
/>;

const FilterByLocation = props => {
    const notify = useNotify();

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(1000);
    const [total, setTotal] = useState(0);
    const [locations, setLocations] = useState(null);

    useQuery(
        {
            type: 'GET_LIST',
            resource: 'locations',
            payload: {
                pagination: {
                    page,
                    perPage
                },
                sort: {
                    field: 'name',
                    order: 'ASC'
                }
            }
        },
        {
            onSuccess: ({ data, total }) => {
                setLocations(data)
                setTotal(total);
            },
            onFailure: (error) => notify(error.message, 'warning')
        }
    );

    return (
        <Filter {...props} variant="outlined">
            <AutocompleteInput
                label="Location"
                source="locationId"
                emptyText={<span style={{ color: '#adadad' }}>Reset</span>}
                alwaysOn
                resettable
                choices={locations || []}
            />
            <BooleanInput
                style={{ margin: 5 }}
                label="Show all orders"
                source="showAll"
                alwaysOn={true}
                resettable

            />
        </Filter>
    )
};

const CompleteOrder = ({ record = {} }) => {
    const notify = useNotify();
    const dispatch = useDispatch();

    const { id, status } = record;

    const completeOrder = (e) => {
        e.stopPropagation();

        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Order/ChangeOrderStatusToCompleted?orderId=${id}`;

        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                    dispatch(refreshView());
                    return notify('Order completed');
                }
            })
            .catch(error => {
                notify(error.message, 'warning');
            });
    };

    return (
        <Fragment>
            {!(status === 2 || status === 3) && (
                <CustomButton
                    label="Complete order"
                    type="primary"
                    onClick={completeOrder}
                    style={{ width: 'auto', height: 26, lineHeight: 1 }}
                />
            )}
        </Fragment>
    );
};


const ReactiveOrder = ({ record = {} }) => {
    const notify = useNotify();
    const dispatch = useDispatch();

    const { id, status } = record;

    const reactiveOrder = (e) => {
        e.stopPropagation();

        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Order/ChangeOrderStatusToOrderd?orderId=${id}`;

        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                    dispatch(refreshView());
                    return notify('Order reactivated');
                }
            })
            .catch(error => {
                notify(error.message, 'warning');
            });
    };

    return (
        <Fragment>
            {(status === 2 || status === 3) && (
                <CustomButton
                    label="Reactive order"
                    type="primary"
                    onClick={reactiveOrder}
                    style={{ width: 'auto', height: 26, lineHeight: 1 }}
                />
            )}
        </Fragment>
    );
};


const OrderStatusField = ({ record = {} }) => {
    const classes = useStyles();

    const statusValues = {
        0: 'Pending',
        1: 'Ordered',
        2: 'Completed',
        3: 'Cancelled',
        4: 'Wait device to complete order'
    };

    const { status } = record;
    const orderStatus = statusValues[status];

    return (
        <span
            className={classnames({
                [classes.pending]: status === 0, // pending
                [classes.ordered]: status === 1, // ordered
                [classes.completed]: status === 2 || status === 4, // completed
                [classes.canceled]: status === 3 // canceled
            })}
        >
            {orderStatus}
        </span>
    );
};

const PaymentStatusField = props => {
    const classes = useStyles();
    const statusValues = {
        0: 'Not Paid',
        1: 'Paid',
        2: 'Refunded',
        3: 'PartiallyRefunded',
        4: 'Locked'
    };

    try {
        const { paymentStatus } = props.record;
        const orderPaymentStatus = statusValues[paymentStatus];


        return (
            <span
                className={classnames({
                    [classes.canceled]: paymentStatus === 0, // not paid
                    [classes.paid]: paymentStatus === 1, // paid
                    [classes.pending]: paymentStatus === 2, // refunded
                    [classes.ordered]: paymentStatus === 3 // partially refunded
                })}
            >
                {orderPaymentStatus}
            </span>
        );
    } catch (e) {
        return (
            <span
                className={classnames({
                    [classes.canceled]: true, // not paid
                })}
            >
                No Payment Status
            </span>
        );
    }
};

const LocationDoorField = ({ record }) => (
    <span>{record?.location?.name} / {record?.door?.name}</span>
);

const UserNameField = ({ record = {} }) => {
    const history = useHistory();

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (record?.user?.id) {
            history.push(`/users/${record.user.id}`);
        }
    }

    return (
        <span style={{ color: '#80C783' }} onClick={handleClick}>
            {record?.user?.name} {record?.user?.surname}
        </span>
    );
};

const UserPhoneNumber = ({ record = {} }) => {
    return (
        <div style={{ color: 'black' }}>
            {record?.user?.userName}
        </div>
    );
};

const OrderList = props => {
    const classes = useStyles();
    const permissions = useSelector(state => state.permissions);
    const hasAccess = (permissions === 'admin');
    return (
        <>
            <CustomLabel title={'Orders'} />
            <List empty={false}
                pagination={<PostPagination />}
                perPage={30}
                sort={{ field: 'creationTime', order: 'DESC' }}
                filters={<FilterByLocation />}
                actions={null}
                {...props}
                bulkActionButtons={false}>
                <Datagrid rowClick="show">
                    <TextField source="id" label="ID" />
                    <UserNameField source="user" label="Customer" />
                    <UserPhoneNumber source="userName" label="Phone Number" />
                    <DateField source="creationTime" label="Order date" locales="nb-NO" />
                    <LocationDoorField label="Location/door" />
                    <OrderStatusField source="status" />
                    <PaymentStatusField source="paymentStatus" />
                    {hasAccess && (
                        <CompleteOrder label="" headerClassName={classes.completeOrderField} />
                    )}
                    {hasAccess && (
                        <ReactiveOrder label="" headerClassName={classes.completeOrderField} />
                    )}
                </Datagrid>
            </List>
        </>
    );
};

export default OrderList;