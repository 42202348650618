import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { TextInput, SelectInput, DateInput } from 'react-admin';

import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';

const UserDetailsTab = ({ labelColor, record, hasEditAccess }) => {
    const permissions = useSelector(state =>
        state.permissions
    );

    const { id } = record;
    const user_id = useSelector(state => state.user.id);
    const isBikelyCustomer = useSelector(state => state.bikelyCustomer);

    const styles = {
        formLabel: { marginTop: 20, marginBottom: 10, fontWeight: 700, color: labelColor },
        flexContainer: { width: '100%' },
        select: { maxWidth: '29%' },
        selectInput: { width: '29%' },
        dateField: { maxWidth: '29%' },
        row: { display: 'flex', justifyContent: 'space-between', marginBottom: 40, width: '100%' },
        lastRow: { display: 'flex', marginBottom: 40, width: '100%', alignItems: 'baseline', justifyContent: 'space-between', },
        addressesSection: { marginTop: 55, marginBottom: 40, width: '100%' },
        addAddressBtn: { marginTop: 18, marginLeft: 20, padding: "5px 30px", textTransform: 'capitalize' }
    };

    const useStyles = makeStyles(styles);
    const classes = useStyles();

    const accessLevels = [
        { id: 0, name: 'Read Access' },
        { id: 1, name: 'Edit Access' },
        { id: 2, name: 'Full Access' }
    ];

    const RoleType = ({ record }) => {
        const { isAdmin } = record;
        const value = isAdmin ? 'Administrator' : 'End User';

        return (
            <TextField
                label="Role"
                variant="standard"
                defaultValue={value}
                style={{ width: '29%' }}
                InputProps={{
                    readOnly: true,
                }}
            />
        );
    };

    return (
        <Fragment>
            <FormLabel component="legend" className={classes.formLabel}>Details</FormLabel>
            <div className={classes.flexContainer}>
                <div className={classes.row}>
                    <TextInput
                        source="id"
                        label="ID"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextInput
                        source="userName"
                        label="Username"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        InputProps={{
                            readOnly: true,
                        }}
                    />

                </div>
                <div className={classes.row}>

                    <TextInput
                        source="name"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        InputProps={{
                            readOnly: !hasEditAccess
                        }}
                    />
                </div>
                <div className={classes.row}>
                    <TextInput
                        source="surname"
                        label="Last Name"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        InputProps={{
                            readOnly: !hasEditAccess
                        }}
                    />
                </div>
                <div className={classes.row}>
                    <SelectInput
                        source="languageId"
                        label="Language"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        choices={[
                            { id: '1', name: 'English' },
                            { id: '2', name: 'Norwegian' }
                        ]}
                        InputProps={{
                            readOnly: !hasEditAccess
                        }}
                    />
                    <DateInput
                        source="creationTime"
                        fullWidth={true}
                        variant="standard"
                        className={classes.dateField}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </div>
                <div className={classes.lastRow}>
                    <RoleType record={record} />
                    {(((permissions === 'customer' && user_id !== id) || (permissions === 'admin' && !isBikelyCustomer)) && (record.isAdmin))
                        && (
                            <>
                                <SelectInput
                                    source="accessLevel"
                                    choices={accessLevels}
                                    variant="standard"
                                    className={classes.select}
                                    fullWidth={true}
                                    InputProps={{
                                        readOnly: !hasEditAccess
                                    }}
                                />
                                <div className={classes.selectInput}></div>
                            </>
                        )}
                    <TextInput
                        source="fiatBalance"
                        label="Balance"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        InputProps={{
                            readOnly: !hasEditAccess
                        }}
                    />
                </div>
            </div>
        </Fragment>
    );
}

export default UserDetailsTab;