import React, { useState, useEffect, Fragment } from 'react';
import { SimpleForm, useNotify, Toolbar } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomButton from '../shared/CustomButton';
import CustomLabel from '../shared/CustomLabel';
import RichTextInput from 'ra-input-rich-text';
import { Divider, Tabs, Tab, useMediaQuery } from "@material-ui/core";
import config from '../../config/config';
import { makeStyles } from '@material-ui/core';
import { useFormState } from 'react-final-form';

const useStyles = makeStyles({
    createToolbar: { display: 'flex', justifyContent: 'flex-end', backgroundColor: 'transparent' },
});

const TermsDialog = (props) => {
    const [open, setOpen] = useState(true);
    const { onSave, onClose } = props;

    const handleClose = () => {
        setOpen(false);
        onClose();
    };
    const changeTab = () => {
        setOpen(false);
        onSave();
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Unsaved changes</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to leave this page, you could lose your data?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CustomButton type="default" label="Cancel" onClick={handleClose} style={{ width: 100, marginRight: 10 }} />
                <CustomButton type="primary" label="Yes" onClick={changeTab} style={{ width: 100, marginRight: 10 }} />
            </DialogActions>
        </Dialog>
    )
}
const CreateToolbar = ({ handleUpdate, ...props }) => {
    const classes = useStyles();
    const notify = useNotify();
    const { edited } = props;
    const formState = useFormState();
    const formData = formState.values;

    const onSave = (e) => {
        e.preventDefault();

        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/TermsAndConditions/Save`;
        const { lang } = props;
        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                value: formData.text ? formData.text : '',
                languageId: lang
            })
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                notify('Saved successfully');
                handleUpdate(formData);
            })
            .catch(error => {
                notify(error.message, 'warning');
            });
    };

    return (
        <div>
            <Toolbar {...props} className={classes.createToolbar}>
                <CustomButton type="save" label="Save" disabled={!edited} onClick={onSave} style={{ marginRight: '20px' }} />
            </Toolbar>
        </div>
    );
};

const TermsAndConditions = () => {
    const [tabs, setTabs] = useState([]);
    const [savedValue, setSavedValue] = useState({ text: '' });
    const [lang, setLang] = useState(null);
    const [currentTab, setCurrentTab] = useState({});
    const [store, setStore] = useState({});
    const [tempValue, setTempValue] = useState(null);
    const [valueChanged, setValueChanged] = useState(false);
    const [open, setOpen] = useState(false);
    const [tempActive, setTempActive] = useState(null);
    const notify = useNotify();

    const isHigherThanSm = useMediaQuery(theme => {
        return theme.breakpoints.up("sm");
    });

    const useStyles = makeStyles({
        textarea: {
            "& .ra-rich-text-input": {
                "& .ql-editor": {
                    maxHeight: isHigherThanSm ? '60vh' : '40vh'
                }
            }
        }
    });
    const classes = useStyles();

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/TermsAndConditions/GetAdminTermsAndConditions`;

        const request = new Request(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                setTabs(response.result);
                setCurrentTab(response.result[0]);
                setLang(response.result[0].languageId);
            })
            .catch(error => {
                notify(error.message, 'warning');
            });
    }, [])

    useEffect(() => {
        if (lang !== null) {
            const tab = tabs.find((item) => item.languageId === lang);
            var value = tab.value;
            if (store && store[lang] && store[lang] !== value) {
                value = store[lang];
            }
            setSavedValue({
                text: value
            });
            setCurrentTab(tab);
        }
    }, [lang]);

    useEffect(() => {
        if (tempValue !== null) {
            addToStore(tempValue)
        }
    },
        [tempValue]);

    const addToStore = (value) => {
        setStore({ ...store, [lang]: value })
        setValueChanged(currentTab.value !== value);
    }

    const changeValue = val => {
        if (val !== null) {
            setTempValue(val);
        }
    }

    const handleUpdate = (formData) => {
        if (formData) {
            currentTab.value = formData.text;
            setValueChanged(false);
        }
        setSavedValue({
            text: currentTab.value
        })
    };

    const handleChange = (event, value) => {
        if (valueChanged) {
            setTempActive(value);
            setOpen(true);
            return;
        }
        setLang(value);
    }
    const closeDialogBox = () => {
        setOpen(false);
    };

    const confirmDialogBox = () => {
        setValueChanged(false);
        setOpen(false);
        setLang(tempActive);
        setTempActive(null);
    }

    return lang && (
        <Fragment>
            <Tabs
                value={lang}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(tab => (
                    <Tab key={tab.languageName} label={tab.languageDisplayName} value={tab.languageId} />
                ))}
            </Tabs>
            <Divider />
            <CustomLabel title={'Terms And Conditions'} />
            {
                tabs.map((tab) => tab.languageId === lang && (
                    <SimpleForm
                        key={tab.languageId}
                        toolbar={<CreateToolbar handleUpdate={handleUpdate} lang={lang} edited={valueChanged ? 1 : 0} />}
                        initialValues={savedValue}
                    >
                        <RichTextInput
                            formClassName={classes.textarea}
                            source="text"
                            label=""
                            variant="standard"
                            style={{ height: '150px' }}
                            onChange={changeValue}
                        />
                    </SimpleForm>
                ))
            }
            {open && (
                <TermsDialog onSave={confirmDialogBox} onClose={closeDialogBox} />
            )}
        </Fragment>
    );
};

export default TermsAndConditions;