import React, { Fragment, useEffect, useState } from 'react';
import { Edit, TabbedForm, FormTab, Toolbar, useNotify, NotFound } from 'react-admin';

import config from '../../../config/config';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useRouteMatch, useHistory} from 'react-router-dom';

import ClientsDetailsTab from './clients-tabs/ClientsDetailsTab';
import ClientsPropertiesTab from './clients-tabs/ClientsPropertiesTab';
import ClientsUsersTab from './clients-tabs/add-users/ClientsUsersTab';

import CustomTitle from '../../shared/CustomTitle';
import CustomButton from '../../shared/CustomButton';

import { makeStyles } from "@material-ui/core/styles";
import { darkTheme, lightTheme } from "../../layout/themes";

const styles = {
    flex: { width: '100%' },
    inputField: { width: '48%', display: 'flex', justifyContent: 'space-between', marginBottom: 40 },
    saveBtn: { display: 'flex', justifyContent: 'flex-end', margin: '20px 16px 20px 0', '& button': {
            marginLeft: '20px !important'
        }
    },
    editToolbar: { display: 'flex', justifyContent: 'flex-end' }
};

const useStyles = makeStyles(styles);

const CustomSaveBtn = ({ changeClientName }) => {
    const classes = useStyles();

    const notify = useNotify();
    const history = useHistory();
    const formState = useFormState();
    
    const pathname = useSelector(state =>
        state.router.location.pathname
    );

    const isAdministratorsTab = pathname.includes('/administrators');

    const validateData = (activeTab, data) => {
        const requiredFields = {
            details: ['name', 'organizationNumber', 'accountNumber', 'organizationEmail', 'countryId'],
            properties: ['numberOfAllowedRentalOrders', 'lockTimerInSeconds']
        };

        let isValid = true;
        
        requiredFields[activeTab].forEach(field => {
            if(!data[field] && data[field] !== 0 && data[field] !== false) {
                isValid = false;
            }
        });

        return isValid;
    };

    const getTabData = (activeTab) => {
        const { id } = formState.values;
        const formData = formState.values;
        
        let data = {};

        switch (activeTab) {
            case 'details':
                const { name, organizationNumber, accountNumber, organizationEmail,
                        bicSwift, countryId, addressType, street, postCode, postName } = formData;
                data = { name, organizationNumber, accountNumber, organizationEmail,
                        bicSwift, countryId, addressType, street, postCode, postName, id };
                break
            case 'properties':
                const { numberOfAllowedRentalOrders, appInformation, lockTimerInSeconds, disableOrderReceipt, termsOfUse } = formData;
                data = { numberOfAllowedRentalOrders, appInformation, lockTimerInSeconds, disableOrderReceipt, termsOfUse, id };
                break
            default:
                return;
        }

        return data;
    };
        
    const save = () => {
        let activeTab = { name: 'details', method: 'Update' };

        if (pathname.includes('/properties')) {
            activeTab = { name: 'properties', method: 'SaveProperties' }
        }

        const data = getTabData(activeTab.name);
        const isFormValid = validateData(activeTab.name, data);

        if(!isFormValid) {
            return notify('Form is not valid. Please check for errors', 'warning');
        }

        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Client/${activeTab.method}`;
        const request = new Request(endpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                if(activeTab.name === 'details') {
                    changeClientName(data.name);
                }
                notify('Saved successfully');
            })
            .catch(error => {
                notify(`Error: ${error.message}`, 'warning');
            });
    };

    const onCancel = (e) => {
        e.preventDefault();
        history.push('/clients');
    };

    return (
        <Fragment>
            {!isAdministratorsTab && (
                <div className={classes.saveBtn}>
                    <CustomButton type="default" label="Cancel" onClick={onCancel} />
                    <CustomButton type="save" label="Save" fullWidth={true} onClick={save} />
                </div>
            )}
        </Fragment>
    );
};

const ClientsEdit = (props) => {
    const classes = useStyles();

    const { params: { id } } = useRouteMatch();

    const client = useSelector(state => state.admin.resources.clients.data[id]);
    const [name, setName] = useState(client ? client.name : '');

    const labelColor = useSelector(state =>
        state.theme === "dark" ?
            darkTheme.palette.iconsColor
            : lightTheme.palette.iconsColor
    );

    const permissions = useSelector(state =>
        state.permissions
    );

    useEffect(()=> {
        setName(client ? client.name : '');
    }, [client]);

    const changeName = (newVal) => {
        setName(newVal);
    };

    const EditToolbar = props => (
        <Toolbar {...props} className={classes.editToolbar}>
            <CustomSaveBtn changeClientName={changeName}/>
        </Toolbar>
    );

    return (
        <Fragment>
            {permissions === 'admin' ? (
                <Fragment>
                    <CustomTitle title={`Client "${name}"`}/>
                    <Edit {...props}>
                        <TabbedForm toolbar={<EditToolbar />} className={classes.flex}>
                            <FormTab label="Details">
                                <ClientsDetailsTab labelColor={labelColor} />
                            </FormTab>
                            <FormTab label="Properties" path="properties">
                                <ClientsPropertiesTab labelColor={labelColor} />
                            </FormTab>
                            <FormTab label="Administrators" path="administrators">
                                <ClientsUsersTab labelColor={labelColor} />
                            </FormTab>
                        </TabbedForm>
                    </Edit>
                </Fragment>
            ) : <NotFound />}
        </Fragment>
    );
};

export default ClientsEdit;