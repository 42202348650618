import React, { useState, useEffect, Fragment } from 'react';

import config from '../../../config/config';
import LocationMap from '../../locations/locations-tabs/LocationMap';
import { useHistory} from 'react-router-dom';

import { makeStyles } from "@material-ui/core";

const styles = {
    googleMaps: { height: 380, width: '100%', marginTop: '5%' }
};

const useStyles = makeStyles(styles);

const OrderInvoiceLocation = ({ locationId }) => {
    const classes = useStyles();
    const [orderLocationDetails, setOrderLocationDetails] = useState(null);

    const history = useHistory();
    const { pathname } = history.location;

    useEffect(() => {
        if(orderLocationDetails || !pathname.includes('/invoice')) return;

        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Location/Get?Id=${locationId}`;
        const request = new Request(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                setOrderLocationDetails(response.result);
            })
            .catch(error => {
                console.log(error);
            });
    });

        
    return(
        <Fragment>
            {orderLocationDetails && (
                <div className={classes.googleMaps}>
                    <LocationMap locationDetails={orderLocationDetails} />
                </div>
            )}
        </Fragment>
    );
};

export default OrderInvoiceLocation;
