import React, { useState, Fragment } from 'react';
import { TextInput, SelectInput, RadioButtonGroupInput, NumberInput, useQuery, required } from 'react-admin';
import LocationMap from './LocationMap';

import { FormLabel, makeStyles } from "@material-ui/core";

const styles = {
    label: { marginBottom: 15, marginTop: 25, display: "block" },
    tabContainer: { display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between" },
    flexLeftDetails: { width: "29%" },
    type: { marginTop: 45, marginBottom: 10 },
    flexRightDetails: { width: "60%" },
    description: { display: "inline-block", marginLeft: 32, width: 544 },
    googleMaps: { height: 380, width: '100%', marginTop: '5%' }
};

const useStyles = makeStyles(styles);

const LocationsCreateDetailsTab = ({ permissions, hasFullAccess, ...props }) => {
    const classes = useStyles();
    const [countries, setCountries] = useState([]);

    useQuery(
        {
            type: 'GET_LIST',
            resource: 'country',
            payload: {
                pagination: {
                    page: 1,
                    perPage: 100
                },
                sort: {
                    field: 'id',
                    order: 'ASC'
                },
                filter: {}
            }
        },
        {
            onSuccess: ({ data }) => {
                setCountries(data);
            }
        }
    );

    return (
        <Fragment>
            <div className={classes.tabContainer}>
                <div className={classes.flexLeftDetails}>
                    <TextInput
                        autoFocus
                        source="name"
                        fullWidth={true}
                        variant="standard"
                        validate={required()}
                    />
                    <FormLabel component="legend" className={classes.type}>Type</FormLabel>
                    <RadioButtonGroupInput
                        source="locationType"
                        label=""
                        defaultValue={0}
                        choices={[
                            { id: 0, name: 'Bike Locker' },
                            { id: 1, name: 'Hotel' }
                        ]}
                        options={{
                            row: true
                        }}
                    />
                    <label className={classes.label}>Address and coordinates</label>
                    <SelectInput
                        source="countryId"
                        variant="outlined"
                        fullWidth={true}
                        choices={countries}
                        validate={required()}
                        initialValue='1'
                    />
                    <SelectInput
                        source="addressType"
                        variant="outlined"
                        fullWidth={true}
                        choices={[
                            { id: 0, name: 'Residental' },
                            { id: 1, name: 'Temporary' }
                        ]}
                    />
                    <TextInput
                        source="street"
                        fullWidth={true}
                        variant="standard"
                    />
                    <TextInput
                        source="municipality"
                        fullWidth={true}
                        variant="standard"
                    />
                    <NumberInput
                        source="postCode"
                        fullWidth={true}
                        variant="standard"
                    />
                    <TextInput
                        source="postName"
                        fullWidth={true}
                        variant="standard"
                    />
                </div>
                <div className={classes.flexRightDetails}>
                    <TextInput
                        source="description"
                        fullWidth={true}
                        rows="4"
                        multiline
                        variant="outlined"
                        formClassName={classes.description}
                    />
                    <div className={classes.googleMaps}>
                        <LocationMap permissions={permissions === 'admin' || hasFullAccess ? 'admin' : null} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default LocationsCreateDetailsTab;
