import {
    Dialog, DialogActions, makeStyles,
    DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { darkTheme, lightTheme } from "../../components/layout/themes";
import config from '../../config/config';
import CustomButton from '../../components/shared/CustomButton';


const useStyles = makeStyles({
    radialButtons: {
        padding: 12,
        '& .MuiTypography-body1': { fontSize: '0.875rem' }
    },
    dialog: {
        '& .MuiPaper-root': { height: 450, }
    }
});

const TwoFactorAuthDialog = ({ isOpen, closeDialog, is2FAActive, on2FAEnable, data, onError }) => {
    const classes = useStyles();
    const token = localStorage.getItem('access_token');
    const themeType = useSelector(state => state.theme);
    const theme = themeType === 'light' ? lightTheme : darkTheme;

    const { authProvider, phoneNumber } = data;

    const [twoFactorValue, setTwoFactorValue] = useState(is2FAActive ? authProvider : 'sms');
    const [phone, setPhone] = useState(is2FAActive ? phoneNumber?.slice(2) : '');
    const [error, setError] = useState(false);

    const onSave = () => {
        if (phone.length < 6) {
            setError(true);
            return;
        } else {
            setError(false)
        }
        const endpoint = `${config.apiBaseUrl}/Profile/TwoFactorAuthenticateEnable`;
        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                isTwoFactorEnabled: true,
                phoneNumber: '00' + phone,
                authProvider: twoFactorValue
            })
        });
        fetch(request)
            .then(res => res.json())
            .then(response => {
                if(response.success) {
                    on2FAEnable();
                } else {
                    onError(response.error?.message || 'An error occurred, please try again later.');
                    closeDialog();
                }
            })
            .catch(error => {
                onError('An error occurred, please try again later.');
            });
    };

    const onCancel = () => {
        closeDialog();
        setTwoFactorValue(is2FAActive ? authProvider : 'sms');
        setPhone(is2FAActive ? phoneNumber?.slice(2) : '');
    };

    useEffect(() => {
        setTwoFactorValue(is2FAActive ? authProvider : 'sms');
        setPhone(is2FAActive ? phoneNumber?.slice(2) : '');
    }, [data])

    return (
        <>
            {isOpen &&
                <Dialog
                    open={isOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='sm'
                    fullWidth={true}
                    className={classes.dialog}
                >
                    <DialogTitle id="alert-dialog-title">{is2FAActive ? 'Edit two-factor authentication' : 'Enable two-factor authentication'}</DialogTitle>
                    <DialogContent>
                        <div style={{ fontWeight: 500, color: '#666' }}>Authentication method</div>
                        <FormControlLabel
                            className={classes.radialButtons}
                            control={
                                <RadioGroup
                                    aria-label="twoFactorAuthentication"
                                    name="twoFactorAuthentication"
                                    value={twoFactorValue}
                                    onChange={(e, val) => setTwoFactorValue(val)}
                                >
                                    <FormControlLabel value="sms" control={<Radio />} label="SMS" />
                                    <FormControlLabel value="email" control={<Radio />} label="Email" />
                                </RadioGroup>
                            }
                        />
                        <div style={{ marginBottom: 5, marginTop: 10, fontWeight: 500, color: '#666' }}>Phone number (required)</div>
                        <p style={{ margin: 0, fontSize: '13px', color: '#666' }}>Phone number is required as a backup option regardless of the authentication method you choose.</p>
                        <PhoneInput
                            country={'no'}
                            value={phone}
                            onChange={num => setPhone(num)}
                            style={{ marginTop: 20, marginBottom: 30 }}
                        />
                        {error &&
                            <p style={{ color: theme.palette.warning.main, marginTop: -25 }}>Phone number format is not correct.</p>
                        }
                    </DialogContent>
                    <DialogActions style={{ paddingBottom: 20, paddingRight: 20 }}>
                        <CustomButton type="default" label="Cancel" onClick={onCancel} />
                        <CustomButton type="primary" label={is2FAActive ? 'Save' : 'Enable'} onClick={onSave} style={{ minWidth: 120, marginLeft: 15 }} />
                    </DialogActions>
                </Dialog>
            }
        </>
    );
};

export default TwoFactorAuthDialog;