export const SET_USER = 'SET_USER';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';

export const setUser = user => ({
    type: SET_USER,
    payload: user
});

export const setPermissions = permission => ({
    type: SET_PERMISSIONS,
    payload: permission
});