const apiDomain = process.env.REACT_APP_API_ENDPOINT;

const config = {
    apiDomain,
    authBaseUrl: apiDomain + '/api',
    apiBaseUrl: apiDomain + '/api/services/app',
    currency: process.env.REACT_APP_DEFAULT_CURRENCY || 'Eur'
};


export default config;
