import React, { Fragment, useEffect, useState } from 'react';
import { TextInput, SelectInput, NumberInput, required } from 'react-admin';

import config from '../../../../config/config';
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

const styles = {
    flexContainer: { width: '100%' },
    inputField: { width: '48%' },
    row: { display: 'flex', justifyContent: 'space-between', marginBottom: '18px', width: '100%' },
    description: { marginBottom: '0' },
};

const useStyles = makeStyles(styles);

const DoorsEditLockDetailsTab = ({ handleLockValidation, labelColor }) => {
    const classes = useStyles();
    const formState = useFormState();
    const { values } = formState;
    const [locks, setLocks] = useState([]);
    const permissions = useSelector(state =>
        state.permissions
    );

    useEffect(() => {
        // don't fetch locks if selected lock type is PLC
        if ((values.lockType === 2 || values.lockType === 3) && locks.length === 0 && values.localAccessKeyName) {
            const token = localStorage.getItem('access_token');
            let endpoint = `${config.apiBaseUrl}/door/GetUnusedNBLocks`;
            if (values.lockType === 3) {
                endpoint = `${config.apiBaseUrl}/door/GetUnusedBLELocks`;
            }

            const request = new Request(endpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            fetch(request)
                .then(res => res.json())
                .then(response => {
                    if (response && response.result) {
                        const data = response.result;
                        let lockValues = [];
                        Object.keys(data).forEach(item => {
                            lockValues.push({
                                id: item,
                                name: `${item} - ${data[item]}`
                            });
                        });

                        setLocks(lockValues);
                    }
                });
        }
    }, [values, locks]);

    const setLockError =
        (!formState.valid && formState.submitFailed && (formState.errors.localAccessKey))
            ? true : false;

    useEffect(() => {
        if (setLockError) {
            handleLockValidation('red');
        } else if (formState.valid || !(formState.errors.localAccessKey)) {
            handleLockValidation(labelColor);
        }
    }, [formState, handleLockValidation, labelColor, setLockError]);

    return (
        <Fragment>
            <div className={classes.flexContainer}>
                <div className={classes.row}>
                    <TextInput
                        source="description"
                        fullWidth={true}
                        rows="4"
                        multiline
                        variant="outlined"
                        className={classes.inputField}
                        disabled={permissions !== 'admin'}
                    />
                </div>
                <div className={classes.row}>
                    <NumberInput
                        source="maxConcurrent"
                        fullWidth={true}
                        className={classes.inputField}
                        variant="standard"
                        defaultValue={1}
                        inputProps={{
                            min: 1
                        }}
                        format={v => (v === 0 || v < 0) ? 1 : v}
                        disabled={permissions !== 'admin'}
                    />
                    {/* when selected lock type is 'NB lock' display dropdown with available locks */}
                    {((values.lockType === 2 || values.lockType === 3) && locks.length) ? (
                        <SelectInput
                            source="localAccessKey"
                            fullWidth={true}
                            className={classes.inputField}
                            variant="standard"
                            choices={locks}
                            validate={required()}
                            disabled={permissions !== 'admin'}
                        />
                    ) : (
                        <NumberInput
                            style={{ display: 'block' }}
                            source="localAccessKey"
                            label="Local access key for PLC device"
                            fullWidth={true}
                            className={classes.inputField}
                            variant="standard"
                            validate={required()}
                            inputProps={{
                                min: 0
                            }}
                            format={v => (v < 0) ? 0 : v}
                            disabled={permissions !== 'admin'}
                        />
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default DoorsEditLockDetailsTab;