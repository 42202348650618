import React, { useState, Fragment } from 'react';
import { Datagrid, TextField, DateField, useQuery, Pagination, Loading } from 'react-admin';
import keyBy from 'lodash/keyBy';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    paid: { color: '#58BBB1' },
    completed: { color: '#80C783' },
    pending: { color: '#5EBDE9' },
    ordered: { color: '#58BBB1' },
    canceled: { color: '#db574d' },
});

const UsersOrderList = ({ id }) => {
    const history = useHistory();

    const user = useSelector(state =>
        state.user
    );

    const permissions = useSelector(state =>
        state.permissions
    );

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sort, setSort] = useState({ field: 'creationTime', order: 'DESC' });

    const changePerPage = (value) => {
        setPage(1);
        setPerPage(value);
    };

    const changeSort = (field) => {
        setPage(1);
        const order = sort.order === 'ASC' ? 'DESC' : 'ASC';
        setSort({ field, order});
    };

    const filter = {
        'UserId': id,
        'showAll': true
    };

    const { data, total, loading, error } = useQuery({
        type: 'GET_LIST',
        resource: 'orders',
        payload: {
            pagination: { page, perPage },
            sort,
            filter
        }
    });

    if (loading) {
        return <Loading />
    }

    if (error) {
        return <p>ERROR: {error}</p>
    }

    const OrderStatusField = props => {
        const classes = useStyles();
        const statusValues = {
            0: 'Pending',
            1: 'Ordered',
            2: 'Completed',
            3: 'Cancelled'
        };

        const { status } = props.record;
        const orderStatus = statusValues[status];

        return (
            <span
                className={classnames({
                    [classes.pending]: status === 0, // pending
                    [classes.ordered]: status === 1, // ordered
                    [classes.completed]: status === 2, // completed
                    [classes.canceled]: status === 3 // canceled
                })}
            >
                {orderStatus}
            </span>
        );
    };


    const PaymentStatusField = props => {
        const classes = useStyles();
        const statusValues = {
            0: 'Not Paid',
            1: 'Paid',
            2: 'Refunded',
            3: 'PartiallyRefunded',
            4: 'Locked'
        };

        const { paymentStatus } = props.record;
        const orderPaymentStatus = statusValues[paymentStatus];

        return (
            <span
                className={classnames({
                    [classes.canceled]: paymentStatus === 0, // not paid
                    [classes.paid]: paymentStatus === 1, // paid
                    [classes.pending]: paymentStatus === 2, // refunded
                    [classes.ordered]: paymentStatus === 3 // partially refunded
                })}
            >
                {orderPaymentStatus}
            </span>
        );
    };

    const goToOrder = (orderId) => {
        history.push(`/orders/${orderId}/show`);
    };

    return (
        <Fragment>
            <Datagrid
                data={keyBy(data, 'id')}
                ids={data.map(({ id }) => id)}
                currentSort={sort}
                setSort={changeSort}
                rowClick={(e) => goToOrder(e)}
            >
                <TextField source="id" />
                <TextField source="invoiceName" label="Customer" sortable={false} />
                <DateField source="creationTime" locales="nb-NO" label="Order Date" />
                <OrderStatusField source="status" />
                <PaymentStatusField source="paymentStatus" />
            </Datagrid>
            <Pagination
                page={page}
                perPage={perPage}
                setPage={setPage}
                setPerPage={changePerPage}
                total={total}
            />
        </Fragment>
    );
};

export default UsersOrderList;