import React, { Fragment, useEffect } from "react";
import { Create, FormTab, TabbedForm, NotFound, SaveButton, Toolbar } from "react-admin";
import LocationsCreateDetailsTab from './locations-tabs/LocationsCreateDetailsTab';
import CustomLabel from '../shared/CustomLabel';
import CustomButton from '../shared/CustomButton';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { setCreateLocationForCustomer } from "../../redux/customerLocation/actions";

const styles = {
    createToolbar: { display: 'flex', justifyContent: 'flex-end' }
};

const useStyles = makeStyles(styles);

export default function LocationsCreate(props) {
    const dispatch = useDispatch();
    const permissions = useSelector(state =>
        state.permissions
    );

    const redirect = (basePath, id, data) => {
        dispatch(setCreateLocationForCustomer(null));
        return `/locations/${id}/doors`;
    };

    useEffect(()=> {
        return () => dispatch(setCreateLocationForCustomer(null));
    }, []);

    const CreateToolbar = ({ basePath, ...props }) => {
        const classes = useStyles();
        const history = useHistory();

        const onCancel = (e) => {
            e.preventDefault();
            history.push('/locations');
        };

        return (
            <div>
                <Toolbar {...props} className={classes.createToolbar}>
                    <CustomButton type="default" label="Cancel" onClick={onCancel} style={{ marginTop: '30px', marginRight: '20px' }}/>
                    <SaveButton basePath={basePath} />
                </Toolbar>
            </div>
        );
    };

    return (
        <Fragment>
            {permissions === 'admin' ? (
                <Fragment>
                    <CustomLabel title={'New Location'} />
                    <Create {...props}>
                        <TabbedForm redirect={redirect} toolbar={<CreateToolbar />}>
                            <FormTab label="Details">
                                <LocationsCreateDetailsTab permissions={permissions} />
                            </FormTab>
                            <FormTab label="Doors" path="doors" disabled />
                            <FormTab label="Access Control" path="access-control" disabled />
                            <FormTab label="Pricing" path="address" disabled />
                        </TabbedForm>
                    </Create>
                </Fragment>
            ) : <NotFound />}
        </Fragment>
    );
};
