import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment } from 'react';
import { Datagrid, Filter, List, NotFound, SearchInput, TextField } from 'react-admin';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ActionDropdown from '../../shared/ActionDropdown';
import CustomButton from '../../shared/CustomButton';
import CustomLabel from '../../shared/CustomLabel';



const useStyles = makeStyles({
    search: { display: 'flex', justifyContent: 'flex-end' },
    buttons: { float: 'right' }
});

const ClientsFilter = props => {
    const classes = useStyles();
    const history = useHistory();

    const handleClick = () => {
        history.push(`${props.basePath}/create`);
    };

    return(
        <Fragment>
            <CustomButton type="primary" label="Create new" fullWidth={true} basePath={'/clients'} onClick={handleClick} />
            <div className={classes.search}>
                <Filter {...props} variant="outlined">
                    <SearchInput source="clientsSearch" variant="outlined" alwaysOn resettable />
                </Filter>
            </div>
        </Fragment>
    );
};

const ClientsList = props => {
    const classes = useStyles();
    const permissions = useSelector(state =>
        state.permissions
    );

    return (
        <Fragment>
            {permissions === 'admin' ? (
                <Fragment>
                    <CustomLabel title={'Clients'} />
                    <List {...props} actions={null} filters={<ClientsFilter />} bulkActionButtons={false}>
                        <Datagrid>
                            <TextField source="id" label="ID" />
                            <TextField source="name" />
                            <TextField source="organizationEmail" />
                            <ActionDropdown label="Actions" entity="client"/>
                        </Datagrid>
                    </List>
                </Fragment>
        ) : <NotFound />}
        </Fragment>
    );
};

export default ClientsList;