import React, { Fragment, useState } from 'react';
import { TextInput, SelectInput, useQuery, useNotify, required } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";

const UserGroupsDetailsPage = ({ permissions, ...props }) => {

    const styles = {
        flexContainer: { width: '100%' },
        inputField: { width: '48%' },
        row: { display: 'flex', justifyContent: 'space-between', marginBottom: 40, width: '100%' },
    };

    const useStyles = makeStyles(styles);
    const classes = useStyles();

    const [clients, setClients] = useState([]);
    const notify = useNotify();

    useQuery(
        {
            type: 'GET_LIST',
            resource: 'clients',
            payload: {
                pagination: {
                    page: 1,
                    perPage: 100
                },
                sort: {
                    field: 'id',
                    order: 'ASC'
                },
                filter: {}
            }
        },
        {
            onSuccess: ({ data }) => {
                setClients(data);
            },
            onFailure: (error) => notify(error.message, 'warning')
        }
    );

    return (
        <Fragment>
            <div className={classes.flexContainer}>
                <div className={classes.row}>
                    <TextInput
                        source="name"
                        fullWidth={true}
                        className={classes.inputField}
                        variant="standard"
                        validate={required()}
                    />
                    {permissions === 'admin' &&
                        <SelectInput
                            source="clientId"
                            fullWidth={true}
                            className={classes.inputField}
                            variant="standard"
                            choices={clients}
                            allowEmpty
                            emptyValue={null}
                            emptyText="None"
                        />
                    }
                </div>
                <div className={classes.row}>
                    <TextInput
                        source="description"
                        fullWidth={true}
                        variant="outlined"
                        rows="5"
                        multiline
                        className={classes.inputField}
                        validate={required()}
                    />
                </div>
            </div>
        </Fragment>
    );
}

export default UserGroupsDetailsPage;