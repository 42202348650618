import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { required } from "react-admin";


const EditDateTimeInput = ({ record, isScheduled, callback, isPublished }) => {
    const { scheduledTime } = record;
    const [dateTime, setDateTime] = useState(scheduledTime);
    const [disabled, setDisabled] = useState(!isScheduled || !isPublished);

    const onChange = val => {
        setDateTime(val);
        callback(val);
    }

    useEffect(() => {
        callback(scheduledTime);
    }, [])

    useEffect(() => {
        setDisabled(!isScheduled || !isPublished);
    }, [isScheduled, isPublished])

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
                autoOk
                ampm={false}
                inputVariant="standard"
                value={dateTime}
                onChange={onChange}
                label="Schedule Time"
                validate={required()}
                disabled={disabled}
            />
        </MuiPickersUtilsProvider>
    )
};

export default EditDateTimeInput;