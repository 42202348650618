import React, { useState, useEffect, Fragment } from 'react';
import { AutocompleteArrayInput } from 'react-admin';
import { FormLabel, makeStyles } from "@material-ui/core";

import config from '../../../config/config';

const UserGroupsTab = ({ labelColor, ...props }) => {
    const styles = {
        flexContainer: { width: '100%' },
        formLabel: { marginTop: 20, marginBottom: 10, fontWeight: 700, color: labelColor },
        select: { maxWidth: '29%' },
        row: { display: 'flex', justifyContent: 'space-between', marginBottom: 40, width: '100%' },
    };

    const useStyles = makeStyles(styles);
    const classes = useStyles();

    const [userGroups, setUserGroups] = useState([]);
    const [defaultValues, setDefaultValues] = useState([]);
    const { id } = props.record;

    useEffect(() => {
        async function getUserGroups() {
            const token = localStorage.getItem('access_token');
    
            // get groups
            const endpoint = `${config.apiBaseUrl}/Group/GetList`;
            const request = new Request(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    sorting: "id ASC",
                    skipCount: 0,
                    maxResultCount: 100
                })
            });

            await fetch(request)
                .then(res => res.json())
                .then(response => {
                    if (response && response.result) {
                        setUserGroups(response.result.items);
                    }
                });

            // get user groups
            const endpointUserGroups = `${config.apiBaseUrl}/Group/GetUserGroups`;
    
            let params = {
                sorting: 'id ASC',
                skipCount: 0,
                maxResultCount: 100,
                userId: id,
                inGroup: true,
                filterRule: {
                    condition: 'and',
                    filterRules: []
                }
            };
    
            const requestUserGroups = new Request(endpointUserGroups, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(params)
            });
    
            await fetch(requestUserGroups)
                .then(res => res.json())
                .then(response => {
                    if (response && response.result) {
                        const { items } = response.result;
                        const ids = items.map(el => el.id);
                        setDefaultValues(ids);
                    }
                });
        }

        getUserGroups();
    }, [id]);

    return (
        <Fragment>
            <div className={classes.flexContainer}>
                <FormLabel component="legend" className={classes.formLabel}>Add User to Group</FormLabel>
                <div className={classes.row}>
                    {
                        defaultValues.length ? (
                            <AutocompleteArrayInput
                                label="Group"
                                source="userGroupsIds"
                                fullWidth={true}
                                variant="outlined"
                                className={classes.select}
                                choices={userGroups}
                                defaultValue={defaultValues}
                            />
                        ) : (
                            <AutocompleteArrayInput
                                label="Group"
                                source="userGroupsIds"
                                fullWidth={true}
                                variant="outlined"
                                className={classes.select}
                                choices={userGroups}
                            />
                        )
                    }
                </div>
            </div>
        </Fragment>
    );
}

export default UserGroupsTab;