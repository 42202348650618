import React, { useState, useEffect, Fragment } from 'react';
import { Datagrid, TextField } from 'react-admin';

import keyBy from 'lodash/keyBy';

import config from '../../../config/config';
import { makeStyles } from '@material-ui/core/';

const styles = {
    noResult: { fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)', borderRadius: '4px', padding: '16px' }
};

const useStyles = makeStyles(styles);

const UserPaymentOptionsTab = ({ record }) => {
    const { id } = record;
    const [data, setData] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/PaymentStripe/GetPaymentOptions?userId=${id}`;

        const request = new Request(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                if(response && response.result) {
                    setData(response.result);
                }
            })
            .catch(error => {
                console.log(error, 'error');
            });
    }, [id]);

    return (
        <Fragment>
            {data && data.length ? (
                <Fragment>
                    <Datagrid
                        data={keyBy(data, 'cardId')}
                        ids={data.map(({ cardId }) => cardId)}
                        currentSort={{ field: 'cardId', order: 'ASC' }}
                    >
                        <TextField source="cardId" label="Card ID" sortable={false} />
                        <TextField source="name" sortable={false} />
                        <TextField source="brand" sortable={false} />
                        <TextField source="expireDate" sortable={false} />
                    </Datagrid>
                </Fragment>
            ) : <p className={classes.noResult}>No results found.</p> }
        </Fragment>
    )
};

export default UserPaymentOptionsTab;