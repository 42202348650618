import createAdminStore from './createAdminStore';
import authProvider from '../auth/authProvider';
import customDataProvider from "../dataProvider/customDataProvider";
import { createHashHistory } from 'history';

const history = createHashHistory();

export const store = createAdminStore({
    authProvider,
    customDataProvider,
    history
});