import React, { useState, Fragment, useEffect } from "react";
import { useQuery, BooleanInput, SelectInput, RadioButtonGroupInput, AutocompleteArrayInput } from "react-admin";
import { useFormState } from 'react-final-form';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker, DateTimePicker } from '@material-ui/pickers';
import { FormLabel, makeStyles, Grid, FormControlLabel, Switch } from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { updateMaintenance } from '../../../redux/maintenance/actions';

import config from '../../../config/config';

export const styles = {
    tabContainer: { display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between" },
    type: { marginTop: 10, marginBottom: 10 },
    clients: { marginBottom: 45 },
    flexLeftAccess: { width: "49%", marginRight: '2%', marginBottom: 50 },
    flexRightAccess: { width: "49%" },
    groupRestrictionsSelect: { maxWidth: 450, marginTop: 25 },
    timePicker: { maxWidth: 135, marginRight: 30 },
    label: { fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' },
    groupRestrictionsLabel: { marginBottom: 20, fontWeight: 700 }
};

const useStyles = makeStyles(styles);

const LocationsAccessControlTab = ({permissions, record, readOnly, canUpdateStatus, ...props}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    
    const { id } = record;
    const [isClient] = useState(permissions === 'client');

    const formState = useFormState();
    const [formData] = useState(formState.values);

    const [defaultValues, setDefaultValues] = useState([]);

    const [locationStatus, setLocationStatus] = useState(formData.isActive);
    const [locationStatusLabel, setLocationStatusLabel] = useState(locationStatus ? 'Active' : 'Inactive');

    const [clients, setClients] = useState([]);
    const [userGroups, setUserGroups] = useState([]);

    const [allDay, setAllDay] = useState(formData.allDay);
    const [openFrom, setOpenFrom] = useState(formData.openFrom ? formData.openFrom : "2020-01-28T06:00:00Z");
    const [openTo, setOpenTo] = useState(formData.openTo ? formData.openTo : "2020-01-28T22:00:00Z");

    const [isInMaintenance, setIsInMaintenance] = useState(record.isInMaintenance);
    const [selectedEndingDate, handleEndingDateChange] = useState(record.maintenanceEndingTime ? new Date(record.maintenanceEndingTime) : new Date());
    const [selectedStartingDate, handleStartingDateChange] = useState(record.maintenanceStartTime ? new Date(record.maintenanceStartTime) : new Date());

    const handleLocationStatusChange = () => {
        setLocationStatus(!locationStatus);
        setLocationStatusLabel(() => locationStatus ? 'Inactive' : 'Active');
    };

    useEffect(() => {
        props.callback({
            allDay,
            openFrom,
            openTo
        });
    }, []);

    useEffect(() => {
        async function getLocationGroups() {
            const token = localStorage.getItem('access_token');
            // get groups
            const endpoint = `${config.apiBaseUrl}/Group/GetList`;
            const request = new Request(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    sorting: "id ASC",
                    skipCount: 0,
                    maxResultCount: 100
                })
            });

            await fetch(request)
                .then(res => res.json())
                .then(response => {
                    if (response && response.result) {
                        setUserGroups(response.result.items);
                    }
                });

            // get location groups
            const endpointLocationGroups = `${config.apiBaseUrl}/Location/GetLocationGroups`;
            const requestLocationGroups = new Request(endpointLocationGroups, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    inLocation: true,
                    locationId: id,
                    sorting: "id ASC",
                    skipCount: 0,
                    maxResultCount: 100
                })
            });

            await fetch(requestLocationGroups)
                .then(res => res.json())
                .then(response => {
                    if (response && response.result) {
                        const { items } = response.result;
                        const ids = items.map(el => el.id);
                        setDefaultValues(ids);
            
                        props.callback({
                            openFrom,
                            openTo,
                            allDay,
                        });
            
                        props.getGroups({
                            groups: items.length ? ids : []
                        });
                    }
                });
        }

        getLocationGroups();
        // eslint-disable-next-line
    }, [id]);

    useQuery(
        {
            type: 'GET_LIST',
            resource: 'clients',
            payload: {
                pagination: {
                    page: 1,
                    perPage: 100
                },
                sort: {
                    field: 'id',
                    order: 'ASC'
                },
                filter: {}
            }
        },
        {
            onSuccess: ({ data }) => {
                setClients(data);
            }
        }
    );

    const handleOpenFromChange = (value) => {
        setOpenFrom(value.toISOString());
        props.callback({
            openFrom: value.toISOString()
        });
    };

    const handleOpenToChange = (value) => {
        setOpenTo(value.toISOString());
        props.callback({
            openTo: value.toISOString()
        });
    };

    const handleSwitchChange = () => {
        props.callback({
            allDay: !allDay
        });
        setAllDay(!allDay);
    };

    const handleMaintenanceSwitch = () => {
        setIsInMaintenance(!isInMaintenance);
    };

    const handleGroupsChange = values => {
        props.getGroups({
            groups: values
        });
    };

    useEffect(() => {
        const value = {
            locationId: id,
            maintenanceEndingTime: isInMaintenance ? selectedEndingDate.toISOString() : null,
            maintenanceStartTime: isInMaintenance ? selectedStartingDate.toISOString() : null
        };

        dispatch(updateMaintenance(value));
        // eslint-disable-next-line
    }, [isInMaintenance, selectedEndingDate, selectedStartingDate]);

    return (
        <Fragment>
            <div className={classes.tabContainer}>
                <div className={classes.flexLeftAccess}>

                <FormLabel component="legend" className={classes.type}>Visibility</FormLabel>
                    <RadioButtonGroupInput
                        source="accessControlType"
                        label=""
                        choices={[
                            { id: 0, name: 'Public' },
                            { id: 1, name: 'Private' }
                        ]}
                        options={{
                            row: true
                        }}
                        disabled={readOnly}
                    />

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container>
                            <TimePicker
                                margin="normal"
                                id="accessTimeFrom"
                                ampm={false}
                                label="Open from"
                                value={openFrom}
                                name="selectedTimeFrom"
                                className={classes.timePicker}
                                keyboardbuttonprops={{
                                    'aria-label': 'change time',
                                }}
                                inputProps={{
                                    readOnly: true
                                }}
                                disabled={allDay || isClient}
                                onChange={handleOpenFromChange}
                            />
                            <TimePicker
                                margin="normal"
                                id="accessTimeTo"
                                ampm={false}
                                name="selectedTimeTo"
                                label="Open to"
                                className={classes.timePicker}
                                value={openTo}
                                keyboardbuttonprops={{
                                    'aria-label': 'change time',
                                }}
                                disabled={allDay || isClient || readOnly}
                                onChange={handleOpenToChange}
                            />
                            <FormControlLabel
                                control={<Switch color="primary" />}
                                label="All day"
                                classes={{ label: classes.label, checked: classes.checkedLabel }}
                                labelPlacement="top"
                                checked={allDay}
                                onChange={handleSwitchChange}
                                disabled={isClient || readOnly}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                    <div className={classes.flexLeftAccess} style={{ marginBottom: '20px' }}>
                        <FormLabel component="legend" className={classes.type}>Maintenance</FormLabel>
                        <FormControlLabel
                            style={{ margin: 0 }}
                            control={<Switch color="primary" />}
                            label="Maintenance active"
                            classes={{ label: classes.label }}
                            labelPlacement="start"
                            checked={isInMaintenance}
                            onChange={handleMaintenanceSwitch}
                            disabled={readOnly}
                        />
                    </div>
                    <div className={classes.flexLeftAccess}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                autoOk
                                ampm={false}
                                inputVariant="outlined"
                                value={selectedStartingDate}
                                onChange={handleStartingDateChange}
                                label="Maintenance Starting Time"
                                disabled={!isInMaintenance || readOnly}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className={classes.flexLeftAccess}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                autoOk
                                ampm={false}
                                inputVariant="outlined"
                                value={selectedEndingDate}
                                onChange={handleEndingDateChange}
                                label="Maintenance Ending Time"
                                disabled={!isInMaintenance || readOnly}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div className={classes.flexRightAccess}>
                    <div className={classes.clients}>
                        <SelectInput
                            allowEmpty
                            emptyValue={null}
                            emptyText="None"
                            source="clientId"
                            fullWidth={true}
                            choices={clients}
                            options={{ disabled: isClient || readOnly }}
                            className={classes.groupRestrictionsSelect}
                        />
                    </div>
                    <FormLabel component="legend" className={classes.type}>Status</FormLabel>
                    <BooleanInput
                        label={locationStatusLabel}
                        source="isActive"
                        onChange={handleLocationStatusChange}
                        disabled={isClient || !canUpdateStatus}
                    />
                </div>
            </div>
            <FormLabel component="legend" className={classes.groupRestrictionsLabel}>Group restrictions</FormLabel>
            {
                defaultValues.length ? (
                    <AutocompleteArrayInput
                        label="Group"
                        source="userGroupsIds"
                        fullWidth={true}
                        variant="outlined"
                        choices={userGroups}
                        defaultValue={defaultValues}
                        onChange={handleGroupsChange}
                    />
                ) : (
                    <AutocompleteArrayInput
                        label="Group"
                        source="userGroupsIds"
                        fullWidth={true}
                        variant="outlined"
                        choices={userGroups}
                        onChange={handleGroupsChange}
                    />
                )
            }
        </Fragment>
    );
}

export default LocationsAccessControlTab;