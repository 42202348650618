import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { NotFound } from "react-admin";

import CustomLabel from '../../shared/CustomLabel';
import CustomTabs from '../../shared/CustomTabs';
import DetailsTab from './DetailsTab';
import AdministratorsTab from './AdministratorsTab';

const ClientInfo = props => {
    const clientId = useSelector(state =>
        state.user.clientId
    );
    const permissions = useSelector(state =>
        state.permissions
    );

    const tabsData = [
        {
            label: 'Details',
            component: <DetailsTab clientId={clientId} />
        },
        {
            label: 'Administrators',
            component: <AdministratorsTab clientId={clientId} />
        }
    ];

    return (
        <Fragment>
            {permissions === 'client' ? (
                <Fragment>
                    <CustomLabel title="Client Info" />
                    <CustomTabs tabsData={tabsData} style={{ marginTop: '1em' }} />
                </Fragment>
            ) : <NotFound />}

        </Fragment>
    )
};

export default ClientInfo;