import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { Edit, FormTab, TabbedForm, Toolbar, useNotify, NotFound } from "react-admin";
import { darkTheme, lightTheme } from "../../../layout/themes";
import CustomTitle from '../../../shared/CustomLabel';
import CustomButton from '../../../shared/CustomButton';

import config from '../../../../config/config';
import { useForm, useFormState } from 'react-final-form';

import DoorsEditBasicDetailsTab from './DoorsEditBasicDetailsTab';
import DoorsEditLockDetailsTab from './DoorsEditLockDetailsTab';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";

const styles = {
    editToolbar: { display: 'flex', justifyContent: 'flex-end' }
};

const useStyles = makeStyles(styles);

const DoorsEditForm = (props) => {
    const history = useHistory();
    const permissions = useSelector(state =>
        state.permissions
    );

    const accessLevel = useSelector(state =>
        state.user.accessLevel
    );

    const hasEditAccess = true;

    const labelColor = useSelector(state =>
        state.theme === "dark" ?
            darkTheme.palette.menuItemsColor
            : lightTheme.palette.menuItemsColor
    );

    const { params: { id } } = useRouteMatch();

    const door = useSelector(state => state.admin.resources.doors.data[id]);
    const name = door ? door.name : '';

    const locationId = door ? door.locationId : '';
    
    const [basicDetailsError, setBasicDetailsError] = useState(labelColor);
    const [lockDetailsError, setLockDetailsError] = useState(labelColor);
    
    const handleDetailsValidation = (newValue) => {
        setBasicDetailsError(newValue);
    };
    
    const handleLockValidation = (newValue) => {
        setLockDetailsError(newValue);
    };
    
    const EditToolbar = ({ basePath, ...props }) => {
        const classes = useStyles();

        const onCancel = (e) => {
            e.preventDefault();
            history.push(`/locations/${locationId}/doors`);
        };

        const CustomSaveBtn = props => {
            const notify = useNotify();

            const form = useForm();
            const formState = useFormState();
            const { values, errors, valid } = formState;

            const getLocationData = async () => {
                const token = localStorage.getItem('access_token');
                const endpoint = `${config.apiBaseUrl}/Location/Get?id=${locationId}`;

                const request = new Request(endpoint, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                
                let data;

                await fetch(request)
                    .then(res => res.json())
                    .then(response => {
                        data = response.result;
                    })
                    .catch(error => {
                        console.log(error, 'error');
                        return [];
                    });

                return data;
            };

            const save = async () => {
                if(!valid) {
                    if(errors.localAccessKey) {
                        handleLockValidation('red');
                    }

                    notify('Form is not valid. Please check for errors', 'warning');
                    return form.submit();
                }


                let data = Object.assign({}, values);

                delete data.useLocationPrice;
                delete data.startPriceAmount;
                delete data.startPriceDuration;
                delete data.mainPriceAmount;
                delete data.mainPriceDuration;

                if (data.maxConcurrent === 0) data.maxConcurrent = 1;
  
                const token = localStorage.getItem('access_token');
        
                const endpoint = `${config.apiBaseUrl}/Door/Update`;
                const request = new Request(endpoint, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(data)
                });
        
                await fetch(request)
                    .then(res => res.json())
                    .then(response => {
                        notify('Door updated', 'info');

                        history.push(`/locations/${locationId}/doors`);
                    })
                    .catch(error => {
                        console.log(error, 'error');
                    });
                
            }
    
            return <CustomButton type="save" label="Save" onClick={save} />;
        };

        return (
            <div>
                <Toolbar {...props} className={classes.editToolbar}>
                    <CustomButton
                        type="default"
                        label={'Cancel'}
                        onClick={onCancel}
                        style={{ marginRight: '20px' }}
                    />
                    {hasEditAccess ? <CustomSaveBtn /> : null}
                </Toolbar>
            </div>
        );
    };

    return (
        <>
            {hasEditAccess ? (
                <>
                    <CustomTitle title={name ? `Door "${name}"`: ''}/>
                    <Edit undoable={false} resource="doors" basePath="doors" {...props}>
                        <TabbedForm redirect={`/locations/${locationId}/doors`} toolbar={<EditToolbar />}>
                            <FormTab label="Basic Details" style={{ color: basicDetailsError}}>
                                <DoorsEditBasicDetailsTab handleDetailsValidation={handleDetailsValidation} labelColor={labelColor} />
                            </FormTab>
                            <FormTab label="Lock Details" style={{ color: lockDetailsError}}>
                                <DoorsEditLockDetailsTab handleLockValidation={handleLockValidation} labelColor={labelColor} />
                            </FormTab>
                        </TabbedForm>
                    </Edit>
                </>
            ) : <NotFound />}
        </>

    );
};

export default DoorsEditForm;