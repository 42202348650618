import React, { Fragment, useState } from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { useSelector } from 'react-redux';
import CustomLabel from '../../../shared/CustomLabel';
import CustomButton from '../../../shared/CustomButton';
import SelectUsersButton from './SelectUsersButton';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/';
import { TextField as SearchField } from '@material-ui/core/';
import { darkTheme, lightTheme } from "../../../layout/themes";

const AddUsersToGroup = ({ handleClose, ...props }) => {
    const theme = useSelector(state =>
        state.theme === "dark" ? darkTheme.palette : lightTheme.palette
    );

    const useStyles = makeStyles({
        label: { width: '100%', display: 'flex', justifyContent: 'flex-start' },
        search: { width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '10px' },
        row: { display: 'flex', width: '100%', marginTop: '11px', alignItems: 'flex-start', justifyContent: 'flex-start'},
        searchField: { position: 'relative', display: 'inline-block'},
        searchIcon: { margin: '6px 0 0 6px', fontSize: '30px', color: theme.secondary.main, cursor: 'pointer'}
    });

    const classes = useStyles();
    const [searchValue, setSearchValue] = useState('');
    const { groupDetails } = props;

    const UsersActionButtons = props => (
        <Fragment>
            <SelectUsersButton group={groupDetails} {...props} />
        </Fragment>
    );

    const RoleType = ({ record = {} }) => {
        const { isAdmin } = record;
    
        return (
            <span>
                { isAdmin ? 'Administrator' : 'End User' }
            </span>
        );
    };

    const UsersFilter = (props) => {
        const { searchValue, setSearchValue } = props;
        const [inputValue, setInputValue] = useState(searchValue);

        const handleChange = (value) => {
            setInputValue(value);
            if(!value) setSearchValue('');
        };

        const onSearch = () => {
            setSearchValue(inputValue);
        };

        return(
            <Fragment>
                <div className={classes.row}>
                    <CustomLabel title="Select users" className={classes.label}/>
                    <div className={classes.search}>
                        <div className={classes.searchField}>
                            <SearchField
                                label="Search"
                                onChange={(e) => handleChange(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && (onSearch())}
                                variant="outlined"
                                value={inputValue}
                                source="search"
                                type="search"
                                size="small"
                            />
                        </div>
                        <SearchIcon
                            onClick={onSearch}
                            className={classes.searchIcon}
                        />
                    </div>
                </div>
            </Fragment>
        );
    };

    const FullNameField = ({ record = {} }) => <span>{record.name} {record.surname}</span>;

    const CancelButton = () => {
        const onCancel = () => {
            handleClose();
        };

        return(
            <CustomButton
                label="Cancel"
                type="default"
                onClick={onCancel}
                style={{ marginLeft: '47px', marginTop: '20px' }}
            />
        )
    };
    
    return(
        <Fragment>
            <List resource="getGroupUsers"
                filter={searchValue ? ({ Name: searchValue, groupId: groupDetails.id, isAdmin: false }) : { groupId: groupDetails.id, isAdmin: false }}
                filters={<UsersFilter searchValue={searchValue} setSearchValue={setSearchValue} />}
                bulkActionButtons={<UsersActionButtons/>}
                basePath=""
                actions={null}
                hasEdit={false}
                hasCreate={false}
                hasShow={true}
                hasList={true}
                style={{ width: '90%', margin: '0 auto' }}
                sort={{ field: 'id', order: 'ASC' }}
            >
                <Datagrid>
                    <TextField source="id" />
                    <FullNameField source="name" label="Name" />
                    <TextField source="userName" label="Phone Number" />
                    <RoleType label={'Role'} />
                </Datagrid>
            </List>
            <CancelButton />
        </Fragment>
    );
};

export default AddUsersToGroup;