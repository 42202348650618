import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink } from 'react-admin';
import UsersIcon from '@material-ui/icons/PeopleAltOutlined';
import SubMenu from '../SubMenu';

const SupportSidebar = ({
    open,
    dense,
    onMenuClick,
    menuItemColor,

}) => {
    const pathname = useSelector(state =>
        state.router.location.pathname
    );

    const [menuCityManagement, setMenuCityManagement] = useState(false); // (Local state)
    const handleCityMenu = () => {
        setMenuCityManagement(!menuCityManagement);
    };

    return (
        <div>
            <SubMenu
                handleToggle={() => handleCityMenu()}
                isOpen={menuCityManagement}
                sidebarIsOpen={open}
                name={'Support'}
                icon={<UsersIcon style={{ color: menuItemColor }} />}
                dense={dense}
                style={{ color: menuItemColor }}>
                <MenuItemLink
                    to={`/support`}
                    primaryText={'Support'}
                    leftIcon={<UsersIcon style={{ color: menuItemColor }} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    style={{ color: menuItemColor }}
                    selected={pathname === '/support'}
                />
                <MenuItemLink
                    to={`/events`}
                    primaryText={'Events'}
                    leftIcon={<UsersIcon style={{ color: menuItemColor }} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    style={{ color: menuItemColor }}
                    selected={pathname === '/events'}
                />
            </SubMenu>
        </div>
    );
};

export default SupportSidebar;
