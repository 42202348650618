import React, { useState, useEffect, Fragment } from 'react';

import MuiAlert from '@material-ui/lab/Alert';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { TextField, Tooltip, makeStyles, Snackbar } from '@material-ui/core';

import CustomButton from '../shared/CustomButton';

import config from '../../config/config';
import { useHistory } from 'react-router';

const useStyles = makeStyles({
    flexContainer: { height: '310px', width: '500px', marginLeft: '15px' },
    row: { display: 'flex', alignItems: 'center', marginBottom: 20, width: '100%' },
    tooltipIcon: { paddingLeft: '3px', width: '20px', height: '20px', color: '#4e6a77', cursor: 'pointer'},
    errorText: { color: '#e57373', fontSize: '14px', fontWeight: 600, display: 'block', textAlign: 'left', backgroundColor: '#eee' },
    field: { backgroundColor: 'white' }
});

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} style={{ backgroundColor: '#e57373' }} />;
};

const ChangePassword = ({ userId, resetCode, screenChange }) => {
    const classes = useStyles();
    const history = useHistory();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[.#$!*@&])[A-Za-z\d#$.!*@&]{8,}$|^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])[A-Za-z\d#.$!*@&]{8,}$/;

    const [formValid, setFormValid] = useState(true);
    const [regexError, setRegexError] = useState(false);
    
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordError, setNewPasswordError] = useState(false);

    const [repeatPassword, setRepeatPassword] = useState('');
    const [repeatPasswordError, setRepeatPasswordError] = useState(false);

    const [passwordsMatchError, setPasswordsMatchError] = useState(false);

    useEffect(() => {
        if(newPassword && repeatPassword) {
            setRegexError(false);
            return setFormValid(true);
        }
    }, [newPassword, repeatPassword]);

    const handleRepeatPassword = (value) => {
        setRepeatPassword(value);

        if(repeatPassword) setRepeatPasswordError(false);
        setPasswordsMatchError(false);
    };

    const validateRepeatPassword = (e) => {
        if(repeatPassword.length === 0) {
            return setRepeatPasswordError(true);
        }
        setRepeatPasswordError(false);
    };

    const handleNewPassword = (value) => {
        setNewPassword(value);

        if(newPassword) setNewPasswordError(false);
        setPasswordsMatchError(false);
    };

    const validateNewPassword = (e) => {
        if(newPassword.length === 0) {
            return setNewPasswordError(true);
        }
        setNewPasswordError(false);
    };

    const onCancel = () => {
        history.push('/login');
    };

    const onChangePassword = () => {
        if(!newPassword || !repeatPassword) {
            return setFormValid(false);
        }

        const validPassword = regex.test(newPassword);
        if(!validPassword) {
            setFormValid(true);
            return setRegexError(true);
        }

        if(newPassword !== repeatPassword) {
            return setPasswordsMatchError(true);
        }

        setFormValid(true);
        setRegexError(false);
        setPasswordsMatchError(false);

        const params = {
            userId,
            resetCode,
            password: newPassword
        };

        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Account/ResetPassword`;

        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(params)
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                if(response && response.success) {
                    return screenChange(3);
                }

                if(response && response.error) {
                    setSnackbarMessage(response.error.message);
                    setSnackbarOpen(true);
                }
            });
    };

    const PasswordTooltip = (
        <div style={{fontSize: '11px'}}>
            <p>Minimum password length is 8 characters.</p>
            <p>Password must contain only alphanumeric characters and any of the special characters - !@#$& -</p>
            <p>Password must contain at least one upper case and one lower case letter.</p>
            <p>Password must contain at least one numeric or special character.</p>
        </div>
    );

    return (
        <Fragment>
            <div className={classes.flexContainer}>
                <div className={classes.row}>
                    <TextField
                        label="New Password"
                        helperText= {newPasswordError && 'This field is required'}
                        required
                        error={newPasswordError}
                        type="password"
                        variant="outlined"
                        value={newPassword}
                        onChange={(e) => handleNewPassword(e.target.value)}
                        onBlur={() => validateNewPassword()}
                        className={classes.field}
                    />
                    <Tooltip title={PasswordTooltip} placement="right">
                        <InfoOutlinedIcon className={classes.tooltipIcon}/>
                    </Tooltip>
                </div>
                <div className={classes.row}>
                    <TextField
                        label="Repeat Password"
                        helperText= {repeatPasswordError && 'This field is required'}
                        required
                        error={repeatPasswordError}
                        type="password"
                        variant="outlined"
                        value={repeatPassword}
                        onChange={(e) => handleRepeatPassword(e.target.value)}
                        onBlur={() => validateRepeatPassword()}
                        className={classes.field}
                    />
                </div>
                <div>
                    {!formValid && <span className={classes.errorText}>Please fill in all fields</span>}
                    {regexError && <span className={classes.errorText}>New password is not valid</span>}
                    {passwordsMatchError && <span className={classes.errorText}>Repeated password does not match the new password</span>}
                </div>
            </div>
            <div style={{ margin: '20px auto 0 15px' }}>
                <CustomButton label="Change password" type="primary" onClick={onChangePassword} style={{ marginBottom: '30px' }} />
                <CustomButton label="Cancel" type="default" onClick={onCancel} style={{ marginLeft: '20px'}} />
            </div>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity="error">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Fragment>
    );
};

export default ChangePassword;