import React from 'react';
import { NotFound } from 'react-admin';
import { useSelector } from 'react-redux';
import CustomLabel from '../shared/CustomLabel';


const AdminConfiguration = props => {
    const permissions = useSelector(state =>
        state.permissions
    );

    return (
        <>
            {permissions === 'admin' ? (
                <CustomLabel title={'Configuration'} />
            ) : <NotFound />}
        </>
    );
};

export default AdminConfiguration;