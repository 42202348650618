import config from '../config/config';
import {
    store
} from '../redux/store';
import {
    createQueryParams,
    cleanResource,
    getManyIdsFilter,
    getGroupListParams,
    getUserUpdateParams,
    getUserGroupUpdateParams
} from './helpers';
import {
    GET_LIST,
    GET_ONE,
    CREATE,
    UPDATE,
    DELETE
} from 'react-admin';
// GET_MANY, GET_MANY_REFERENCE, UPDATE_MANY, DELETE_MANY

const baseUrl = config.apiBaseUrl;

export default (type, resourceType, params) => {
    let url = '';
    const resource = cleanResource(resourceType);
    const apiUrl = `${baseUrl}/${resource}`;

    const token = localStorage.getItem('access_token');
    const options = {
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        })
    };
    console.log(type, resourceType, resource, params);
    switch (type) {
        case GET_LIST:
            if (resource === 'getGroupUsers') {
                url = `${baseUrl}/User/GetGroupUsers`;

                const groupParams = getGroupListParams(params);
                options.body = JSON.stringify(groupParams);
            } else if (resource === 'accessLogs' || resource === 'messageList') {
                const getUrl = {
                    accessLogs: 'GetAccessLogsList',
                    messageList: 'GetMessageList'
                };
                url = `${baseUrl}/User/${getUrl[resource]}`;

                const logsQueryParams = createQueryParams(params);
                options.body = JSON.stringify(logsQueryParams);
            } else if (resource === 'paymentLogs') {

                url = `${baseUrl}/User/GetPaymentList`;

                const logsQueryParams = createQueryParams(params);
                options.body = JSON.stringify(logsQueryParams);
            } else if (resource === 'locationdoors') {
                url = `${baseUrl}/location/GetDoorList`;
                const queryParams = createQueryParams(params);
                options.body = JSON.stringify(queryParams);
            }
            else if (resource === 'doors') {
                url = `${baseUrl}/Door/GetList`;
                const queryParams = createQueryParams(params);
                options.body = JSON.stringify(queryParams);
            } else if (resource === 'diagnostic') {
                url = `${baseUrl}/diagnostic/GetDiagnosticInfo`;
                const queryParams = createQueryParams(params);
                options.body = JSON.stringify(queryParams);
            } else if (resource === 'getDiagnosticsPerDoor') {
                url = `${baseUrl}/diagnostic/GetList`;
                const queryParams = createQueryParams(params);
                options.body = JSON.stringify(queryParams);
            }
            else {
                url = `${apiUrl}/GetList`;

                const queryParams = createQueryParams(params);
                options.body = JSON.stringify(queryParams);
            }

            options.method = 'POST';
            break;

        case GET_ONE:
            url = `${apiUrl}/Get?id=${params.id}`;

            if (resource === "User") url = `${apiUrl}/GetUserById?id=${params.id}`;
            if (resource === "Order") url = `${apiUrl}/GetById?id=${params.id}`;
            if (resource === "diagnostic") url = null;
            break;

        case 'GET_MANY':
            url = `${apiUrl}/GetList`;

            let idsFilter = getManyIdsFilter([...params.ids]);

            let newParams = Object.assign({}, {
                pagination: {
                    page: 1,
                    perPage: 100
                },
                sort: {
                    field: 'Id',
                    order: 'ASC'
                }
            });

            let queryParamsGetMany = createQueryParams(newParams);
            queryParamsGetMany = Object.assign(queryParamsGetMany, idsFilter);

            options.method = 'POST';
            options.body = JSON.stringify(queryParamsGetMany);
            break;

        case CREATE:
            url = `${apiUrl}/Create`;

            if (resource === 'User') url = `${apiUrl}/CreateOrUpdateUser`;
            if (resource === 'Group') url = `${apiUrl}/CreateOrUpdateGroup`;
            if (resource === 'MessageTemplate') url = `${apiUrl}/CreateOrUpdateMessageTemplate`;
            if (resource === 'Location') {
                const state = store.getState();
                const location = state.locationMap;
                let data = Object.assign({}, params.data);
                if (location.latitude) data = Object.assign(data, location);
                options.body = JSON.stringify(data);
            }  else {
                options.body = JSON.stringify(params.data);
            }

            options.method = 'POST';
            break;

        case UPDATE:
            if (resource === 'User') {
                //users
                url = `${apiUrl}/CreateOrUpdateUser`;

                const userUpdateParams = getUserUpdateParams(params);

                options.body = JSON.stringify(userUpdateParams);
                options.method = 'POST';
            } else if (resource === 'Group') {
                // user-groups
                url = `${apiUrl}/CreateOrUpdateGroup`;

                const userGroupUpdateParams = getUserGroupUpdateParams(params);

                options.body = JSON.stringify(userGroupUpdateParams);
                options.method = 'POST';
            } else if (resource === 'MessageTemplate') {
                // message templates
                url = `${apiUrl}/CreateOrUpdateMessageTemplate`;
                options.body = JSON.stringify(params.data);
                options.method = 'POST';
            } else {
                // default
                url = `${apiUrl}/Update`;
                options.body = JSON.stringify(params.data);
                options.method = 'PUT';

                // location
                if (resource === 'saveAccessControl') url = `${baseUrl}/location/SaveAccessControl`;
                if (resource === 'saveLocationPrice') url = `${baseUrl}/location/SaveLocationPrice`;

                //Client
                if (resource === 'SaveProperties') url = `${baseUrl}/client/SaveProperties`;
            }

            break;

        case DELETE:
            url = `${apiUrl}/Delete?id=${params.id}`;
            //
            if (resource === 'User') url = `${apiUrl}/DeleteUser?id=${params.id}`;
            if (resource === 'work-locations') url = `${baseUrl}/Location/Delete?id=${params.id}`;
            //
            options.method = 'DELETE';
            break;

        case 'GET_FORM_DATA':
            url = `${baseUrl}/${resource}`;
            options.method = 'GET';
            break;

        case 'GET_TEMPLATE_LANGUAGES':
            url = `${baseUrl}/${resource}`;
            options.method = 'GET';
            break;

        default:
            throw new Error(`Unsupported Data Provider request type ${type}`);
    }

    console.log(url, options);

    return fetch(url, options)
        .then(res => res.json())
        .then(response => {
            switch (type) {
                case GET_LIST:
                case 'GET_FORM_DATA':
                case 'GET_MANY':
                    const {
                        items, totalCount
                    } = response.result;
                    return {
                        data: items,
                        total: totalCount
                    }
                case GET_ONE:
                case CREATE:
                case UPDATE:
                case 'GET_TEMPLATE_LANGUAGES':
                    return {
                        data: response.result
                    }
                case DELETE:
                    return {
                        data: {}
                    }
                default:
                    throw new Error(`Unsupported Data Provider type ${type}`);
            }
        });
};
