import React, { Fragment } from "react";
import { useSelector } from 'react-redux';
import { Show, FormTab, TabbedForm, NotFound } from "react-admin";
import { darkTheme, lightTheme } from "../layout/themes";

import OrderDetailsTab from './order-tabs/OrderDetailsTab';
import OrderInvoiceTab from './order-tabs/OrderInvoiceTab';

import CustomTitle from '../shared/CustomTitle';
import { useRouteMatch} from 'react-router-dom';

export default function OrdersShow(props) {
    const labelColor = useSelector(state =>
        state.theme === "dark" ?
            darkTheme.palette.iconsColor
            : lightTheme.palette.iconsColor
    );

    const permissions = useSelector(state =>
        state.permissions
    );
    const { params: { id } } = useRouteMatch();

    return (
        <Fragment>
            {permissions === 'admin' ? (
                <Fragment>
                    <CustomTitle title={`Order "${id}"`}/>
                    <Show {...props}>
                        <TabbedForm toolbar={null}>
                            <FormTab label={'Details'}>
                                <OrderDetailsTab labelColor={labelColor} />
                            </FormTab>
                            <FormTab label={'Invoice/Shipping'} path="invoice">
                                <OrderInvoiceTab labelColor={labelColor} />
                            </FormTab>
                        </TabbedForm>
                    </Show>
                </Fragment>
            ) : <NotFound />}
        </Fragment>
    );
};
