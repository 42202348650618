import React, { useState, Fragment } from "react";
import { useSelector } from 'react-redux';
import { Create, FormTab, TabbedForm, SaveButton, Toolbar, NotFound } from "react-admin";
import { darkTheme, lightTheme } from "../layout/themes";
import CustomLabel from '../shared/CustomLabel';
import CustomButton from '../shared/CustomButton';

import UserCreateDetailsTab from './user-create-tabs/UserCreateDetailsTab';
import UserCreatePasswordTab from './user-create-tabs/UserCreatePasswordTab';

import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";

const styles = {
    createToolbar: { display: 'flex', justifyContent: 'flex-end' }
};

const useStyles = makeStyles(styles);

export default function UserCreate(props) {
    const permissions = useSelector(state =>
        state.permissions
    );

    const userAccessLevel = useSelector(state =>
        state.user.accessLevel
    );

    const hasFullAccess = true;

    const labelColor = useSelector(state =>
        state.theme === "dark" ?
            darkTheme.palette.iconsColor
            : lightTheme.palette.iconsColor
    );

    const [validationError, setValidationError] = useState(labelColor);

    const handleChange = (newValue) => {
        setValidationError(newValue);
    };

    const CreateToolbar = ({ basePath, ...props }) => {
        const classes = useStyles();
        const history = useHistory();

        const onCancel = (e) => {
            e.preventDefault();
            history.push('/users');
        };

        return (
            <div>
                <Toolbar {...props} className={classes.createToolbar}>
                    <CustomButton type="default" label="Cancel" onClick={onCancel} style={{ marginTop: '30px', marginRight: '20px' }}/>
                    <SaveButton basePath={basePath} />
                </Toolbar>
            </div>
        );
    };

    return (
        <>
            {hasFullAccess ? (
                <>
                    <CustomLabel title={'New User'} />
                    <Create {...props}>
                        <TabbedForm redirect="/users" toolbar={<CreateToolbar />}>
                            <FormTab label="User Details">
                                <UserCreateDetailsTab labelColor={labelColor} />
                            </FormTab>
                            <FormTab label="Password" style={{ color: validationError}}>
                                <UserCreatePasswordTab handlePasswordValidation={handleChange} labelColor={labelColor} isDialogForm={false} />
                            </FormTab>
                        </TabbedForm>
                    </Create>
                </>
            ) : <NotFound />}
        </>
    );
};