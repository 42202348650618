import React, { Fragment } from "react";
import { useSelector } from 'react-redux';
import { Edit, FormTab, TabbedForm, Toolbar, SaveButton } from "react-admin";
import { darkTheme, lightTheme } from "../layout/themes";
import UserDetailsTab from './user-tabs/UserDetailsTab';
import UserOrdersTab from './user-tabs/UserOrdersTab';
import UserGroupsTab from './user-tabs/UserGroupsTab';
import UserPaymentOptionsTab from "./user-tabs/UserPaymentOptionsTab";
import UserAccessLogsTab from "./user-tabs/UserAccessLogsTab";
import UserMessageLogsTab from "./user-tabs/UserMessageLogsTab";
import CustomTitle from '../shared/CustomTitle';
import CustomButton from '../shared/CustomButton';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";

const styles = {
    editToolbar: { display: 'flex', justifyContent: 'flex-end', '& button': {
            margin: '0 0 0 20px !important'
        }
    }
};

const useStyles = makeStyles(styles);

export default function UsersEdit(props) {
    const classes = useStyles();
    const history = useHistory();

    const permissions = useSelector(state =>
        state.permissions
    );

    const accessLevel = useSelector(state =>
        state.user.accessLevel
    );

    const hasEditAccess = true;

    const labelColor = useSelector(state =>
        state.theme === "dark" ?
            darkTheme.palette.iconsColor
            : lightTheme.palette.iconsColor
    );

    const onCancel = (e) => {
        e.preventDefault();
        history.push('/users');
    };

    const { params: { id } } = useRouteMatch();

    const user = useSelector(state => state.admin.resources.users.data[id]);
    const name = user ? `${user.name} ${user.surname}` : '';

    const EditToolbar = props => (
        <Toolbar {...props} className={classes.editToolbar}>
            <CustomButton type="default" label={!hasEditAccess ? 'Go Back' : 'Cancel'} onClick={onCancel} />
            {hasEditAccess ? <SaveButton /> : null}
        </Toolbar>
    );

    return (
        <Fragment>
            <CustomTitle title={`User "${name}"`}/>
            <Edit {...props}>
                <TabbedForm toolbar={<EditToolbar />}>
                    <FormTab label="User Details">
                        <UserDetailsTab labelColor={labelColor} hasEditAccess={hasEditAccess} />
                    </FormTab>
                    {(user && !user.isAdmin) && (
                        <FormTab label="Orders" path="orders">
                            <UserOrdersTab labelColor={labelColor} />
                        </FormTab>
                    )}
                    {(user && !user.isAdmin) && (
                        <FormTab label="User Groups" path="user-groups">
                            <UserGroupsTab labelColor={labelColor} />
                        </FormTab>
                    )}
                    {(user && !user.isAdmin) && (
                        <FormTab label="Payment Options" path="payment-options">
                            <UserPaymentOptionsTab />
                        </FormTab>
                    )}
                    {(user && !user.isAdmin) && (
                        <FormTab label="Access Log" path="access-log">
                            <UserAccessLogsTab labelColor={labelColor} />
                        </FormTab>
                    )}
                    {(user && !user.isAdmin) && (
                        <FormTab label="Message Logs" path="message-logs">
                            <UserMessageLogsTab labelColor={labelColor} />
                        </FormTab>
                    )}
                </TabbedForm>
            </Edit>
        </Fragment>
    );
};
