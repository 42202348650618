import React from 'react';
import { Pie } from "react-chartjs-2";
// Do not delete (used to show data labels if datalabels: {display: true })
// eslint-disable-next-line
import ChartDataLabels from 'chartjs-plugin-datalabels';

/////////////////////         PROPS        /////////////////////
/*

props: {
    //required
    data: [], 
    backgroundColor: [],
    
    // optional
    title: strig,
    labels: [], // if null, it will hide legend
    legend: boolean,
    legendPosition: 'left' / 'top' / 'right' / 'bottom',
    percentageDataValues: boolean, // if true, datavalues will be in %
    dataLabels: boolean, // set to false if you want to hide datalabels
    dataLabelsColor: string,
    dataLabelsAlign: 'start' / 'center' / 'end',
    dataLabelsAnchor: 'start' / 'center' / 'end',
    titlePosition: 'left' / 'top' / 'right' / 'bottom',
    titleFontSize: number,
    usePointStyle: boolean // false - if you want rectangular legend style
}

*/
////////////////////////////////////////////////////////////////

const PieChart = props => {
    console.log(props);
    const data = {
        labels: props.labels || [],
        datasets: [{
            data: props.data,
            backgroundColor: props.backgroundColor,
            // hoverBackgroundColor: [
            //     '#ccc',
            //     '#eee',
            //     '#eee'
            // ]
        }]
    };

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            datalabels: {
                display: props.dataLabels || false,
                color: props.dataLabelsColor || '#fff',
                align: props.dataLabelsAlign || 'center',
                anchor: props.dataLabelsAnchor || 'center',
                formatter: (value) => {
                    if (props.percentageDataValues) {
                        let sum = 0;
                        let dataArr = data.datasets[0].data;

                        dataArr.map(data => sum += data);

                        let percentage = (value * 100 / sum).toFixed(2) + "%";

                        return percentage;
                    }

                    return value;
                },
            }
        },
        animation: {
            duration: 0
        },
        title: {
            display: props.title ? true : false,
            text: props.title,
            position: props.titlePosition || 'top',
            fontSize: props.titleFontSize || 18
        },
        legend: {
            display: 'props.labels || props.legend',
            position: props.legendPosition || 'left',
            labels: {
                generateLabels: function () {
                    if (props.percentageDataValues) {
                        const chartData = data.datasets[0].data;
                        return chartData.map(function (value, i) {
                            return {
                                text: data.labels[i] + ": " + value,
                                index: i,
                                fillStyle: data.datasets[0].backgroundColor[i]
                            };
                        });
                    } else {
                        return data.labels.map(function (value, i) {
                            return {
                                text: value,
                                index: i,
                                fillStyle: data.datasets[0].backgroundColor[i]
                            };
                        });
                    }
                },
                usePointStyle: props.usePointStyle !== undefined ? props.usePointStyle : true,
                padding: props.labels && props.labels.length ? 20 : 0
            }
        },
        layout: {
            padding: 30
        }
    }

    return (
        <Pie data={data} options={options} />
    );
};

export default PieChart;