import React, { Fragment, useEffect } from "react";
import { TextInput, required, regex } from "react-admin";
import { useSelector } from 'react-redux';
import { Tooltip, makeStyles } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useFormState } from 'react-final-form';
import { darkTheme, lightTheme } from "../../layout/themes";

export default function UserCreatePasswordTab({handlePasswordValidation, labelColor, isDialogForm, ...props}) {
    const theme = useSelector(state =>
        state.theme === "dark" ? darkTheme.palette : lightTheme.palette
    );

    const styles = {
        flexContainer: { width: '100%' },
        select: { maxWidth: isDialogForm ? '35%' : '22%' },
        row: { display: 'flex', alignItems: 'center', marginBottom: 20, width: '100%' },
        icon: { paddingLeft: '3px', width: '20px', height: '20px', color: theme.secondary.main, cursor: 'pointer'}
    };

    const PasswordTooltip = (
        <div style={{fontSize: '11px'}}>
            <p>Minimum password length is 8 characters.</p>
            <p>Password must contain only alphanumeric characters and any of the special characters - !@#$& -</p>
            <p>Password must contain at least one upper case and one lower case letter.</p>
            <p>Password must contain at least one numeric or special character.</p>
        </div>
    );
    
    const useStyles = makeStyles(styles);
    const classes = useStyles();

    const regexValidation = regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[.#$!*@&])[A-Za-z\d#$.!*@&]{8,}$|^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])[A-Za-z\d#.$!*@&]{8,}$/, 'Password is not valid');
    
    const isSameAsPassword = (value, allValues) => {
        if(allValues.user.password !== value) {
            return 'Repeated password does not match the new password';
        }
        return false;
    };

    const formState = useFormState();

    const setPasswordError =
        // check if password fields failed submit validation
        (!formState.valid && formState.submitFailed && formState.errors.user.password && formState.errors.user.repeatPassword)
        ? true : false;

    const validatePassword = [required(), regexValidation];
    const checkRepeatedPassword = [required(), isSameAsPassword];

    useEffect(()=>{
        if(isDialogForm) return; // creating admin from client/administrators dialog form, no label styling required

        if(setPasswordError) {
            handlePasswordValidation('red'); // set tab label color
        } else if(formState.valid || !(formState.errors.user.password || formState.errors.user.repeatPassword)) {
            handlePasswordValidation(labelColor);
        }
    }, [formState, handlePasswordValidation, setPasswordError, labelColor, isDialogForm]);

    return (
        <Fragment>
            <div className={classes.flexContainer}>
                <div className={classes.row}>
                    <TextInput
                        type="password"
                        autoComplete="new-password"
                        label="New Password"
                        source="user.password"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        validate={validatePassword}
                    />
                    <Tooltip title={PasswordTooltip} placement="right">
                        <InfoOutlinedIcon className={classes.icon}/>
                    </Tooltip>
                </div>
                <div className={classes.row}>
                    <TextInput
                        type="password"
                        autoComplete="new-password"
                        label="Repeat Password"
                        source="user.repeatPassword"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        validate={checkRepeatedPassword}
                    />
                </div>
            </div>
        </Fragment>
    );
};