import React, { useEffect } from 'react';
import { useNotify, useUnselectAll, refreshView } from 'react-admin';
import CustomButton from '../../../shared/CustomButton';

import config from '../../../../config/config';
import { useDispatch } from 'react-redux';

const SelectUsersButton = ({ selectedIds, group }) => {
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const dispatch = useDispatch();

    const endpoint = `${config.apiBaseUrl}/Group/CreateOrUpdateGroup`;

    useEffect(()=> {
        return () => {
            unselectAll('getGroupUsers');
        };
    },[unselectAll]);

    const selectUsers = async () => {
        const userIds = group.users.map(user => user.id);
        
        const params = {
            id: group.id,
            clientId: group.client ? group.client.id : null,
            name: group.name,
            description: group.description ? group.description : null,
            userIds: [...userIds, ...selectedIds]
        };
        
        const token = localStorage.getItem('access_token');
        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(params)
        });
        
        try {
            await fetch(request);
            const isPlural = selectedIds.length > 1 ? 'Users' : 'User';
            notify(`Selected ${isPlural} added to the Group`);
            unselectAll('getGroupUsers');
            dispatch(refreshView());
        } catch(err) {
            notify(err.message, 'warning');
        }
    }

    return (
        <CustomButton
            label="Add selected Users to the Group"
            onClick={selectUsers}
            type="primary"
            style={{ marginBottom: '15px', marginTop: '15px' }}
        />
    );
};

export default SelectUsersButton;