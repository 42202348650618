import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import LoginBackground from '../../assets/images/login-background.png';
import { Notification, useTranslate, useLogin, useNotify } from 'react-admin';
import { lightTheme } from './themes';
import { useHistory } from 'react-router';
import {
    Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Portal, Snackbar, CircularProgress, Button, Card,
    Avatar, CardActions, TextField, createMuiTheme, makeStyles
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import CustomButton from '../shared/CustomButton';
import ReactCodeInput from 'react-verification-code-input';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import config from '../../config/config';


const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: `url(${LoginBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    forgotPassword: {
        padding: 15,
        textAlign: 'center',
        '& p': {
            fontSize: '14px',
            fontWeight: '700',
            width: 'max-content',
            margin: '0 auto',
            cursor: 'pointer',
            color: '#562219'
        }
    },
    codeInput: {
        width: 'auto!important',
        textAlign: 'center',
        '& input': {
            border: 'none!important',
            borderBottom: '2px solid grey!important',
            marginRight: 15,
            borderRadius: 'unset!important',
            fontFamily: 'Roboto, -apple-system, "Segoe UI", Arial, sans-serif',
            caretColor: `${lightTheme.palette.primary.main}!important`
        }
    },
    closeBtn: { position: 'absolute', right: 3, top: 2 },
    colorPrimary: { color: theme.palette.primary.main },
    anotherOptionBtn: { color: '#666', fontSize: 13, fontWeight: 400, textDecoration: 'underline', '&:hover': { backgroundColor: 'inherit' } },
    dialogActions: { padding: '0 24px 24px', marginTop: 65, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
    loaderWrapper: { width: '100%', height: '100%', display: 'flex' },
    loader: { height: 100, margin: 'auto', width: 100 },
    dialog: { '& .MuiDialog-paperWidthSm': { minWidth: 400 } }
}));

const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth={true}
    />
);

const TwoFactorLoginDialog = ({ isOpen, credentials, onSubmit, onCancel, codeSendMethod, userId }) => {
    const classes = useStyles();
    const [twoFactorVerificationCode, setTwoFactorVerificationCode] = useState(null);
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [codeMethod, setCodeMethod] = useState(codeSendMethod);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [codeResendLoader, setCodeResendLoader] = useState(false);

    const onCodeInputChange = val => {
        if (val.length < 4) {
            setButtonEnabled(false);
        }
    };

    const onComplete = val => {
        setTwoFactorVerificationCode(val);
        setButtonEnabled(true);
    };

    const resendCode = (e, isAnotherOption) => {
        let provider = codeMethod;
        setCodeResendLoader(true);
        if (isAnotherOption) {
            provider = codeMethod === 1 ? 2 : 1;
        }

        const endpoint = `${config.authBaseUrl}/TokenAuth/ResendCodeForAdmin?userId=${userId}&provider=${provider}`;
        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                    setTimeout(() => {
                        setSuccessSnackbarOpen(true);
                        setCodeMethod(provider);
                        setCodeResendLoader(false);
                    }, 2000);
                } else {
                    console.error(response.error);
                    setCodeResendLoader(false);
                    setErrorSnackbarOpen(true);
                }
            })
            .catch(error => {
                console.error(error);
                setErrorSnackbarOpen(true);
            })
    };

    useEffect(() => {
        setCodeMethod(codeSendMethod);
    }, [codeSendMethod])

    return (
        <>
            {isOpen &&
                <Dialog
                    open={isOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='sm'
                    className={classes.dialog}
                >
                    {codeResendLoader &&
                        <CircularProgress
                            size={20}
                            style={{ position: 'absolute', left: 195, top: 22 }}
                        />
                    }
                    <IconButton
                        onClick={() => {
                            setButtonEnabled(false);
                            setCodeMethod(codeSendMethod);
                            onCancel();
                        }}
                        className={classes.closeBtn}
                    >
                        <CloseIcon style={{ width: 20 }} />
                    </IconButton>
                    <DialogTitle id="alert-dialog-title">Two-Factor Login</DialogTitle>
                    <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
                        <p style={{ color: '#666', marginTop: 0, marginBottom: 20 }}>{`Enter the 4 digit code we sent to your ${codeMethod === 1 ? 'phone' : 'email'}.`}</p>
                        <ReactCodeInput
                            type="number"
                            fields={4}
                            onChange={onCodeInputChange}
                            onComplete={val => onComplete(val)}
                            className={classes.codeInput}
                        />
                        <Button
                            className={classes.colorPrimary}
                            disabled={codeResendLoader}
                            startIcon={<RefreshIcon style={{ marginRight: -4 }} />}
                            onClick={resendCode}
                            style={{ width: 130, marginTop: 20, alignSelf: 'flex-end' }}
                        >
                            Resend code
                        </Button>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button className={classes.anotherOptionBtn} disabled={codeResendLoader} disableRipple={true} onClick={e => resendCode(e, true)}>
                            {`Send code to ${codeMethod === 1 ? 'email' : 'sms'} instead`}
                        </Button>
                        <CustomButton
                            type="primary"
                            label='Confirm'
                            disabled={!buttonEnabled}
                            onClick={() => onSubmit({ ...credentials, twoFactorVerificationCode })}
                            style={{ minWidth: 120, marginLeft: 15 }} />
                    </DialogActions>
                </Dialog>
            }
            <Portal>
                <Snackbar
                    open={successSnackbarOpen}
                    autoHideDuration={4000}
                    onClose={() => setSuccessSnackbarOpen(false)}
                >
                    <MuiAlert elevation={6} variant="filled" onClose={() => setSuccessSnackbarOpen(false)} severity="success">
                        Code successfully sent.
                    </MuiAlert>
                </Snackbar>
            </Portal>
            <Portal>
                <Snackbar
                    open={errorSnackbarOpen}
                    autoHideDuration={4000}
                    onClose={() => setErrorSnackbarOpen(false)}
                >
                    <MuiAlert elevation={6} variant="filled" onClose={() => setErrorSnackbarOpen(false)} severity="error">
                        There was an error while sending the code. Please try again later.
                    </MuiAlert>
                </Snackbar>
            </Portal>
        </>
    )
};

const Login = ({ location }) => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const history = useHistory();
    const classes = useStyles();
    const notify = useNotify();
    const login = useLogin();

    const [twoFactorDialogOpen, setTwoFactorDialogOpen] = useState(false);
    const [credentials, setCredentials] = useState({});
    const [codeSendMethod, setCodeSendMethod] = useState(null);
    const [userId, setUserId] = useState(null);

    const handleLogin = auth => {
        setLoading(true);
        login(auth, location.state ? location.state.nextPathname : '/')
            .then(response => {
                setTwoFactorDialogOpen(false);
            })
            .catch(error => {
                setLoading(false);

                if (error?.requiresTwoFactorVerification) {
                    setCodeSendMethod(error.twoFactorAuthProviders);
                    setUserId(error.userId);
                    setCredentials(auth);
                    setTwoFactorDialogOpen(true);
                } else {
                    notify(
                        typeof error === 'string'
                            ? error
                            : typeof error === 'undefined' || !error.message
                                ? 'ra.auth.sign_in_error'
                                : error.message,
                        'warning'
                    );
                }
            });
    };

    const validate = values => {
        const errors = {};
        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    const onForgotPassword = () => {
        history.push('/reset-password');
    };

    return (
        <>
            <Form
                onSubmit={handleLogin}
                validate={validate}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <div className={classes.main}>
                            <Card className={classes.card}>
                                <div className={classes.avatar}>
                                    <Avatar className={classes.icon}>
                                        <LockIcon />
                                    </Avatar>
                                </div>
                                <div className={classes.form}>
                                    <div className={classes.input}>
                                        <Field
                                            // autoFocus
                                            name="username"
                                            component={renderInput}
                                            label={translate('ra.auth.username')}
                                            disabled={loading}
                                        />
                                    </div>
                                    <div className={classes.input}>
                                        <Field
                                            name="password"
                                            component={renderInput}
                                            label={translate('ra.auth.password')}
                                            type="password"
                                            disabled={loading}
                                        />
                                    </div>
                                </div>
                                <CardActions className={classes.actions}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        disabled={loading}
                                        className={classes.button}
                                        fullWidth={true}
                                    >
                                        {loading && (
                                            <CircularProgress
                                                size={25}
                                                thickness={2}
                                            />
                                        )}
                                        {translate('ra.auth.sign_in')}
                                    </Button>
                                </CardActions>
                                <div className={classes.forgotPassword}>
                                    <p onClick={onForgotPassword}>Forgot password?</p>
                                </div>
                            </Card>
                            <Notification />
                        </div>
                    </form>
                )}
            />
            <TwoFactorLoginDialog
                isOpen={twoFactorDialogOpen}
                credentials={credentials}
                codeSendMethod={codeSendMethod}
                userId={userId}
                onSubmit={handleLogin}
                onCancel={() => setTwoFactorDialogOpen(false)}
            />
        </>
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = props => (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <Login {...props} />
    </ThemeProvider>
);

export default LoginWithTheme;