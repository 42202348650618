import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Create, SimpleForm, TextInput, SelectInput, required, useQuery, useNotify, Toolbar, SaveButton, NotFound } from 'react-admin';

import { useHistory } from 'react-router-dom';

import RichTextInput from 'ra-input-rich-text';
import CustomLabel from '../shared/CustomLabel';
import CustomButton from '../shared/CustomButton';

import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { Collapse, IconButton, useMediaQuery, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    createToolbar: { display: 'flex', justifyContent: 'flex-end' }
});

const LanguageInput = ({ clientId, templateName }) => {
    const notify = useNotify();
    const [languages, setLanguages] = useState([]);

    const languageQuery = templateName ? 'GET_TEMPLATE_LANGUAGES' : 'GET_FORM_DATA';
    const resource = languageQuery === 'GET_FORM_DATA' ? 'Language/GetLanguages' : `MessageTemplate/GetFilteredLanguages?tplName=${templateName}&clientId=${clientId}`;

    useQuery(
        {
            type: languageQuery,
            resource
        },
        {
            onSuccess: ({ data }) => {
                const values = data.map(item => {
                    return {
                        id: item.id,
                        name: item.displayName
                    }
                });
                setLanguages(values);
            },
            onFailure: (error) => notify(error.message, 'warning')
        }
    );

    return (
        <SelectInput
            source="languageId"
            variant="standard"
            choices={languages}
            validate={required()}
        />
    );
};


const CreateToolbar = ({ basePath, isXsmall, ...props }) => {
    const classes = useStyles();
    const history = useHistory();

    const onCancel = (e) => {
        e.preventDefault();
        history.push('/message-templates');
    };

    return (
        <div>
            <Toolbar {...props} className={classes.createToolbar} style={{ position: isXsmall ? 'absolute' : null, bottom: isXsmall ? '20' : null }}>
                <CustomButton type="default" label="Cancel" onClick={onCancel} style={{ marginTop: '30px', marginRight: '20px' }} />
                <SaveButton basePath={basePath} />
            </Toolbar>
        </div>
    );
};

const MessageTemplatesCreate = (props) => {
    const history = useHistory();

    const permissions = useSelector(state =>
        state.permissions
    );

    const [open, setOpen] = useState(false);

    const record = (history.location.state) ? history.location.state.record : { clientId: null, name: null };
    const { clientId, name } = record;

    const isXsmall = useMediaQuery(theme => theme.breakpoints.down("xs"));

    useEffect(() => {
        if (record.clientId) {
            setOpen(true);
        }
    }, [record.clientId]);

    return (
        <Fragment>
            {permissions === 'admin' ? (
                <Fragment>
                    <CustomLabel title={'New Message Template'} />
                    <Collapse in={open}>
                        <Alert
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            severity="warning"
                            style={{ marginTop: '10px' }}
                        >
                            Do not change values in brackets {'{{ }}'}
                        </Alert>
                    </Collapse>
                    <Create title="Create Template" {...props}>
                        <SimpleForm toolbar={<CreateToolbar isXsmall={isXsmall} />} redirect={'/message-templates'}>
                            <TextInput
                                source="name"
                                validate={required()}
                                variant="standard"
                                style={{ width: isXsmall ? '80%' : '40%' }}
                                disabled={name !== null}
                            />
                            <LanguageInput clientId={clientId} templateName={name} />
                            <TextInput
                                source="subject"
                                label="Template subject"
                                // validate={required()}
                                variant="standard"
                                style={{ width: isXsmall ? '80%' : '40%', marginBottom: '40px' }}
                            />
                            <RichTextInput
                                source="text"
                                label="Template text"
                                validate={required()}
                                variant="standard"
                                style={{ height: '150px' }}
                                fullWidth={true}
                            />
                        </SimpleForm>
                    </Create>
                </Fragment>
            ) : <NotFound />}
        </Fragment>
    );
};

export default MessageTemplatesCreate;