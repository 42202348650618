import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { adminReducer, adminSaga, USER_LOGOUT } from 'react-admin';

import userReducer from './auth/userReducer';
import permissionsReducer from './auth/permissionsReducer';

import themeReducer from './theme/themeReducer';
import locationMapReducer from './locationMap/locationMapReducer';
import locationPricingReducer from './locationPricing/locationPricingReducer';
import locationWasActiveReducer from './locationPricing/locationWasActiveReducer';
import maintenanceReducer from './maintenance/maintenanceReducer';
import diagnosticsFiltersReducer from './diagnostics/filtersReducer';
import customerReducer from './auth/customerReducer';
import customerLocationReducer from './customerLocation/customerLocationReducer';

export default ({
    authProvider,
    dataProvider,
    history,
}) => {
    const reducer = combineReducers({
        admin: adminReducer,
        router: connectRouter(history),
        user: userReducer,
        permissions: permissionsReducer,
        theme: themeReducer,
        locationMap: locationMapReducer,
        locationWasActive: locationWasActiveReducer,
        locationPricing: locationPricingReducer,
        maintenance: maintenanceReducer,
        diagnostics: diagnosticsFiltersReducer,
        bikelyCustomer: customerReducer,
        customerLocation: customerLocationReducer
    });
    const resettableAppReducer = (state, action) =>
        reducer(action.type !== USER_LOGOUT ? state : undefined, action);

    const saga = function* rootSaga() {
        yield all(
            [
                adminSaga(dataProvider, authProvider),
                // add your own sagas here
            ].map(fork)
        );
    };
    const sagaMiddleware = createSagaMiddleware();

    const composeEnhancers =
        (process.env.NODE_ENV === 'development' &&
            typeof window !== 'undefined' &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                trace: true,
                traceLimit: 25,
            })) ||
        compose;
  
    const store = createStore(
        resettableAppReducer,
        composeEnhancers(
            applyMiddleware(
                sagaMiddleware,
                routerMiddleware(history),
                // add your own middlewares here
            ),
            // add your own enhancers here
        ),        
    );
    sagaMiddleware.run(saga);
    return store;
};