import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNotify, useQuery, Pagination } from 'react-admin';
import { capitalizeFirstLetter } from '../../../dataProvider/helpers';
import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, Button, DialogTitle, DialogContent } from "@material-ui/core";
import config from '../../../config/config';
import CustomButton from '../../shared/CustomButton';
import CreateAdministratorDialog from '../admin/clients-tabs/add-users/CreateAdministratorDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { darkTheme, lightTheme } from "../../layout/themes";

const useStyles = makeStyles({
    dialogPaper: { minWidth: '60%', height: '75%', borderRadius: 0, padding: '20px 20px 40px 20px' }
});

const AddUserBtn = props => {
    const theme = useSelector(state =>
        state.theme === "dark" ? darkTheme.palette : lightTheme.palette
    );

    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleClickOpen = (e) => {
        e.preventDefault();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        props.refreshTable();
    };

    return (
        <Fragment>
            <CustomButton
                label="Add"
                onClick={handleClickOpen}
                style={{ marginBottom: 30, width: 76 }}
                type="default"
            />
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={false}
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle id="scroll-dialog-title" style={{ color: theme.secondary.main }}>Create New Client Administrator</DialogTitle>
                <DialogContent>
                    <CreateAdministratorDialog refreshGrid={handleClose} clientId={props.clientId} />
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};

const AdministratorsTab = ({ clientId }) => {
    const theme = useSelector(state =>
        state.theme === "dark" ? darkTheme.palette : lightTheme.palette
    );

    const useStyles = makeStyles({
        table: { minWidth: 650 },
        deleteBtn: { color: theme.errorRed }
    });

    const classes = useStyles();

    const notify = useNotify();
    const history = useHistory();
    
    const user_id = useSelector(state =>
        state.user.id
    );
        
    const [administrators, setAdministrators] = useState([]);

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(0);

    useQuery(
        {
            type: 'GET_LIST',
            resource: 'users',
            payload: {
                pagination: {
                    page,
                    perPage
                },
                sort: {
                    field: 'id',
                    order: 'ASC'
                },
                filter: {
                    'IsAdmin': true
                }
            }
        },
        {
            onSuccess: ({ data, total }) => {
                setTotal(total);
                setAdministrators(data);
            },
            onFailure: (error) => notify(error.message, 'warning')
        }
    );

    const refreshTable = () => {
        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/User/GetList`;

        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                skipCount: 0,
                maxResultCount: 10,
                sorting: "id ASC",
                filterRule: {
                    condition: 'and',
                    filterRules: [
                        {
                            field: 'IsAdmin',
                            operator: 'equals',
                            value: true
                        }
                    ]
                }
            })
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                const data = response.result;
                setAdministrators([...data.items]);
            });
    };

    const onDelete = async (userId) => {
        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/User/DeleteUser?Id=${userId}`;

        const request = new Request(endpoint, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        try {
            await fetch(request);
            notify('User successfully removed', 'success');
            refreshTable();
        } catch (err) {
            notify(err.message, 'warning');
        }
    };

    const onEdit = (id) => {
        history.push(`/users/${id}`);
    };

    return (
        <Fragment>
            <AddUserBtn refreshTable={refreshTable} clientId={clientId} />
            <TableContainer component={Paper}>
                <Table size="small" className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>NAME</TableCell>
                            <TableCell>EMAIL</TableCell>
                            <TableCell>ROLE</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {administrators.map(admin => (
                            <TableRow key={admin.name}>
                                <TableCell component="th" scope="row">{admin.id}</TableCell>
                                <TableCell>
                                    {`${capitalizeFirstLetter(admin.name)} ${capitalizeFirstLetter(admin.surname)}`}
                                </TableCell>
                                <TableCell>{admin.emailAddress}</TableCell>
                                <TableCell>Administrator</TableCell>
                                <TableCell align="right">
                                    <Button aria-label="edit" color="primary" onClick={() => onEdit(admin.id)} startIcon={<EditIcon />}>
                                        Edit
                                    </Button>
                                </TableCell>
                                <TableCell align="right">
                                    {admin.id !== user_id &&
                                        <Button aria-label="delete" className={classes.deleteBtn} onClick={() => onDelete(admin.id)} startIcon={<DeleteIcon />}>
                                            Delete
                                    </Button>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                page={page}
                perPage={perPage}
                setPage={setPage}
                setPerPage={setPerPage}
                total={total}
            />
        </Fragment >
    );
};

export default AdministratorsTab;