import React, { Fragment, useState } from 'react';
import { useQuery, useNotify, Toolbar } from 'react-admin';
import { makeStyles, TextField, Select, InputLabel, FormControl, MenuItem } from "@material-ui/core";

import config from '../../../config/config';
import CustomButton from '../../shared/CustomButton';

const DetailsTab = ({ clientId }) => {

    const styles = {
        flexContainer: { width: '100%' },
        inputField: { width: '48%', margin: '1em 0' },
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 40, width: '100%',
            flexWrap: 'wrap'
        },
        toolbar: { justifyContent: 'flex-end' },
        saveBtn: { marginTop: 0 },
        label: { marginBottom: 15, marginTop: 25, display: "block" }
    };
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const notify = useNotify();

    const [state, setState] = useState({
        name: '',
        organizationNumber: '',
        accountNumber: '',
        organizationEmail: '',
        bicSwift: '',
        countryId: '',
        addressType: '',
        street: '',
        postCode: '',
        postName: '',
        id: clientId
    });

    useQuery(
        {
            type: 'GET_ONE',
            resource: 'clients',
            payload: { id: clientId }
        },
        {
            onSuccess: ({ data }) => {
                setState({ ...state, ...data });
            },
            onFailure: (error) => notify(error.message, 'warning')
        }
    );

    const [countries, setCountries] = useState([]);

    useQuery(
        {
            type: 'GET_LIST',
            resource: 'country',
            payload: {
                pagination: {
                    page: 1,
                    perPage: 100
                },
                sort: {
                    field: 'id',
                    order: 'ASC'
                },
                filter: {}
            }
        },
        {
            onSuccess: ({ data }) => {
                setCountries(data);
            }
        }
    );

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const CustomSaveBtn = props => {
        const notify = useNotify();
        const token = localStorage.getItem('access_token');

        const endpoint = `${config.apiBaseUrl}/Client/Update`;
        const request = new Request(endpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ ...state })
        });

        const save = async () => {
            console.log("Save clicked!")
            try {
                await fetch(request);
                notify('Element Updated.', 'success');
            } catch (err) {
                notify(err.message, 'warning');
            }
        }

        return <CustomButton type="save" label="Save" onClick={save} />;
    };

    const EditToolbar = () => (
        <Toolbar className={classes.toolbar}>
            <CustomSaveBtn className={classes.saveBtn} />
        </Toolbar>
    );

    return (
        <Fragment>
            <div className={classes.flexContainer}>
                <div className={classes.row}>
                    <TextField
                        label="Name"
                        name="name"
                        value={`${state.name || ''}`} //backend returns null if empty (not supported)
                        className={classes.inputField}
                        variant="standard"
                        onChange={handleChange}
                    />
                    <TextField
                        label="Organization Number"
                        name="organizationNumber"
                        type="number"
                        value={`${state.organizationNumber || ''}`}
                        className={classes.inputField}
                        variant="standard"
                        onChange={handleChange}
                    />
                    <TextField
                        label="Account Number / IBAN"
                        name="accountNumber"
                        value={`${state.accountNumber || ''}`}
                        type="number"
                        className={classes.inputField}
                        variant="standard"
                        onChange={handleChange}
                    />
                    <TextField
                        label="Organization Email"
                        name="organizationEmail"
                        value={`${state.organizationEmail || ''}`}
                        type="email"
                        className={classes.inputField}
                        variant="standard"
                        onChange={handleChange}
                    />
                    <TextField
                        label="BIC / SWIFT"
                        name="bicSwift"
                        value={`${state.bicSwift || ''}`}
                        className={classes.inputField}
                        variant="standard"
                        onChange={handleChange}
                    />
                </div>
                <label className={classes.label}>Address</label>
                <div className={classes.row}>
                    <FormControl className={classes.inputField}>
                        <InputLabel id="country-label">Country</InputLabel>
                        <Select
                            label="country-label"
                            name="countryId"
                            value={`${state.countryId || ''}`}
                            variant="standard"
                            onChange={handleChange}
                            required
                        >
                            {countries.map((item, index) => (
                                <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.inputField}>
                        <InputLabel id="address-type-label">Address Type</InputLabel>
                        <Select
                            label="address-type-label"
                            name="addressType"
                            value={`${state.addressType || ''}`}
                            variant="standard"
                            onChange={handleChange}
                        >
                            <MenuItem value={1}>Residental</MenuItem>
                            <MenuItem value={2}>Temporary</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Street"
                        name="street"
                        value={`${state.street || ''}`}
                        variant="standard"
                        className={classes.inputField}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Post code"
                        name="postCode"
                        type="number"
                        value={`${state.postCode || ''}`}
                        variant="standard"
                        className={classes.inputField}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Post Name"
                        name="postName"
                        value={`${state.postName || ''}`}
                        variant="standard"
                        className={classes.inputField}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <EditToolbar />
        </Fragment >
    );
}

export default DetailsTab;