import React, { Fragment } from "react";
import { Show, SimpleForm, TextInput, NotFound } from "react-admin";

import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import CustomTitle from '../shared/CustomTitle';
import { useMediaQuery } from '@material-ui/core';

export default function MessageTemplatesShow(props) {
    const { params: { id } } = useRouteMatch();

    const template = useSelector(state => state.admin.resources['message-templates'].data[id]);
    const name = template ? template.name : '';

    const isXsmall = useMediaQuery(theme => theme.breakpoints.down("xs"));

    const permissions = useSelector(state =>
        state.permissions
    );

    return (
        <Fragment>
            {permissions === 'admin' ? (
                <Fragment>
                    <CustomTitle title={`Message template "${name}"`} />
                    <Show {...props} actions={null} >
                        <SimpleForm toolbar={null}>
                            <TextInput
                                source="name"
                                variant="standard"
                                style={{ width: isXsmall ? '80%' : '40%' }}
                                disabled
                            />
                            <TextInput
                                source="languageName"
                                variant="standard"
                                disabled
                            />
                            <TextInput
                                source="subject"
                                label="Template subject"
                                variant="standard"
                                style={{ width: isXsmall ? '90%' : '40%', marginBottom: '40px' }}
                                disabled
                            />
                            <TextInput
                                multiline
                                source="text"
                                label="Template text"
                                variant="standard"
                                fullWidth={true}
                                disabled
                            />
                            {/* <RichTextInput
                        source="text"
                        label="Template text"
                        variant="standard"
                        fullWidth={true}
                        disabled
                    /> */}
                        </SimpleForm>
                    </Show>
                </Fragment>
            ) : <NotFound />}
        </Fragment>
    );
};
