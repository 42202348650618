import React from "react";
import { useSelector } from "react-redux";
import { Layout, Sidebar } from "react-admin";
import AppBar from "./AppBar";
import Menu from "./Menu";
import { darkTheme, lightTheme } from "./themes";
import { makeStyles } from '@material-ui/core/styles';

const CustomSidebar = props => {
  const theme = useSelector(state =>
    state.theme === "dark" ? darkTheme : lightTheme
  );

  const useStyles = makeStyles({
    sidebarContainer: {
      backgroundColor: theme.palette.sidebarColor,
      marginRight: 19,
      minHeight: 'calc(100vh - 48px)',
      zIndex: 1
    }
  });

  const classes = useStyles();

  return (
    <div className={classes.sidebarContainer}>
      <Sidebar {...props} size={200} />
    </div>
  )
};

const AppLayout = props => {
  const theme = useSelector(state =>
    state.theme === "dark" ? darkTheme : lightTheme
  );

  return (
    <Layout
      {...props}
      appBar={AppBar}
      sidebar={CustomSidebar}
      menu={Menu}
      theme={theme}
    />
  );
};

export default AppLayout;