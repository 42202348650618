import React, { Fragment } from 'react';
import { List, Datagrid, TextField, NumberField, EditButton, Filter, SearchInput, DeleteWithConfirmButton } from 'react-admin';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import CustomLabel from '../shared/CustomLabel';
import CustomButton from '../shared/CustomButton';

const useStyles = makeStyles({
    search: { display: 'flex', justifyContent: 'flex-end' }
});

const UserGroupsFilter = props => {
    const classes = useStyles();
    const history = useHistory();

    const handleClick = () => {
        history.push(`${props.basePath}/create`);
    };

    return(
        <Fragment>
            <CustomButton type="primary" fullWidth={true} label="Create new" basePath={'/user-groups'} onClick={handleClick} />
            <div className={classes.search}>
                <Filter {...props} variant="outlined">
                    <SearchInput source="userGroupsSearch" alwaysOn resettable />
                </Filter>
            </div>
        </Fragment>
    );
}

const DeleteConfirmationDialog = ({ record = {}, ...props }) => {
    const { name } = record;
    const title = `Delete user group "${name}"`;

    return (
        <DeleteWithConfirmButton confirmTitle={title} {...props} record={record} />
    );
};

const UserGroupsList = (props) => {
    const permissions = useSelector(state =>
        state.permissions
    );

    const clientId = useSelector(state =>
        state.user.clientId
    );

    return(
        <Fragment>
            <CustomLabel title={'User Groups'} />
            {permissions && (
                <List {...props} actions={null} filters={<UserGroupsFilter />} bulkActionButtons={false} filter={ permissions === 'client' ? ({ clientId }) : {} }>
                    <Datagrid rowClick="edit">
                        <TextField source="id" label="ID" />
                        <TextField source="name" />
                        <TextField source="client.name" label="Client" />
                        <NumberField source="userCount" />
                        {permissions === 'admin' && (
                            <EditButton basePath="/user-groups" style={{ float:'right'}} />
                        )}
                        {permissions === 'admin' && (
                            <DeleteConfirmationDialog style={{ float:'right'}} />
                        )}
                    </Datagrid>
                </List>
            )}
        </Fragment>
    );
};

export default UserGroupsList;