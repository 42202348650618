import config from '../config/config';
import { store } from '../redux/store';
import { setUser, setPermissions } from '../redux/auth/actions';

const authEndpoint = `${config.authBaseUrl}/TokenAuth`;

export const abpLogin = async (username, password, twoFactorVerificationCode) => {
    const params = {
        userNameOrEmailAddress: username,
        password,
        rememberClient: false,
        twoFactorRememberClientToken: null,
        singleSignIn: false,
        returnUrl: null,
        twoFactorVerificationCode
    };

    const request = new Request(`${authEndpoint}/Authenticate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    });

    try {
        const loginRequest = await fetch(request);
        const res=  await loginRequest.json();

        const { result: { accessToken, refreshToken, expireInSeconds, user, roles, requiresTwoFactorVerification, twoFactorAuthProviders, userId }} = res;
        if(requiresTwoFactorVerification) {
            return Promise.reject({ requiresTwoFactorVerification, twoFactorAuthProviders, userId });
        }

        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('refresh_token', refreshToken);
        setExpirationTime(expireInSeconds); // set expires_at cookie
        user.roles = roles;
        store.dispatch(setUser(user));

        const permissions = getPermissions(user, accessToken);
        store.dispatch(setPermissions(permissions));

        return Promise.resolve();
    } catch(err) {
        console.log(err);
        return Promise.reject();
    }
};

export const getPermissions = (user, accessToken) => {
    /* 
        admin
        client
    */
    console.log('getPermissions', user, accessToken)
    if (user.isAdmin && user.clientId) return 'client';
    const isSystemAdmin = parseJwt(accessToken);
    if (isSystemAdmin) {
        return 'admin';
    }
    // check for support role
    console.log('=============',user);
    if(user.roles && user.roles.find(r => r.roleName === "Support")) {
        return 'support';
    }

    return 'client';
};

const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = JSON.parse(decodeURIComponent(Buffer.from(base64, 'base64')));

    const isSystemAdmin = jsonPayload?.IsSystemAdmin === 'True';
    return isSystemAdmin;
};

export const didTokenExpire = async () => {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    const currentTime = new Date().getTime();

    //if(expiresAt > currentTime) return true; // access token is valid

    // request new token
    const refreshToken = localStorage.getItem('refresh_token')

    const request = new Request(`${authEndpoint}/RefreshToken?refreshToken=${refreshToken}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    try {
        const response = await fetch(request);
        const { result } = await response.json();
        
        if(!result) return Promise.reject(false); // refresh token is not valid

        const { accessToken, expireInSeconds } = result;
        localStorage.setItem('access_token', accessToken);

        setExpirationTime(expireInSeconds); // set expires_at cookie
    
        return Promise.resolve(true);
    } catch(err) {
        console.log('refresh token failed', err)
        return Promise.reject(false);
    }
};

export const abpLogout = async () => {
    const token = localStorage.getItem('access_token');

    if(!token) return;

    const request = new Request(`${authEndpoint}/LogOut`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });

    await fetch(request);
};

export const clearAuthStorage = () => {
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user_id');
};

const setExpirationTime = (expireInSeconds) => {
    let time = new Date();
    time = time.setSeconds(time.getSeconds() + expireInSeconds);
    localStorage.setItem('expires_at', time);
};