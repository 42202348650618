import React, { Fragment } from "react";
import { useSelector } from 'react-redux';
import { Create, FormTab, TabbedForm, SaveButton, Toolbar } from "react-admin";
import { darkTheme, lightTheme } from "../layout/themes";
import CustomLabel from '../shared/CustomLabel';
import CustomButton from '../shared/CustomButton';

import UserGroupsDetailsTab from './user-groups-tabs/UserGroupsDetailsTab';

import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";

const styles = {
    createToolbar: { display: 'flex', justifyContent: 'flex-end' }
};

const useStyles = makeStyles(styles);

export default function UserGroupCreate(props) {
    const labelColor = useSelector(state =>
        state.theme === "dark" ? // TODO: change this
            darkTheme.palette.iconsColor
            : lightTheme.palette.iconsColor
    );

    const permissions = useSelector(state =>
        state.permissions
    );

    const redirect = (basePath, id, data) => {
        // after save redirect to edit - users tab
        return `/user-groups/${id}/users`;
    };

    const CreateToolbar = ({ basePath, ...props }) => {
        const classes = useStyles();
        const history = useHistory();

        const onCancel = (e) => {
            e.preventDefault();
            history.push('/user-groups');
        };

        return (
            <div>
                <Toolbar {...props} className={classes.createToolbar}>
                    <CustomButton type="default" label="Cancel" onClick={onCancel} style={{ marginTop: '30px', marginRight: '20px' }}/>
                    <SaveButton basePath={basePath} />
                </Toolbar>
            </div>
        );
    };

    return (
        <Fragment>
            <CustomLabel title={'New User Group'} />
            <Create {...props}>
                <TabbedForm redirect={redirect} toolbar={<CreateToolbar />}>
                    <FormTab label="Details">
                        <UserGroupsDetailsTab labelColor={labelColor} permissions={permissions}/>
                    </FormTab>
                    <FormTab label="Users" disabled />
                </TabbedForm>
            </Create>
        </Fragment>
    );
};