import React, { Fragment } from 'react';
import { TextInput, required, NumberInput, BooleanInput } from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";

const ClientsPropertiesTab = props => {

    const styles = {
        flexContainer: { width: '100%' },
        inputField: { width: '48%' },
        row: { display: 'flex', justifyContent: 'space-between', marginBottom: 40, width: '100%', flexDirection: 'column' },
    };

    const useStyles = makeStyles(styles);
    const classes = useStyles();

    return (
        <Fragment>
            <div className={classes.flexContainer}>
                <div className={classes.row}>
                    <NumberInput
                        source="numberOfAllowedRentalOrders"
                        className={classes.inputField}
                        variant="standard"
                        fullWidth={true}
                        validate={required()}
                    />
                    <TextInput
                        source="appInformation"
                        fullWidth={true}
                        multiline
                        rows="3"
                        className={classes.inputField}
                        variant="standard"
                    />
                    <BooleanInput source="disableOrderReceipt" />
                    <NumberInput
                        source="lockTimerInSeconds"
                        className={classes.inputField}
                        variant="standard"
                        fullWidth={true}
                        validate={required()}
                    />
                    <TextInput
                        source="termsOfUse"
                        fullWidth={true}
                        multiline
                        rows="3"
                        className={classes.inputField}
                        variant="standard"
                    />
                </div>
            </div>
        </Fragment>
    );
}

export default ClientsPropertiesTab;