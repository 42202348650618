import React, { useState, Fragment } from 'react';
import { Datagrid, DateField, useQuery, Pagination, Loading } from 'react-admin';

import keyBy from 'lodash/keyBy';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    customField: { cursor: 'pointer' },
    order: { color: '#62C333' },
    door: { color: '#58bbb1'}
});


const UserAccessLogsTab = ({ record }) => {
    const classes = useStyles();
    const history = useHistory();

    const { id } = record;
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sort, setSort] = useState({ field: 'creationTime', order: 'DESC' });

    const changePerPage = (value) => {
        setPage(1);
        setPerPage(value);
    };

    const changeSort = (field) => {
        setPage(1);
        const order = sort.order === 'ASC' ? 'DESC' : 'ASC';
        setSort({ field, order});
    };

    const { data, total, loading, error } = useQuery({
        type: 'GET_LIST',
        resource: 'accessLogs',
        payload: {
            pagination: { page, perPage },
            sort,
            filter: {
                'CreatorUserId': id
            }
        }
    });

    if (loading) {
        return <Loading />
    }

    if (error) {
        return <p>ERROR: {error}</p>
    }

    const Direction = ({ record = {} }) => {
        const { direction } = record;
    
        return (
            <span>
                {direction === 0 ? 'In' : 'Out'}
            </span>
        );
    };

    const OrderField = ({ record = {} }) => {
        const { orderId } = record;

        const goToOrder = () => {
            history.push(`/orders/${orderId}/show`);
        };
    
        return (
            <span onClick={goToOrder} className={`${classes.customField} ${classes.order}`}>
                { orderId }
            </span>
        );
    };

    const DoorField = ({ record = {} }) => {
        const { id, name } = record.door;

        const goToDoor = () => {
            history.push(`/doors/${id}`);
        };
    
        return (
            <span onClick={goToDoor} className={`${classes.customField} ${classes.door}`}>
                { name }
            </span>
        );
    };

    return (
        <Fragment>
            <Datagrid
                data={keyBy(data, 'id')}
                ids={data.map(({ id }) => id)}
                currentSort={sort}
                setSort={changeSort}
            >
                <OrderField label="Order ID" sortBy="orderId" />
                <DateField source="creationTime" label="Created" showTime locales="nb-NO" />
                <DoorField label="Door" />
                <Direction source="direction" />
            </Datagrid>
            <Pagination
                page={page}
                perPage={perPage}
                setPage={setPage}
                setPerPage={changePerPage}
                total={total}
            />
        </Fragment>
    );
};

export default UserAccessLogsTab;