import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from '@material-ui/core/FormLabel';
import { darkTheme, lightTheme } from "../layout/themes";

const CustomLabel = props => {
    const theme = useSelector(state =>
        state.theme === "dark" ? darkTheme.palette : lightTheme.palette
    );

    const styles = {
        label: { margin: '28px 0 5px 1px', fontSize: '19px', fontWeight: '700', color: theme.secondary.main }
    };

    const useStyles = makeStyles(styles);
    const classes = useStyles();

    return(
        <div {...props}>
            <FormLabel component="legend" className={classes.label} style={props.style}>{ props.title }</FormLabel>
        </div>
    );
};

export default CustomLabel;