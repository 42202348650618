import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core';
import {Select, MenuItem } from '@material-ui/core';
import { EditButton, DeleteWithConfirmButton } from 'react-admin';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const ActionDropdown = ({ record, permissions = '', user, ...props }) => {
    const useStyles = makeStyles({
        btn: { display: "block" }
    });
    const classes = useStyles();

    const DeleteConfirmationDialog = () => {
        let name = record.surname ? `${record.name} ${record.surname}` : `${record.name}`;
        if(props.entity === "announcement") name = `${record.title}`
        const { entity, resource } = props;
        const dialogTitle = `Delete ${entity} "${name}"`;

        return (
            <DeleteWithConfirmButton confirmTitle={dialogTitle} basePath={props.basePath} label="Delete" record={record} resource={resource}/>
        );
    };

    return (
        <Fragment>
            <Select disableUnderline IconComponent={MoreVertIcon} >
                <MenuItem><EditButton record={record} basePath={props.basePath} className={classes.btn} /></MenuItem>
                <MenuItem><DeleteConfirmationDialog/></MenuItem>
            </Select>
        </Fragment>
    );
}

export default ActionDropdown;
