import { store } from '../redux/store';
import { abpLogin, abpLogout, didTokenExpire, clearAuthStorage } from './auth.utils';

export default {
    // called when the user attempts to log in
    login: ({ username, password, twoFactorVerificationCode, twoFactorAuthProviders }) => {
        return abpLogin(username, password, twoFactorVerificationCode, twoFactorAuthProviders);
    },
    // called when the user clicks on the logout button
    logout: () => {
        abpLogout();
        clearAuthStorage();
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: async () => {
        const token = localStorage.getItem('access_token');
        if(token) {
            const isTokenValid = await didTokenExpire();
            return isTokenValid ? Promise.resolve() : Promise.reject();
        }
        return Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        const state = store.getState();
        const role = state.permissions;
        return role ? Promise.resolve(role) : Promise.reject();
    }
};