import React, { Fragment, useState, useEffect } from 'react';
import { List, Datagrid, TextField, SearchInput, Filter, CreateButton, useNotify, DateField, DeleteWithConfirmButton, refreshView, useQuery, AutocompleteInput } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Inbox from '@material-ui/icons/Inbox';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, withStyles, Box, Typography, FormControlLabel, Switch, Button, Dialog, DialogContent, DialogContentText, DialogTitle, TextField as TextFieldM, IconButton, Tooltip } from '@material-ui/core';
import config from '../../config/config';
import LocationsExpand from "./LocationsExpand";
import CustomLabel from '../shared/CustomLabel';
import CustomButton from '../shared/CustomButton';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";

import moment from 'moment';
import momentz from 'moment-timezone';
import StatusSwitch from './StatusSwitch';
import BuildIcon from '@material-ui/icons/Build';
import SecurityIcon from '@material-ui/icons/Security';
import PublicIcon from '@material-ui/icons/Public';
import EditIcon from '@material-ui/icons/Edit';

import Icon from '@material-ui/core/Icon';
import HotelIcon from '../../assets/icons/hotel.svg'
import PodLocker from '../../assets/icons/pod_locker.svg'
import SubscriptionIcon from '../../assets/icons/subscription.svg'

const useStyles = makeStyles({
    active: { color: '#80C783' },
    inactive: { color: 'red' },
    search: { display: 'flex', justifyContent: 'flex-end', width: '100%' },
    create: { marginTop: '20px', width: '100%', display: 'flex', justifyContent: 'flex-start' },
    buttons: { float: 'right' },
    message: { textAlign: 'center', opacity: 0.5, margin: '0 1em' },
    icon: { width: '9em', height: '9em' },
    toolbar: { textAlign: 'center', marginTop: '2em' },
    statusSwitch: {
        '& .MuiTypography-body1': { fontSize: '0.875rem' }
    },
    dialogActions: { display: 'flex', justifyContent: 'space-between' },
    dialog: { '& .MuiDialog-paperWidthSm': { margin: 0, width: 500, padding: 10 } },
    textField: { '& .MuiOutlinedInput-input': { padding: '12.5px 14px' }, width: 328 },
    closeBtn: { position: 'absolute', right: 0, top: 0 },
    flexContainer: { width: '100%' },
    dialogPaper: { minWidth: '30%', borderRadius: 0 },
    bold: {
        fontWeight: 'bold'
    },
    controls: {
        display: 'flex',
        justifyContent: 'flex-start',
        margin: '30px 0 30px 0', width: '100%',
    },
    row: {
        display: 'flex',
        justifyContent: 'flex-start',
        margin: '30px 0 30px 0', width: '90%',
        flexWrap: 'wrap'
    },
    label: { fontSize: 12, color: 'rgba(0, 0, 0, 0.54)' },
    filter: { '& div:first-child': { marginLeft: 15 } },
    viewLocation: { cursor: 'pointer', color: '#80C783' }
});

const listStyles = {
    actions: {
        color: 'red !important',
    },
    bulkActionsDisplayed: {
        color: 'red'
    }
};

const Filters = ({ permissions, ...props }) => {
    const classes = useStyles();
    const history = useHistory();
    const notify = useNotify();

    const page = 1;
    const perPage = 100;
    const [total, setTotal] = useState(0);
    const [clients, setClients] = useState(null);

    useQuery(
        {
            type: 'GET_LIST',
            resource: 'clients',
            payload: {
                pagination: {
                    page,
                    perPage
                },
                sort: {
                    field: 'name',
                    order: 'ASC'
                }
            }
        },
        {
            onSuccess: ({ data, total }) => {
                setClients(data)
                setTotal(total);
            },
            onFailure: (error) => notify(error.message, 'warning')
        }
    );

    const handleClick = () => {
        history.push(`${props.basePath}/create`);
    };

    return (
        <>
            {permissions === 'admin' ? (
                <CustomButton basePath={'/locations'} type="primary" fullWidth={true} label="Create new" onClick={handleClick} />
            ) : (
                <div style={{ width: '100%' }}></div>
            )}
            <div className={classes.search}>
                <Filter {...props} variant="outlined" className={classes.filter}>
                    {permissions === 'admin' && (
                        <AutocompleteInput
                            label="Client"
                            source="clientId"
                            emptyText={<span style={{ color: '#adadad' }}>Reset</span>}
                            alwaysOn
                            choices={clients || []}
                        />
                    )}
                    <SearchInput source="locationsSearch" alwaysOn resettable />
                </Filter>
            </div>
        </>
    );
}

const EmptyList = ({ basePath, permissions }) => {
    const classes = useStyles();

    return (
        <Box textAlign="center" m={1}>
            <div className={classes.message}>
                <Inbox className={classes.icon} />
                <Typography variant="h4" paragraph>
                    No locations yet.
                </Typography>
                {permissions === 'admin' && (
                    <Typography variant="body1">
                        Do you want to add one?
                    </Typography>
                )}
            </div>
            {permissions === 'admin' && (
                <div className={classes.toolbar}>
                    <CreateButton variant="contained" basePath={basePath} />
                </div>
            )}
        </Box>
    );
};

const DeleteConfirmationDialog = ({ record = {}, ...props }) => {
    const { name } = record;
    const title = `Delete location "${name}"`;

    return (
        <DeleteWithConfirmButton confirmTitle={title} {...props} record={record} />
    );
};


const LocationList = (props) => {
    const classes = useStyles();
    const permissions = useSelector(state =>
        state.permissions
    );
    const numberOfLocations = useSelector(state =>
        state.admin.resources.locations.list.total
    );

    const [inviteOpen, setInviteOpen] = useState(false);
    const [sbscriptionOpen, setSbscriptionOpen] = useState(-1);
    const [link, setLink] = useState('');

    const DoorsSummary = ({ source, record = {} }) => {
        const { doors } = record;

        const totalDoors = doors.length;
        const activeDoors = doors.filter(d => d.status).length;

        return (
            <>
                <span>{activeDoors} active out of {totalDoors}</span>
            </>
        )
    }

    const VisibilityType = ({ source, record = {} }) => {
        const { isPublic, locationType } = record;

        return (
            <>
                <IconButton>
                    {locationType === 0 ?
                        <Icon>
                            <img src={PodLocker} style={{ maxWidth: 24 }} />
                        </Icon>
                        : <Icon>
                            <img src={HotelIcon} style={{ maxWidth: 24 }} />
                        </Icon>}
                </IconButton>
                <IconButton>
                    {isPublic ? <PublicIcon style={{ color: '#5353b5' }} /> : <SecurityIcon style={{ color: '#7b7c2f' }} />}
                </IconButton>
                <IconButton onClick={() => {

                    setSbscriptionOpen(record.id)
                }}>
                    <Icon>
                        <img src={SubscriptionIcon} style={{ maxWidth: 24 }} />
                    </Icon>
                </IconButton>
                {!isPublic ?
                    <InviteButton record={record} label={'Invite People'} /> : <></>
                }
            </>
        )
    }

    const InviteButton = ({ record }) => {

        const handleClick = () => {
            const token = localStorage.getItem('access_token');
            const endpoint = `${config.apiBaseUrl}/Location/GetInviteCode?locationId=${record.id}`;

            const request = new Request(endpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            fetch(request)
                .then(res => res.json())
                .then(response => {
                    if (response.success) {
                        setInviteOpen(true);
                        setLink(window.location.origin.toString() + '/#/invite/' + response.result);
                    }
                });
        };

        return (
            <IconButton onClick={handleClick}>
                <PersonAddIcon
                    label={'Invite people'}
                    style={{ color: 'rgba(88, 88, 88, 0.87)', cursor: 'pointer' }}
                />
            </IconButton>
        );
    };

    const InviteDialog = ({ open }) => {
        const [copyBtnDisabled, setCopyBtnDisabled] = useState(false);
        const [copyBtnText, setCopyBtnText] = useState('Copy');

        const handleClose = () => {
            setInviteOpen(false);
        };

        const copyLink = (e) => {
            setCopyBtnDisabled(true);
            setCopyBtnText('Copied!');
        };

        return (
            <Fragment>
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className={classes.dialog}>
                    <DialogTitle id="simple-dialog-title">
                        Invite people to the location
                        <IconButton onClick={handleClose} className={classes.closeBtn}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Share this link to give them access to your location
                        </DialogContentText>
                        <div className={classes.dialogActions}>
                            <TextFieldM
                                className={classes.textField}
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={link}
                            />
                            <CopyToClipboard
                                text={link}
                                onCopy={copyLink}
                            >
                                <Button variant="contained" color="primary" disabled={copyBtnDisabled}>{copyBtnText}</Button>
                            </CopyToClipboard>
                        </div>
                    </DialogContent>
                </Dialog>
            </Fragment>
        )
    };

    const SubscribeDialog = ({ open }) => {

        const handleClose = () => {
            setSbscriptionOpen(-1);
        };
        const [userPhone, setUserPhone] = useState('');
        const [subscriptionMessaage, setSubscriptionMessaage] = useState('')

        return (
            <Fragment>
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className={classes.dialog}>
                    <DialogTitle id="simple-dialog-title">
                        Subscription
                        <IconButton onClick={handleClose} className={classes.closeBtn}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Send invitation link for subscription at this location to the user phone:
                        </DialogContentText>
                        <div className={classes.dialogActions}>
                            <TextFieldM
                                className={classes.textField}
                                variant="outlined"
                                onChange={(event) => {
                                    setUserPhone(event.target.value)
                                }}
                            />
                            <Button variant="contained" color="primary" onClick={() => {
                                // send initial request to server
                                const token = localStorage.getItem('access_token');
                                const endpoint = `${config.apiBaseUrl}/Location/SendSubscriptionLink`;

                                const request = new Request(endpoint, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${token}`
                                    },
                                    body: JSON.stringify(
                                        {
                                            locationId: sbscriptionOpen,
                                            userPhone: userPhone
                                        }
                                    )

                                });

                                try{
                                fetch(request)
                                    .then(res => res.json())
                                    .then(response => {
                                        console.log(response)
                                        if (response.doorId > 0) {
                                            setSubscriptionMessaage(`Successfully subscribed to door ${response.result.doorName}`)
                                        } else {
                                            setSubscriptionMessaage(`${response.result.result}`)
                                        }
                                    }).catch(e=> {
                                        setSubscriptionMessaage(`Subscription failed: ${e.message}`)
                                    });
                                }catch(e) {
                                    console.log(e)
                                }
                            }}>Send</Button>
                        </div>
                        <DialogContentText>{subscriptionMessaage}</DialogContentText>
                    </DialogContent>
                </Dialog>
            </Fragment>
        )
    };

    const Maintenance = ({ record = {} }) => {
        const { isInMaintenance } = record;
        const [open, setOpen] = useState(false);

        const handleClose = (value) => {
            setOpen(false);
        };

        const handleClickOpen = () => {
            setOpen(true);
        };

        return (
            <Fragment>
                <Tooltip title={isInMaintenance ? "Finish Maintenance" : "Start maintenance"}>
                    <IconButton>
                        <BuildIcon style={{ color: isInMaintenance ? '#FF6E42' : 'rgba(51, 51, 52, 0.87)', cursor: 'pointer' }} onClick={handleClickOpen} />
                    </IconButton>
                </Tooltip>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth={false}
                    classes={{ paper: classes.dialogPaper }}
                    aria-labelledby="scroll-dialog-title"
                >
                    <DialogContent>
                        <MaintenanceDialog record={record} handleClose={handleClose} />
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    };

    const MaintenanceDialog = ({ record, handleClose }) => {
        const notify = useNotify();
        const dispatch = useDispatch();

        const { id, isInMaintenance, maintenanceStartTime, maintenanceEndingTime, isMaintenancePlanned, name } = record;

        const [isChecked, setIsChecked] = useState(true);
        const [startDate, handleStartDateChange] = useState(new Date());
        const [endDate, handleEndDateChange] = useState(new Date());
        const [message, setMessage] = useState(null);

        useEffect(() => {
            if (isInMaintenance || isMaintenancePlanned) {
                handleStartDateChange(maintenanceStartTime);
                handleEndDateChange(maintenanceEndingTime);
            }
            if (isInMaintenance) {
                setIsChecked(true);
            }
            if (isMaintenancePlanned) {
                setIsChecked(false);
            }
        }, [isInMaintenance, isMaintenancePlanned, maintenanceStartTime, maintenanceEndingTime]);

        const onConfirm = () => {
            if (startDate && endDate) {
                const newStartTime = new Date(startDate).getTime();
                const newEndTime = new Date(endDate).getTime();
                if (newStartTime > newEndTime) {
                    setMessage('Incorrect, check time and date.');
                    return;
                } else {
                    setMessage(null);
                }
            }
            const token = localStorage.getItem('access_token');
            const endpoint = `${config.apiBaseUrl}/Location/SetMaintenance`;
            const request = new Request(endpoint, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    locationId: id,
                    maintenanceStartTime: !isChecked ? new Date(startDate).toISOString() : null,
                    maintenanceEndingTime: new Date(endDate).toISOString()
                })
            });

            fetch(request)
                .then(res => res.json())
                .then(response => {
                    if (response.success) {
                        dispatch(refreshView());
                        notify('Location maintenance time updated', 'success');
                    } else {
                        notify(response.error.message, 'error');
                    }
                })
                .catch(error => {
                    notify(`Error: ${error.message}`, 'warning');
                });

            handleClose();
        };

        const onFinish = () => {
            const token = localStorage.getItem('access_token');
            const endpoint = `${config.apiBaseUrl}/Location/SetMaintenance`;

            const request = new Request(endpoint, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    locationId: id,
                    maintenanceStartTime: null,
                    maintenanceEndingTime: null,
                })
            });

            fetch(request)
                .then(res => res.json())
                .then(response => {
                    if (response.success) {
                        notify('Location maintenance finished', 'success');
                        dispatch(refreshView());
                    } else {
                        notify(response.error.message, 'error');
                    }
                })
                .catch(error => {
                    notify(`Error: ${error.message}`, 'warning');
                });

            handleClose();
        };

        const onCancel = () => {
            handleClose();
        };

        const handleSwitchChange = () => {
            setIsChecked(!isChecked);
            if (isChecked && isMaintenancePlanned) {
                handleStartDateChange(new Date())
            }
        };

        return (
            <Fragment>
                <div className={classes.flexContainer}>
                    <CustomLabel title={'Start maintenance for ' + name} />
                    <div className={classes.row}>
                        <FormControlLabel
                            style={{ margin: 0 }}
                            control={<Switch color="primary" />}
                            label="Start immediately"
                            classes={{ label: classes.label }}
                            labelPlacement="start"
                            checked={isChecked}
                            onChange={handleSwitchChange}
                        />
                    </div>
                    {!isChecked && <div className={classes.row}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                autoOk
                                ampm={false}
                                inputVariant="outlined"
                                value={startDate}
                                onChange={handleStartDateChange}
                                label="Maintenance Start Time"
                            />
                        </MuiPickersUtilsProvider>
                    </div>}
                    <div className={classes.row}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                autoOk
                                ampm={false}
                                inputVariant="outlined"
                                value={endDate}
                                onChange={handleEndDateChange}
                                label="Maintenance Ending Time"
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className={classes.row}>
                        <span className={classes.inactive}>
                            {message}
                        </span>
                        {isInMaintenance && (<p>Are you sure you want to finish maintenance for this location?</p>)}
                    </div>
                    <div className={classes.controls}>
                        <div>
                            <CustomButton
                                type="primary"
                                label="Save changes"
                                onClick={onConfirm}
                            />
                            {isInMaintenance || isMaintenancePlanned ? (
                                <CustomButton
                                    type="warning"
                                    label={isInMaintenance ? 'Finish maintenance' : 'Cancel maintenance'}
                                    onClick={onFinish}
                                    style={{ marginLeft: '20px' }}
                                />
                            ) : null}
                        </div>
                        <CustomButton
                            type="default"
                            label="Close"
                            onClick={onCancel}
                            style={{ marginLeft: '20px' }}
                        />
                    </div>
                </div>
            </Fragment>
        );
    };

    const StatusField = ({ record = {} }) => {
        if (permissions === 'admin') {
            return <StatusSwitch source="isActive" label="Status" record={record} />
        }
        const { isActive } = record;
        return (
            <span>
                {isActive ? 'Active' : 'Inactive'}
            </span>
        );
    };

    const MaintenanceTime = ({ record = {} }) => {
        const { isInMaintenance, isMaintenancePlanned, maintenanceEndingTime, maintenanceStartTime } = record;

        if (!isInMaintenance && !isMaintenancePlanned) {
            return <span>-</span>;
        }

        const timezone = momentz.tz.guess();
        const startTime = moment.tz(maintenanceStartTime, timezone).format('DD.MM.YYYY, HH:mm:ss');
        const endTime = moment.tz(maintenanceEndingTime, timezone).format('DD.MM.YYYY, HH:mm:ss');

        return (
            <span>{isMaintenancePlanned && (<span className={classes.bold}>Planned:</span>)} {startTime && 'from ' + startTime} {"until " + endTime}</span>
        );
    };

    const ViewLocationLink = ({ record = {} }) => {
        const history = useHistory();
        const goToLocation = () => {
            history.push(`/locations/${record.id}`)
        }

        return (
            <Tooltip title={"Edit location"}>
                <IconButton>
                    <EditIcon onClick={goToLocation} />
                </IconButton>
            </Tooltip>
        );
    };

    return (
        <Fragment>
            {(numberOfLocations > 0 || permissions === 'admin') && (
                <CustomLabel title={'Locations'} />
            )}
            <List sort={{ field: 'Id', order: 'ASC' }} actions={null} filter={{}} filters={<Filters permissions={permissions} />} {...props} bulkActionButtons={false} empty={<EmptyList permissions={permissions} />}>
                <Datagrid
                    expand={(permissions === 'admin')
                        ? <LocationsExpand permissions={permissions} />
                        : null
                    }>
                    <TextField source="id" />
                    <VisibilityType source="isPublic" label="Visibility / Invite users" />
                    <TextField source="name" />
                    <TextField source="municipality" label="City" />
                    <StatusField source="isActive" label={'Status'} />
                    <DoorsSummary label={'Summary'} />
                    <MaintenanceTime source="maintenanceEndingTime" label="Note" />

                    {permissions === 'admin' &&
                        <ViewLocationLink />
                    }
                    {permissions === 'admin' &&
                        <Maintenance />
                    }
                    {permissions === 'admin' &&
                        <DeleteConfirmationDialog />
                    }
                </Datagrid>
            </List>
            <InviteDialog open={inviteOpen} />
            <SubscribeDialog open={sbscriptionOpen > 0} />
        </Fragment >
    )
};

export default withStyles(listStyles)(LocationList);