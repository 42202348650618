import React, { useState, useEffect, useMemo } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

import { Loading } from 'react-admin';

import { useDispatch } from 'react-redux';
import { updateLocation } from '../../../redux/locationMap/actions';

const mapContainerStyle = {
    height: "450px",
    width: '100%',
    marginTop: '5%'
};

export default function LocationMap({ locationDetails, permissions }) {
    const dispatch = useDispatch();

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyC2w0NtRwp4FzSxTHnBHHfz7eGPgyNRjvI"
    });

    const { latitude, longitude } = locationDetails ? locationDetails : { latitude: null, longitude: null };
    const [position, setPosition] = useState({ lat: latitude, lng: longitude });

    // do not add dependency 'position' because in that case new marker will always be the center of the map
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const defaultCenter = useMemo(() => position.lat ? position : { lat: 63.4305, lng: 10.3951 }, []);

    useEffect(()=>{
        return(() => {
            dispatch(updateLocation({
                latitude: null,
                longitude: null
            }));
        });
    }, [dispatch]);

    const changeLocation = (e) => {
        if(permissions !== 'admin') return;

        const latitude = e.latLng.lat();
        const longitude = e.latLng.lng();
        dispatch(updateLocation({ latitude, longitude }));

        setPosition({
            lat: latitude,
            lng: longitude
        });
    };

    const renderMap = () => {
        return (
            <GoogleMap
                zoom={14}
                center={defaultCenter}
                onClick={changeLocation}
                mapContainerStyle={mapContainerStyle}
            >
                <Marker position={position.lat ? position : null} />
            </GoogleMap>
        ); 
    };

    return isLoaded ? renderMap() : <Loading loadingPrimary="ra.page.loading" />;
};