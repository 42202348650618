import React, { useState, useEffect } from 'react';
import { FormControlLabel, makeStyles, Switch, Snackbar } from '@material-ui/core';
import { useNotify } from 'react-admin';
import config from '../../config/config';
import StatusChangeDialog from './StatusChangeDialog';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
    statusSwitch: {
        '& .MuiTypography-body1': { fontSize: '0.875rem' }
    },
    disabledSwitch: {
        '& .MuiSwitch-root': {
            opacity: 0.2
        }
    }
});

const StatusSwitch = ({ source, record, disabled }) => {
    const classes = useStyles();
    const notify = useNotify();
    const { id, isActive } = record;
    const [active, setActive] = useState(isActive);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [open, setOpen] = useState(false);


    useEffect(() => {
        if (isActive !== active) {
            setActive(isActive);
        }
    }, [isActive])

    const handleClose = () => {
        setOpen(false);
    }
    const handleStatusChange = () => {
        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Location/Activate`;
        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ id, isActive: !active })
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                    setActive(!active);
                    notify('Status successfully changed', 'success');
                } else {
                    notify(response.error.message, 'error');
                }
                setDialogOpen(false);
            });
    };

    return (
        <>
            <FormControlLabel
                className={classes.statusSwitch}
                control={
                    <Switch
                        checked={active}
                        onChange={(e, val) => setDialogOpen(true)
                        }
                        color="primary"
                    />
                }
                label={active ? 'Active' : 'Inactive'}
            />
            <StatusChangeDialog
                open={dialogOpen}
                title='Are you sure you want to change location status?'
                setOpen={(val) => setDialogOpen(val)}
                onChangeStatus={handleStatusChange}
            />
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} >
                <MuiAlert onClose={handleClose} severity="error">
                    Status didn't change. Pricing is missing.
                </MuiAlert>
            </Snackbar>
        </>
    )
};

export default StatusSwitch;