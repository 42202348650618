import React, { Fragment } from 'react';
import { Edit, TabbedForm, FormTab, Toolbar, SaveButton } from 'react-admin';
import CustomTitle from '../shared/CustomTitle';
import CustomButton from '../shared/CustomButton';

import UserGroupsEditDetailsTab from './user-groups-edit-tabs/UserGroupsEditDetailsTab';
import UserGroupsEditUsersTab from './user-groups-edit-tabs/edit-users-tab/UserGroupsEditUsersTab';

import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch} from 'react-router-dom';

import { makeStyles } from "@material-ui/core/styles";

const styles = {
    editToolbar: { display: 'flex', justifyContent: 'flex-end', '& button': {
            margin: '0 0 0 20px !important'
        }
    }
};

const useStyles = makeStyles(styles);

const UserGroupsEdit = (props) => {
    const classes = useStyles();

    const permissions = useSelector(state =>
        state.permissions
    );

    const history = useHistory();
    const { pathname } = history.location;

    const { params: { id } } = useRouteMatch();

    const group = useSelector(state => state.admin.resources['user-groups'].data[id]);
    const name = group ? group.name : '';

    const onCancel = (e) => {
        e.preventDefault();
        history.push('/user-groups');
    };

    const EditToolbar = props => (
        <Fragment>
            {(!pathname.includes('/users') && permissions === 'admin') && (
                <Toolbar {...props} className={classes.editToolbar}>
                    <CustomButton type="default" label="Cancel" onClick={onCancel} />
                    <SaveButton />
                </Toolbar>
            )}
        </Fragment>
    );

    return (
        <Fragment>
            <CustomTitle title={`User Group "${name}"`}/>
            <Edit {...props}>
                <TabbedForm toolbar={<EditToolbar />}>
                    <FormTab label="Details">
                        <UserGroupsEditDetailsTab permissions={permissions} />
                    </FormTab>
                    <FormTab label="Users" path="users">
                        <UserGroupsEditUsersTab />
                    </FormTab>
                </TabbedForm>
            </Edit>
        </Fragment>
    );
};

export default UserGroupsEdit;