import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { darkTheme, lightTheme } from "../layout/themes";

const CustomTitle = (props) => {
    const theme = useSelector(state =>
        state.theme === "dark" ? darkTheme.palette : lightTheme.palette
    );

    const useStyles = makeStyles({
        label: { margin: '20px 0 5px 0', fontSize: '19px', fontWeight: '700', color: theme.secondary.main }
    });

    const classes = useStyles();

    return (
        <span className={classes.label}>
            {props.title}
        </span>
    );
};

export default CustomTitle;