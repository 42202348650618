import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { required, TextInput, SimpleForm, useNotify } from 'react-admin';
import config from '../../config/config';

const useStyles = makeStyles({
    dialogActions: { display: 'flex', justifyContent: 'flex-end', marginTop: 20 },
    dialog: { '& .MuiDialog-paperWidthSm': { margin: 0, width: 500, padding: 10 } },
    closeBtn: { position: 'absolute', right: 0, top: 0 },
    title: { marginTop: 20 },
    button: { minWidth: 100, height: 45 }
});

const SendMessageDialog = ({ open, setOpen, title, primaryBtnLabel = 'Send', userId }) => {
    const classes = useStyles();
    const [textValue, setTextValue] = useState('');
    const notify = useNotify();

    const handleClose = () => {
        setOpen(false);
    };

    const handleStatusChange = () => {
        // Send to server
        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/USer/SendSMSMessage`;
        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                userId: userId,
                message: textValue,
            })
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                    notify('Message sent to user', 'success');
                } else {
                    notify(response.error.message, 'error');
                }
                setOpen(false);
            })
            .catch(error => {
                notify(`Error: ${error.message}`, 'warning');
                setOpen(false);
            });
    };

    const handleTextChange = (event) => {
        setTextValue(event.target.value);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className={classes.dialog}>
            <DialogTitle id="simple-dialog-title" className={classes.title}>
                {title}
                <IconButton onClick={handleClose} className={classes.closeBtn}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <SimpleForm toolbar={<div className={classes.dialogActions}>
                    <Button variant="outlined" color="secondary" className={classes.button} onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" color="primary" className={classes.button} style={{ marginLeft: 15, width: 130 }} onClick={handleStatusChange}>{primaryBtnLabel}</Button>
                </div>}>
                    <TextInput
                        label="Message"
                        source="description"
                        fullWidth={true}
                        rows="4"
                        multiline
                        variant="outlined"
                        className={classes.inputField}
                        onChange={handleTextChange}
                        validate={required()}
                    />
                </SimpleForm>
            </DialogContent>
        </Dialog>
    )
};

export default SendMessageDialog;