import React from 'react';
import { Resource } from 'react-admin';
import { useSelector } from 'react-redux';

import clients from "../components/clients";
import diagnostic from "../components/diagnostic";
import locations from '../components/locations';
import messageTemplates from '../components/message-templates';
import notifications from "../components/announcements";
import orders from '../components/orders';
import SupportOrderList from '../components/orders/SupportOrderList';
import users from '../components/users';
import userGroups from '../components/user-groups';
import doors from "../components/locations/locations-tabs/doors-edit";

const adminResources = [
    <Resource key={"accessLogs"} name="accessLogs" />,
    <Resource key={"paymentLogs"} name="paymentLogs" />,
    <Resource key={"announcements"} name="announcements" {...notifications} />,
    <Resource key={"clients"} name="clients" {...clients} />,
    <Resource key={"diagnostic"} name="diagnostic" {...diagnostic} />,
    <Resource key={"getDiagnosticsPerDoor"} name="getDiagnosticsPerDoor" />,
    <Resource key={"getGroupUsers"} name="getGroupUsers" />,
    <Resource key={"messageList"} name="messageList" />,
    <Resource key={"message-templates"} name="message-templates" {...messageTemplates} />,
    <Resource key={"orders"} name="orders" {...orders} />,
    <Resource key={"support"} name="support" {...SupportOrderList} />,
    <Resource key={"paymentOptions"} name="paymentOptions" />,
    <Resource key={"user-groups"} name="user-groups" {...userGroups} />,
    <Resource key={"doors"} name="doors" {...doors} />,
];

const clientResources = [
    <Resource key={"locations"} name="locations"  {...locations} />,
];

const sharedResources = [
    <Resource key={"locations"} name="locations" {...locations} />,
    <Resource key={"users"} name="users" {...users} />,
];

const useGetResources = () => {
    const permissions = useSelector(state =>
        state.permissions
    );

    const isBikelyCustomer = useSelector(state =>
        state.bikelyCustomer
    );
    
    const resources = [
        (permissions === 'admin' ? adminResources : []),
        (permissions === 'client' ? clientResources : []),
        ...sharedResources
    ];

    return resources;
};

export default useGetResources;
