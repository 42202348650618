import { Button, CircularProgress, makeStyles, Snackbar } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../components/shared/CustomButton.jsx';
import CustomLabel from '../components/shared/CustomLabel.jsx';
import config from '../config/config';
import { changeTheme } from '../redux/theme/actions';
import ChangePasswordDialog from './components/ChangePasswordDialog.jsx';
import Remove2FADialog from './components/Remove2FADialog.jsx';
import TwoFactorAuthDialog from './components/TwoFactorAuthDialog.jsx';
import { darkTheme, lightTheme } from "../components/layout/themes";

const useStyles = makeStyles({
    button: { margin: '1em' },
    statusSwitch: {
        '& .MuiTypography-body1': { fontSize: '0.875rem' }
    },
    twoFactorContainer: { marginTop: 50 },
    twoFactorContent: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 30
    },
    radialButtons: {
        padding: 12,
        '& .MuiTypography-body1': { fontSize: '0.875rem' }
    },
    loaderWrapper: { width: '100%', height: '100%', display: 'flex' },
    loader: { height: 100, margin: 'auto', width: 100 },
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'fixed',
        width: '100%',
        bottom: 0,
        right: 0,
        background: '#F5F5F5',
        minHeight: 100,
        paddingRight: 50
    },
    dialog: {
        '& .MuiPaper-root': { height: 450, }
    }
});

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Settings = () => {
    const classes = useStyles();
    const theme = useSelector(state => state.theme);
    const token = localStorage.getItem('access_token');
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(true);
    const [twoFactorAuthEnabled, setTwoFactorAuthEnabled] = useState(false);
    const [twoFactorData, setTwoFactorData] = useState({});
    const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
    const [twoFactorDialogOpen, setTwoFactorDialogOpen] = useState(false);
    const [remove2FADialogOpen, setRemove2FADialogOpen] = useState(false);
    const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState(false);
    const [snackbarErrorOpen, setSnackbarErrorOpen] = useState(false);
    const [snackbarSuccessMessage, setSnackbarSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const palette = useSelector(state =>
        state.theme === "dark" ? darkTheme.palette : lightTheme.palette
    );

    const fetchUserSettings = () => {
        const endpoint = `${config.apiBaseUrl}/Profile/GetUserSettings`;
        const request = new Request(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        fetch(request)
            .then(res => res.json())
            .then(response => {
                const { twoFactorInput } = response.result;
                setTwoFactorAuthEnabled(twoFactorInput.isTwoFactorEnabled);
                setTwoFactorData(twoFactorInput);
                setLoader(false);
            })
    };

    const on2FAEnable = () => {
        setTwoFactorDialogOpen(false);
        setTwoFactorAuthEnabled(true);
        setSnackbarSuccessMessage(
            twoFactorAuthEnabled ?
                'Saved successfully'
                :
                'Two-Factor authentication successfully enabled.'
        );
        setSnackbarSuccessOpen(true);
        fetchUserSettings();
    };

    const on2FARemove = () => {
        setRemove2FADialogOpen(false);
        setTwoFactorAuthEnabled(false);
        setSnackbarSuccessMessage('Two-Factor authentication successfully removed.');
        setSnackbarSuccessOpen(true);
        fetchUserSettings();
    };

    useEffect(() => {
        fetchUserSettings();
    }, [])

    return (
        <>
            <CustomLabel title='Settings' />
            {!loader ?
                <>
                    <div style={{ padding: 16, paddingBottom: 150 }}>
                        <div style={{ marginBottom: 20 }}>
                            <h4 style={{ color: palette.secondary.main }}>Theme</h4>
                            <Button
                                variant="contained"
                                className={classes.button}
                                color={theme === 'light' ? 'primary' : 'default'}
                                onClick={() => dispatch(changeTheme('light'))}
                            >
                                {'LIGHT'}
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.button}
                                color={theme === 'dark' ? 'primary' : 'default'}
                                onClick={() => dispatch(changeTheme('dark'))}
                            >
                                {'DARK'}
                            </Button>
                        </div>
                        <div className={classes.twoFactorContainer}>
                            <CustomLabel title='Password and authentication' />
                            <div className={classes.twoFactorContent}>
                                <CustomButton type="primary" label="Change password" onClick={() => setChangePasswordDialogOpen(true)} style={{ maxWidth: 170, marginBottom: 30 }} />
                                {twoFactorAuthEnabled ?
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: '#17b978' }}>
                                            <LockIcon style={{ width: 18, marginTop: 5, marginRight: 3 }} />
                                            <h4 style={{ marginBottom: 15, color: '#17b978' }}>Two-factor authentication enabled</h4>
                                        </div>
                                        <p style={{ marginTop: 0, color: palette.secondary.main }}>
                                            Two-Factor authentication (2FA for short) is a good way to add an extra
                                            layer of security<br /> to your account to make sure that
                                            only you have the ability to log in.
                                        </p>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <CustomButton
                                                type="primary"
                                                label="Edit 2FA"
                                                onClick={() => setTwoFactorDialogOpen(true)}
                                                style={{ width: 150, marginBottom: 20, marginTop: 15 }}
                                            />
                                            <CustomButton
                                                type="warning"
                                                label="Remove 2FA"
                                                onClick={() => setRemove2FADialogOpen(true)}
                                                style={{ width: 150, marginBottom: 20, marginTop: 15, marginLeft: 25 }}
                                            />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <h4 style={{ marginBottom: 15, color: palette.secondary.main }}>Two-factor authentication</h4>
                                        <p style={{ marginTop: 0, color: palette.secondary.main }}>Protect your account with an extra layer of security.<br />
                                        Once configured, you'll be required to enter an authentication code in order to login.
                                        </p>
                                        <CustomButton
                                            type="primary"
                                            label="Enable two-factor auth"
                                            onClick={() => setTwoFactorDialogOpen(true)}
                                            style={{ maxWidth: 200, marginBottom: 20, marginTop: 15 }}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <ChangePasswordDialog
                        isOpen={changePasswordDialogOpen}
                        closeDialog={() => setChangePasswordDialogOpen(false)}
                        onSave={() => {
                            setChangePasswordDialogOpen(false);
                            setSnackbarSuccessMessage('Password changed successfully')
                            setSnackbarSuccessOpen(true);
                        }}
                        onError={message => {
                            setErrorMessage(message);
                            setSnackbarErrorOpen(true);
                        }}
                    />
                    <TwoFactorAuthDialog
                        isOpen={twoFactorDialogOpen}
                        is2FAActive={twoFactorAuthEnabled}
                        closeDialog={() => setTwoFactorDialogOpen(false)}
                        on2FAEnable={on2FAEnable}
                        data={twoFactorData}
                        onError={message => {
                            setErrorMessage(message);
                            setSnackbarErrorOpen(true);
                        }}
                    />
                    <Remove2FADialog
                        isOpen={remove2FADialogOpen}
                        closeDialog={() => setRemove2FADialogOpen(false)}
                        on2FARemove={on2FARemove}
                        onError={message => {
                            setErrorMessage(message);
                            setSnackbarErrorOpen(true);
                        }}
                    />
                    <Snackbar
                        open={snackbarSuccessOpen}
                        autoHideDuration={4000}
                        onClose={() => setSnackbarSuccessOpen(false)}
                    >
                        <Alert onClose={() => setSnackbarSuccessOpen(false)} severity="success">
                            {snackbarSuccessMessage}
                        </Alert>
                    </Snackbar>
                    {snackbarErrorOpen &&
                        <Snackbar
                            open={snackbarErrorOpen}
                            autoHideDuration={4000}
                            onClose={() => {
                                setSnackbarErrorOpen(false);
                                setErrorMessage('');
                            }}
                        >
                            <Alert onClose={() => setSnackbarErrorOpen(false)} severity="error">
                                {errorMessage}
                            </Alert>
                        </Snackbar>
                    }
                </>
                :
                <div className={classes.loaderWrapper}>
                    <CircularProgress className={classes.loader} />
                </div>
            }
        </>
    );
};

export default Settings;
