import { Avatar, makeStyles } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { AppBar, MenuItemLink, UserMenu } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DefaultAvatar from '../../assets/images/blank-avatar.png';
import LogoImg from '../../assets/images/logo.svg';
import { clearAuthStorage, getPermissions } from '../../auth/auth.utils';
import config from '../../config/config';
import { capitalizeFirstLetter } from '../../dataProvider/helpers';
import { setPermissions, setUser } from '../../redux/auth/actions';


const ConfigurationMenu = forwardRef((_, ref) => {
    return (
        <div style={{ minWidth: 200 }}>
            <MenuItemLink
                ref={ref}
                to="/settings"
                primaryText={'Settings'}
                leftIcon={<SettingsIcon />}
            />
        </div>
    );
});

const CustomIcon = () => {
    const useStyles = makeStyles({
        profile: {
            display: 'flex',
            alignItems: 'center'
        },
        username: {
            fontSize: '16px',
        },
        avatar: {
            height: 36,
            width: 36,
            marginLeft: '20px'
        }
    });

    const [name, setName] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector(state =>
        state.user
    );

    const useWindowUnloadEffect = (handler, callOnCleanup) => {
        const cb = useRef();
        
        cb.current = handler;
        
        useEffect(() => {
            const handler = () => cb.current();
            
            window.addEventListener('beforeunload', handler);
            
            return () => {
                if(callOnCleanup) handler();
            
                window.removeEventListener('beforeunload', handler);
                dispatch(setUser({}));
                dispatch(setPermissions(''));
                localStorage.removeItem('user_id');
            }
        }, [cb, callOnCleanup]);
    };
    
    const setUserData = useCallback((user) => {
        dispatch(setUser(user));
        let { name, surname } = user;
        name = capitalizeFirstLetter(name);
        surname = capitalizeFirstLetter(surname);
        setName(`${name} ${surname}`);
        
        const token = localStorage.getItem('access_token');
        const permissions = getPermissions(user, token);
        dispatch(setPermissions(permissions));
    }, [dispatch]);

    useEffect(()=> {
        // after login
        if(user && user.id) {
            let { name, surname } = user;
            name = capitalizeFirstLetter(name);
            surname = capitalizeFirstLetter(surname);
            return setName(`${name} ${surname}`);
        }

        // on page reload
        const id = localStorage.getItem('user_id');
        localStorage.removeItem('user_id');

        if(!id || id === 'undefined') {
            return history.push('/login');
        }

        const endpoint = `${config.apiBaseUrl}/User/GetUserById?id=${id}`;

        const token = localStorage.getItem('access_token');
        const request = new Request(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        fetch(request)
        .then(res => res.json())
        .then(response => {
            if (response && response.result) {
                setUserData(response.result);
            }

            if (response && response.error) {
                if (response.error.message === '[Current user did not login to the application]') {
                    clearAuthStorage();
                    return history.push('/login');
                }
            }
        })
        .catch(error => {
            console.log(error);
        });
    }, [setUserData, user, history]);

    useWindowUnloadEffect(() => localStorage.setItem('user_id', user.id), true);

    const classes = useStyles();
    return (
        <div className={classes.profile}>
            <p className={classes.username}>{ name }</p>
            <Avatar
                className={classes.avatar}
                src={DefaultAvatar}
            />
        </div>
    );
};

const CustomUserMenu = props => (
    <UserMenu {...props} icon={<CustomIcon />}>
        <ConfigurationMenu />
    </UserMenu>
);

const Logo = () => (
    <div>
        <img src={LogoImg} alt="logo" style={{maxHeight: 120, maxWidth: 140, paddingTop: 3 }} />
    </div>
);

const CustomAppBar = props => {
    const useStyles = makeStyles({
        title: {
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        spacer: {
            flex: 1,
        },
        profileContainer: {
            backgroundColor: '#4B6471',
            width: 230,
            height: 48,
            position: 'absolute',
            right: 0
        },
        removePadding: { paddingRight: '0 !important' },
        notransition: {
            '-webkit-transition': 'none !important',
            '-moz-transition': 'none !important',
            '-o-transition': 'none !important',
            transition: 'none !important',
            transform: 'none !important',
            visibility: 'visible !important',
        }
    });

    const classes = useStyles();
    return (
        <AppBar {...props} userMenu={<CustomUserMenu />} className={`${classes.removePadding} ${classes.notransition}`}>
            <Logo />
            <span className={classes.spacer} />
        </AppBar>
    );
};

export default CustomAppBar;
