import MessageTemplatesList from './MessageTemplatesList';
import MessageTemplatesEdit from './MessageTemplatesEdit';
import MessageTemplatesCreate from './MessageTemplatesCreate';
import MessageTemplatesShow from './MessageTemplatesShow';

export default {
    list: MessageTemplatesList,
    create: MessageTemplatesCreate,
    edit: MessageTemplatesEdit,
    show: MessageTemplatesShow
};