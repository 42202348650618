import React, { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, Tab, Paper, Tabs, Typography, Box } from '@material-ui/core';
import { darkTheme, lightTheme } from "../layout/themes";
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles({
    root: { flexGrow: 1 },
    hidden: { visibility: 'hidden' },
    customRange: {
        position: 'absolute',
        right: 0,
        top: '25%',
        textTransform: 'uppercase',
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.875rem',
        fontFamily: 'Roboto,-apple-system,Segoe UI,Arial,sans-serif',
        fontWeight: 500,
        lineHeight: 1.75,
        cursor: 'pointer',
        display: 'flex'
    }
});

const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomTabs = props => {
    const { style, tabsData } = props;
    const classes = useStyles();

    const [value, setValue] = useState(props.defaultTab || 0);

    const labelColor = useSelector(state =>
        state.theme === "dark" ?
            darkTheme.palette.primary.main
            : lightTheme.palette.primary.main
    );

    const handleChange = (event, newValue) => {
        setValue(newValue);

        if (props.customRange) {
            props.datePickerOpen(false);
            getActiveTab(newValue);
        }
    };

    let getActiveTab = null;
    if (props.customRange) {
        getActiveTab = (value) => {
            props.activeTab(value)
        };
    }

    /* Used for custom range on Dashboard only */
    const handlePickerClick = () => {
        setValue(false);
        getActiveTab(4);
        props.datePickerOpen(!props.pickerOpen);
    };

    return (
        <Fragment>
            <Paper className={classes.root} style={style}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    activeTab={getActiveTab}
                >
                    {tabsData.map((tab, index) => (
                        <Tab label={tab.label} {...a11yProps(index)} disabled={tab.disabled} key={index} style={props.tabStyle} className={tab.hidden ? classes.hidden : null} />
                    ))}


                    {/* Used for custom range on Dashboard only */}
                    {props.customRange &&
                        <div className={classes.customRange} onClick={handlePickerClick} style={{ color: value === false ? labelColor : null }}>Custom range {props.pickerOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}</div>
                    }

                </Tabs>
                {!props.headerOnly && tabsData.map((tab, index) => (
                    <TabPanel value={value} index={index} key={index}>
                        {tab.component}
                    </TabPanel>
                ))}
            </Paper>
        </Fragment>
    );
}

export default CustomTabs;