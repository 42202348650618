
import { UPDATE_WAS_ACTIVE } from './actions';

export default (previousState = false, { type, payload }) => {
    if (type === UPDATE_WAS_ACTIVE) {
        return payload;
    }
    
    return previousState;
};




