import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppBar, Toolbar, makeStyles, Button, Link, TextField, Snackbar } from '@material-ui/core';
import config from '../../config/config';
import LogoImg from '../../assets/images/logo.svg';
import AppleStore from '../../assets/images/apple-app-store.svg';
import GoogleStore from '../../assets/images/google-play-store.svg';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
    appBar: { backgroundColor: '#008a38' },
    toolbar: { display: 'flex', justifyContent: 'center', height: 50, minHeight: 'unset' },
    container: { position: 'absolute', top: 50, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' },
    content: { display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: 'center', padding: '60px 30px', background: '#eee', justifyContent: 'space-between', fontSize: '1.1rem', color: '#434343', width: 300, '& .react-tel-input': { width: 'auto' }, boxShadow: '0px 0px 5px 2px rgb(0 0 0 / 20%)' },
    button: { backgroundColor: '#72C948', boxShadow: 'none', '&:hover': { backgroundColor: '#72C948', opacity: .9, boxShadow: 'none' } },
    terms: { fontSize: '.8rem', color: '#7b7b7b' },
    tabs: { display: 'flex', justifyContent: 'space-between', width: 235 },
    tab: { textTransform: 'uppercase', fontSize: '.9rem', fontWeight: 600, opacity: .7 },
    verificationField: {
        "& label.Mui-focused": {
            // color: "salmon"
        },
        "& .MuiInput-underline:after": {
            // borderBottomColor: "green"
        },
        "& .MuiOutlinedInput-root": {
            height: 35,
            background: '#fff',
            "& fieldset": {
                // borderColor: "salmon"
            },
            "&:hover fieldset": {
                // borderColor: "yellow"
            },
            "&.Mui-focused fieldset": {
                borderColor: "#72C948"
            }
        }
    },
    checkIcon: { width: 50, color: '#72C948', height: 'auto', marginBottom: 10 },
    storeButton: { width: 150 },
    svgContainer: { display: 'flex', justifyContent: 'space-between', flexDirection: 'column', textAlign: 'center', alignItems: 'center', height: '30%' }
});

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const InvitePage = () => {
    const pathname = useSelector(state =>
        state.router.location.pathname
    );
    const classes = useStyles();

    const index = pathname.indexOf('/', 2);
    const code = pathname.substring(index + 1);
    const termsUrl = window.location.origin.toString() + '/#/terms&conditions';

    const [screen, setScreen] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [phone, setPhone] = useState();
    const [verificationCode, setVerificationCode] = useState('');

    const [firstSnackbarOpen, setFirstSnackbarOpen] = useState(false);
    const [firstSnackbarMessage, setFirstSnackbarMessage] = useState('');
    const [secondSnackbarOpen, setSecondSnackbarOpen] = useState(false);
    const [secondSnackbarMessage, setSecondSnackbarMessage] = useState('');

    const sendSmsCode = () => {
        const phoneNum = '00' + phone;
        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Account/SendUserInvitationSmsCode?invitationCode=${code}&phoneNumber=${phoneNum}`;
        const request = new Request(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                    setActiveTab(1);
                } else {
                    setFirstSnackbarMessage(response.error.message);
                    setFirstSnackbarOpen(true);
                }
            });
    };

    const checkVerification = () => {
        const phoneNum = '00' + phone;
        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Account/VerifyUserInvitationSmsCode?invitationCode=${code}&phoneNumber=${phoneNum}&activationCode=${verificationCode}`;
        const request = new Request(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                    setScreen(2);
                } else {
                    console.error(response.error.message);
                    setSecondSnackbarMessage(response.error.message);
                    setSecondSnackbarOpen(true);
                }
            });
    };

    return (
        <Fragment>
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <img src={LogoImg} alt="logo" style={{ maxWidth: 150 }} />
                </Toolbar>
            </AppBar>
            <div className={classes.container}>
                {screen === 0 && (
                    <div className={classes.content} style={{ height: 150 }}>
                        <div>Jippi! Arbeidsplassen din har valgt sykkelskap fra Bikely! 🚲</div>
                        <Button className={classes.button} variant="contained" color="primary" onClick={() => setScreen(1)}>Få tilgang</Button>
                    </div>
                )}
                {screen === 1 && (
                    <div className={classes.content} style={{ height: 400 }}>
                        <div>
                            Dine valg beveger verden i riktig retning 💚
                        </div>
                        <div className={classes.tabs} style={{ width: '265px' }}>
                            <div className={classes.tab} style={!activeTab ? { textDecoration: 'underline', opacity: 1 } : null}>Mobilnummer</div>
                            <div className={classes.tab} style={activeTab ? { textDecoration: 'underline', opacity: 1 } : null}>Verifiseringskode</div>
                        </div>
                        {!activeTab ? (
                            <Fragment>
                                <div>Tast inn ditt telefonnummer for å motta en kode på sms.</div>
                                <PhoneInput
                                    country={'no'}
                                    value={phone}
                                    onChange={num => setPhone(num)}
                                />
                                <Button className={classes.button} variant="contained" color="primary" onClick={sendSmsCode}>Next</Button>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div>Tast inn ditt telefonnummer for å motta en kode på sms.</div>
                                <TextField className={classes.verificationField} variant="outlined" placeholder="e.g. 123456" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} />
                                <Button className={classes.button} variant="contained" color="primary" disabled={!verificationCode} onClick={checkVerification}>Next</Button>
                            </Fragment>
                        )}

                        <div className={classes.terms}>
                            By joining you accept Bikely's <br />
                            <Link href={termsUrl} target="_blank">Terms and Conditions</Link>.
                        </div>
                        <Snackbar open={firstSnackbarOpen} autoHideDuration={4000} onClose={() => setFirstSnackbarOpen(false)}>
                            <Alert onClose={() => setFirstSnackbarOpen(false)} severity="error">
                                {firstSnackbarMessage}
                            </Alert>
                        </Snackbar>
                        <Snackbar open={secondSnackbarOpen} autoHideDuration={4000} onClose={() => setSecondSnackbarOpen(false)}>
                            <Alert onClose={() => setSecondSnackbarOpen(false)} severity="error">
                                {secondSnackbarMessage}
                            </Alert>
                        </Snackbar>
                    </div>
                )}
                {screen === 2 && (
                    <div className={classes.content} style={{ height: 450 }}>
                        <div className={classes.svgContainer}>
                            <div style={{ marginBottom: 20 }}>Hurra! Du har fått tilgang til private sykkelskap fra Bikely. Takk for at du sykler! 💚🚲</div>
                            <CheckCircleIcon className={classes.checkIcon} />
                        </div>
                        <div>Kom i gang med å last ned appen her 👇🏻</div>
                        <div style={{ display: 'flex', flexDirection: 'column', height: 110, justifyContent: 'space-between' }}>
                            <Link href="https://apps.apple.com/us/app/safebikely/id1403968600">
                                <img src={AppleStore} alt="apple-app-store" className={classes.storeButton} />
                            </Link>
                            <Link href="https://play.google.com/store/apps/details?id=no.source.safebikely">
                                <img src={GoogleStore} alt="google-play-store" className={classes.storeButton} />
                            </Link>
                        </div>
                        <div>Eller søk etter Bikely i App Store / Google Play</div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default InvitePage;