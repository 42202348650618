import React, { Fragment } from 'react';
import { TextInput, SelectInput } from "react-admin";
import { useSelector } from 'react-redux';
import OrderInvoiceLocation from './OrderInvoiceLocation';
import { FormLabel, makeStyles } from "@material-ui/core";
import { darkTheme, lightTheme } from "../../layout/themes";

const OrderInvoiceTab = ({ labelColor, ...props }) => {
    const theme = useSelector(state =>
        state.theme === "dark" ? darkTheme.palette : lightTheme.palette
    );

    const useStyles = makeStyles({
        formLabel: { marginTop: 20, marginBottom: 10, fontWeight: 700, color: theme.secondary.main },
        flexContainer: { width: '100%' },
        select: { maxWidth: '29%' },
        row: { display: 'flex', justifyContent: 'space-between', marginBottom: 40, width: '100%' },
        flexLeft: { width: '45%' },
        flexRight: { width: '55%' },
        inputField: { maxWidth: 600, marginBottom: 15 }
    });

    const classes = useStyles();

    return (
        <Fragment>
            <FormLabel component="legend" className={classes.formLabel}>Invoice details</FormLabel>
            <div className={classes.flexContainer}>
                <div className={classes.row}>
                    <TextInput
                        label="Email"
                        source="invoiceEmail"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextInput
                        label="Name"
                        source="invoiceName"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextInput
                        label="Phone"
                        source="invoicePhone"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </div>
            </div>
            <div className={classes.flexContainer}>
                <FormLabel component="legend" className={classes.formLabel} style={{ marginBottom: '20px' }}>Address and coordinates</FormLabel>
                <div className={classes.row}>
                    <div className={classes.flexLeft}>
                        <SelectInput
                            source="countryId"
                            fullWidth={true}
                            className={classes.inputField}
                            variant="standard"
                            choices={[
                                { id: 1, name: 'Norway' },
                                { id: 2, name: 'Sweden' },
                                { id: 3, name: 'Finland' }
                            ]}
                            disabled
                        // InputProps={{
                        //     readOnly: true,
                        // }}
                        />

                        <SelectInput
                            source="addressType"
                            fullWidth={true}
                            className={classes.inputField}
                            variant="standard"
                            choices={[
                                { id: 0, name: 'Residental' },
                                { id: 1, name: 'Temporary' }
                            ]}
                            disabled
                        // InputProps={{
                        //     readOnly: true,
                        // }}
                        />

                        <TextInput
                            source="street"
                            fullWidth={true}
                            variant="standard"
                            className={classes.inputField}
                            disabled
                            InputProps={{
                                readOnly: true
                            }}
                        />
                        <TextInput
                            source="municipality"
                            fullWidth={true}
                            variant="standard"
                            className={classes.inputField}
                            disabled
                            InputProps={{
                                readOnly: true
                            }}
                        />
                        <TextInput
                            source="postCode"
                            fullWidth={true}
                            variant="standard"
                            className={classes.inputField}
                            disabled
                            InputProps={{
                                readOnly: true
                            }}
                        />
                        <TextInput
                            source="postName"
                            fullWidth={true}
                            variant="standard"
                            className={classes.inputField}
                            disabled
                            InputProps={{
                                readOnly: true
                            }}
                        />
                    </div>
                    <div className={classes.flexRight}>
                        <OrderInvoiceLocation locationId={props.record.locationId} />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default OrderInvoiceTab;