import {
    Dialog, DialogActions,
    DialogContent, DialogTitle
} from '@material-ui/core';
import React from 'react';
import CustomButton from '../../components/shared/CustomButton';
import config from '../../config/config';


const Remove2FADialog = ({ isOpen, closeDialog, on2FARemove, onError }) => {
    const token = localStorage.getItem('access_token');

    const onDisable2FA = () => {
        const endpoint = `${config.apiBaseUrl}/Profile/TwoFactorAuthenticateEnable`;
        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                isTwoFactorEnabled: false
            })
        });
        fetch(request)
            .then(res => res.json())
            .then(response => {
                if(response.success) {
                    on2FARemove();
                } else {
                    onError(response.error?.message || 'An error occurred, please try again later.');
                    closeDialog();
                }
            })
            .catch(error => {
                onError('An error occurred, please try again later.');
            });
    };

    return (
        <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='sm'
        >
            <DialogTitle id="alert-dialog-title">Remove two-factor authentication</DialogTitle>
            <DialogContent>
                <div style={{ color: '#666' }}>Are you sure you want to remove Two-Factor authentication?</div>
            </DialogContent>
            <DialogActions style={{ paddingBottom: 20, paddingRight: 20, marginTop: 40 }}>
                <CustomButton type="default" label="Cancel" onClick={closeDialog} />
                <CustomButton type="warning" label='Remove 2FA' onClick={onDisable2FA} style={{ minWidth: 120, marginLeft: 15 }} />
            </DialogActions>
        </Dialog>
    );
};

export default Remove2FADialog;