import React from 'react';
import UsersOrderList from '../UsersOrderList';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    container: { width: '100%', paddingBottom: 40 }
});

const UserOrdersTab = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <UsersOrderList {...props.record} />
        </div>
    );
}

export default UserOrdersTab;