// charts response data adaptation 
export const setChartData = payload => {
    let data = Object.values(payload);
    let labels = Object.keys(payload);
    
    return {
        data,
        labels
    }
};

export const setOrderLengthData = payload => {
    const result = {
        data: [],
        labels: [],
        dataLabelValues: []
    };

    payload.forEach(el => {
        result.data.push(el.value);
        result.labels.push(el.label);
        result.dataLabelValues.push(el.displayText);
    });

    return result;
};