import React, { Fragment, useState } from 'react';
import { useNotify, refreshView } from 'react-admin';
import AddUsersToGroup from './AddUsersToGroup';
import CustomLabel from '../../../shared/CustomLabel';
import CustomButton from '../../../shared/CustomButton';

import { useDispatch, useSelector } from 'react-redux';
import config from '../../../../config/config';

import { Table, TableBody, TableCell, TableHead, TableRow, Dialog, makeStyles } from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import { darkTheme, lightTheme } from "../../../layout/themes";

const UserGroupsUsersTab = React.memo((props) => {
    const groupDetails = props.record;

    let { users } = groupDetails;
    if(!users) users = [];

    const theme = useSelector(state =>
        state.theme === "dark" ? darkTheme.palette : lightTheme.palette
    );

    const permissions = useSelector(state =>
        state.permissions
    );

    const styles = {
        deleteButton: { cursor: 'pointer', color: theme.errorRed, fontSize: '20px'},
        dialogPaper: { minWidth: '50%', borderRadius: 0, padding: '20px 0 40px 0' },
        noUsers: { fontSize: '14px', border: '1px solid rgba(224, 224, 224, 1)', borderRadius: '4px', padding: '16px' },
        toolbar: { display: 'flex', alignItems: 'center' },
        btnText: { lineHeight: '1.75', fontWeight: 500, cursor: 'pointer', paddingLeft: '0.5rem', fontSize: '0.8125rem' },
        deleteBtnText: { color: theme.errorRed }
    };

    const useStyles = makeStyles(styles);
    const classes = useStyles();

    const notify = useNotify();
    const dispatch = useDispatch();

    const [openDialog, setOpenDialog] = useState(false);
    const [userId, setUserId] = useState('');

    const handleCloseDialog = () => {
        setUserId('');
        setOpenDialog(false);
    };
    
    const AddUsersButton = (props) => {
        const [open, setOpen] = useState(false);
        
        const handleClickOpen = (e) => {
            e.preventDefault();
            setOpen(true);
        };
        
        const handleClose = (value) => {
            setOpen(false);
        };
        
        return (
            <Fragment>
                <CustomButton
                    label="Add User"
                    onClick={handleClickOpen}
                    style={{ marginBottom: 20 }}
                    fullWidth={true}
                    type="default"
                />
                <Dialog
                    open={open}
                    onClose={handleClose}
                    children={<AddUsersToGroup groupDetails={groupDetails} handleClose={handleClose} />}
                    maxWidth={false}
                    classes={{ paper: classes.dialogPaper }}
                />
            </Fragment>
        );
    };

    const DeleteUserDialog = () => {
        const DeleteDialog = () => {
            const user = users.filter(user => user.id === userId);
            const name = `"${user[0].name} ${user[0].surname}"`;

            return (
                <div style={{ padding: '10px 30px'}}>
                    <p style={{ fontSize: '20px', fontWeight: 700 }}>Remove user {name}</p>
                    <p>Are you sure you want to remove this user from the group?</p>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10, marginTop: 20 }}>
                        <CustomButton
                            label="Cancel"
                            onClick={handleCloseDialog}
                            style={{ marginRight: '20px' }}
                            type="default"
                        />
                        <CustomButton
                            label="Confirm"
                            onClick={onDelete}
                            type="primary"
                        />
                    </div>
                </div>
            )
        };
        
        return (
            <Fragment>
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    children={<DeleteDialog />}
                    maxWidth={false}
                />
            </Fragment>
        );
    };

    const openDeleteDialog = (userId) => {
        setUserId(userId);
        setOpenDialog(true)
    };

    const onDelete = async () => {
        let ids = [];

        users.forEach(user => {
            if(user.id !== userId) {
                ids.push(user.id);
            }
        });

        const endpoint = `${config.apiBaseUrl}/Group/CreateOrUpdateGroup`;
        const params = {
            id: groupDetails.id,
            name: groupDetails.name,
            description: groupDetails.description ? groupDetails.description : null,
            clientId: groupDetails.client ? groupDetails.client.id : null,
            userIds: [...ids]
        };

        const token = localStorage.getItem('access_token');
        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(params)
        });

        try {
            await fetch(request);
            users = users.filter(user => user.id !== userId);
            notify('User removed from the Group.');
            dispatch(refreshView());
        } catch(err) {
            notify(err.message, 'warning');
        }

        setUserId('');
    };
    
    return (
        <Fragment>
            {permissions === 'admin' && <AddUsersButton />}
            {
                users.length ? (
                    <Fragment>
                        <CustomLabel title="Users added to this group:" style={{ marginBottom: '22px', fontSize: '17px' }}/>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        ID
                                    </TableCell>
                                    <TableCell>
                                        NAME
                                    </TableCell>
                                    <TableCell>
                                        PHONE NUMBER
                                    </TableCell>
                                    <TableCell>
                                        ROLE
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map(user => (
                                    <TableRow key={user.id}>
                                        <TableCell>{user.id}</TableCell>
                                        <TableCell>{user.name} {user.surname}</TableCell>
                                        <TableCell>{user.userName}</TableCell>
                                        <TableCell>{user.isAdmin ? 'Administrator' : 'End User' }</TableCell>
                                        <TableCell>
                                            <div className={classes.toolbar}>
                                                <DeleteIcon onClick={() => openDeleteDialog(user.id)} className={classes.deleteButton}/>
                                                <span onClick={() => openDeleteDialog(user.id)} className={`${classes.btnText} ${classes.deleteBtnText}`}>Remove</span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <DeleteUserDialog />
                    </Fragment>
                ) : <p className={classes.noUsers}>No users added</p>
            }
        </Fragment>
    );
});

export default UserGroupsUsersTab;