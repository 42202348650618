import React, { Fragment } from 'react';
import { DateField, EditButton, DeleteWithConfirmButton, TextField } from 'react-admin';

import VisibilityIcon from '@material-ui/icons/Visibility';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Card, CardContent, CardHeader, Tooltip, Button, makeStyles } from '@material-ui/core';

import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

const useStyles = makeStyles({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
        borderRadius: '4px !important',
        boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12) !important',
        overflow: 'visible !important'
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: '12px',
        overflow: 'visible !important'
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    fieldContainer: {
        marginBottom: '10px'
    },
    fieldTitle: {
        display: 'inline'
    },
    icon: {fontSize: '22px', paddingLeft: '4px', color: '#607D8B', cursor: 'pointer'},
    button: { minWidth: '35px', padding: '0 0 0 7px' },
    toolbarContainer: { display: 'flex', justifyContent: 'flex-end' }
});

const DeleteConfirmationDialog = ({ record = {}, ...props }) => {
    const { name } = record;
    const title = `Delete message template "${name}"`;

    return (
        <DeleteWithConfirmButton confirmTitle={title} {...props} record={record} />
    );
};


const TemplatesMobileGrid = ({ ids, data, basePath, clientId }) => {
    const classes = useStyles();
    
    return (
        <div style={{ margin: '1em' }}>
            {ids.map(id => (
                <Card key={id} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <h2>{`${data[id].name}`}</h2>
                                <CustomToolbar
                                    clientId={clientId}
                                    data={data[id]}
                                    style={{ justifyContent: 'flex-end'}}
                                />
                            </div>
                        }
                    />
                    <CardContent style={{ margin: '5px' }} className={classes.cardContent}>
                        {data[id].client && (
                            <div className={classes.fieldContainer}>
                                <p className={classes.fieldTitle}>Company Name: </p>
                                <TextField
                                    record={data[id]}
                                    source="client.name"
                                />
                            </div>
                        )}
                        <div className={classes.fieldContainer}>
                            <p className={classes.fieldTitle}>Language: </p>
                            <TextField
                                record={data[id]}
                                source="languageName"
                            />
                        </div>
                        <div className={classes.fieldContainer}>
                            <p className={classes.fieldTitle}>Creation Time: </p>
                            <DateField
                                record={data[id]}
                                source="creationTime"
                                showTime
                                locales="nb-NO"
                            />
                        </div>
                        {data[id].lastModificationTime && (
                            <div className={classes.fieldContainer}>
                                <p className={classes.fieldTitle}>Last Modification Time: </p>
                                <DateField
                                    record={data[id]}
                                    source="lastModificationTime"
                                    showTime
                                    locales="nb-NO"
                                />
                            </div>
                        )}
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};

const CustomToolbar = ({clientId, data}) => {
    const classes = useStyles();
    const history = useHistory();

    const id = data.id;

    const onShow = () => {
        history.push(`/message-templates/${id}/show`);
    };

    const CustomCloneButton = () => {
        const { name, subject, text } = data;
        return (
            <Button
                component={Link}
                to={{
                    pathname: '/message-templates/create',
                    state: { record: { clientId, name, subject, text }},
                }}
            >
                <CopyIcon />
            </Button>
        );
    }

    const RenderToolbar = () => {
        const permissions = useSelector(state =>
            state.permissions
        );

        if(clientId === null && permissions === 'admin') {
            return(
                <Fragment>
                    <EditButton className={classes.button} label={""} basePath={'/message-templates'} record={data}/>
                    <DeleteConfirmationDialog className={classes.button} label={""} basePath={'/message-templates'} record={data} resource={'message-templates'} />
                </Fragment>
            );
        } else if(clientId !== null & data.client === null) {
            return(
                <Fragment>
                    {data.copyAllowed
                        ? <CustomCloneButton />
                        : <span style={{ minWidth: '64px', display: 'inline-block'}}></span>
                    }
                    <Tooltip title={'View'} placement="right">
                        <VisibilityIcon onClick={onShow} className={classes.icon} style={{ verticalAlign: 'middle', paddingTop: '5px' }} />
                    </Tooltip>
                </Fragment>
            );
        } else {
            return(
                <Fragment>
                    <EditButton className={classes.button} label={""} basePath={'/message-templates'} record={data} />
                    <DeleteConfirmationDialog className={classes.button} label={""} basePath={'/message-templates'} record={data} resource={'message-templates'} />
                </Fragment> 
            );
        }
    };

    return(
        <div className={classes.toolbarContainer}>
            <RenderToolbar/>
        </div>
    )
};

const CopyIcon = () => {
    const classes = useStyles();
    return(
        <Tooltip title={'Copy'} placement="right">
            <FileCopyOutlinedIcon className={classes.icon} />
        </Tooltip>
    )
};

export default TemplatesMobileGrid;
