import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from "react";
import { maxLength, required, TextInput } from "react-admin";


const useStyles = makeStyles({
    container: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
    tooltipIcon: { marginLeft: 10, width: 20, height: 20, color: '#4e6a77', cursor: 'pointer' },
});

const ShortDescriptionInput = () => {
    const classes = useStyles();
    const tooltip = "Short description will be shown in mobile app list view";

    return (
        <div className={classes.container}>
            <TextInput
                variant="standard"
                label="Short description"
                multiline
                validate={[required(), maxLength(250)]}
                source="description"
                style={{ width: '50%', minWidth: 400 }}
            />
            <Tooltip title={tooltip} placement="right">
                <InfoOutlinedIcon className={classes.tooltipIcon} />
            </Tooltip>
        </div>
    )
};

export default ShortDescriptionInput;