import {
	addDays,
	startOfWeek,
	endOfWeek,
	addWeeks,
	startOfMonth,
	endOfMonth,
	addMonths,
	startOfDay
} from "date-fns";

// const allTimeStart = new Date('2000-12-31T23:00:00.000Z');

const dates = date => {
	return {
		allTime: {
			label: "All Time",
			startDate: '',
			endDate: '',
			period: 32
		},
		today: {
			label: "Today",
			startDate: startOfDay(date).toISOString(),
			//endDate: date.toISOString(),
			period: 1,
		},
		yesterday: {
			label: "Yesterday",
			startDate: startOfDay(addDays(date, -1)).toISOString(),
			endDate: startOfDay(date).toISOString(),
			period: 1,
		},
		thisWeek: {
			label: "This Week",
			startDate: startOfWeek(date, {weekStartsOn: 1}).toISOString(),
			endDate: endOfWeek(date, {weekStartsOn: 1}).toISOString(),
			period: 7,
		},
		lastWeek: {
			label: "Last Week",
			startDate: startOfWeek(addWeeks(date, -1)).toISOString(),
			endDate: endOfWeek(addWeeks(date, -1)).toISOString(),
			period: 7,
		},
		last7Days: {
			label: "Last 7 Days",
			startDate: addWeeks(date, -1).toISOString(),
			endDate: date.toISOString()
		},
		thisMonth: {
			label: "This Month",
			startDate: startOfMonth(date).toISOString(),
			endDate: endOfMonth(date).toISOString(),
			period: 31,
		},
		lastMonth: {
			label: "Last Month",
			startDate: startOfMonth(addMonths(date, -1)).toISOString(),
			endDate: endOfMonth(addMonths(date, -1)).toISOString(),
			period: 31,
		}
	}
};

export default dates(new Date());