export const cleanResource = (resource) => {
    switch (resource) {
        case 'message-templates':
            return 'MessageTemplate';

        case 'user-groups':
            return 'Group';

        case 'clients':
            return 'Client';

        case 'users':
            return 'User'

        case 'locations':
            return 'Location'

        case 'doors':
            return 'Door'

        case 'orders':
            return 'Order'

        case 'announcements':
            return 'Announcement'

        default:
            return resource;
    }
};

export const createQueryParams = (params) => {
    const {
        page,
        perPage
    } = params.pagination;
    const {
        field,
        order
    } = params.sort;
    const filter = Object.assign({}, params.filter);
    const queryParams = {
        sorting: `${field} ${order}`,
        skipCount: (page - 1) * perPage,
        maxResultCount: perPage,
        filterRule: {
            condition: 'and',
            filterRules: params.filterRules || []
        }
    };

    if (!params.filterRules) {
        Object.keys(filter).forEach((item) => {
            // react-admin is removing filters with value null so when needed we can instead pass string 'null'
            // TODO: find a better workaround
            const value = filter[item] === 'null' ? null : filter[item];

            // set search filter for multiple fields
            if (isSearchFilter(item)) {
                const searchValues = item === 'usersSearch' ? value.split(' ') : [value];

                // search for multiple values
                searchValues.forEach(value => {
                    if (value === '') return;

                    queryParams.filterRule.filterRules.push({
                        condition: 'or',
                        filterRules: []
                    });

                    const searchFields = getSearchFields(item);
                    const lastItemIndex = queryParams.filterRule.filterRules.length - 1;

                    searchFields.forEach(field => {
                        let fieldValue;
                        if (field === 'Id') {
                            fieldValue = parseInt(value);
                            if (isNaN(fieldValue)) return;
                        }

                        queryParams.filterRule.filterRules[lastItemIndex].filterRules.push({
                            field,
                            operator: field === 'Id' ? 'equals' : 'contains',
                            value: field === 'Id' ? parseInt(value) : value
                        });
                    });
                });

            } else {
                queryParams.filterRule.filterRules.push({
                    field: capitalizeFirstLetter(item), // capitalize field name
                    operator: 'equals',
                    value
                });
            }
        });
    }

    return queryParams;
};

function getSearchFields(filter) {
    switch (filter) {
        case 'clientsSearch':
        case 'locationsSearch':
        case 'templatesSearch':
        case 'userGroupsSearch':
            return ['Name'];

        case 'nbDoorsSearch':
        case 'plcDoorsSearch':
            return ['Name'];

        case 'ordersSearch':
            return ['InvoiceName'];

        case 'usersSearch':
            return ['Name', 'Surname', 'Id', 'UserName'];

        default:
            return ['Name'];
    }
}

function isSearchFilter(filter) {
    const searchFilters = [
        'clientsSearch',
        'locationsSearch',
        'nbDoorsSearch',
        'ordersSearch',
        'plcDoorsSearch',
        'templatesSearch',
        'userGroupsSearch',
        'usersSearch'
    ];

    return searchFilters.includes(filter);
}

export const getManyIdsFilter = (ids) => {
    const userIds = ids;
    const filter = {
        filterRule: {
            condition: 'or',
            filterRules: []
        }
    };

    userIds.forEach(id => {
        filter.filterRule.filterRules.push({
            field: 'Id',
            operator: 'equals',
            value: id
        })
    });

    return filter;
};

export const getGroupListParams = (params) => {
    const {
        filter
    } = params;
    const {
        field,
        order
    } = params.sort;
    const {
        page,
        perPage
    } = params.pagination;

    let newParams = {
        sorting: `${field} ${order}`,
        skipCount: (page - 1) * perPage,
        maxResultCount: perPage,
        groupId: filter.groupId,
        inGroup: false,
        filterRule: {
            condition: 'and',
            filterRules: []
        }
    };

    Object.keys(filter).forEach(el => {
        if (el !== 'groupId') {
            newParams.filterRule.filterRules.push({
                field: capitalizeFirstLetter(el),
                operator: 'equals',
                value: filter[el]
            });
        }
    });

    return newParams;
};

export const getUserUpdateParams = ({
    data
}) => {
    let updateParams = {
        user: {},
        userGroupsIds: []
    };

    updateParams.user = data;
    updateParams.userGroupsIds = data.userGroupsIds;
    delete updateParams.user.userGroupsIds;

    return updateParams;
};

export const getUserGroupUpdateParams = ({
    data,
    previousData
}) => {
    let newGroupParams = Object.assign({}, data);

    const userIds = previousData.users.map(user => user.id);
    const clientId = newGroupParams.client.id;

    delete newGroupParams.users;
    delete newGroupParams.client;

    newGroupParams = Object.assign(newGroupParams, {
        userIds,
        clientId
    });

    return newGroupParams;
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};