import React, {useState} from 'react';
import { useSelector } from 'react-redux';

import { MenuItemLink } from 'react-admin';
import LocationIcon from '@material-ui/icons/LocationOnOutlined';
import OrdersIcon from '@material-ui/icons/DescriptionOutlined';
import MessageIcon from '@material-ui/icons/ChatOutlined';
import UsersIcon from '@material-ui/icons/PeopleAltOutlined';
import UserGroupsIcon from '@material-ui/icons/LocalLibraryOutlined';
import ClientsIcon from '@material-ui/icons/BusinessCenterOutlined';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import DiagnosticsIcon from '@material-ui/icons/TimelineOutlined';
import NotificationsIcon from '@material-ui/icons/NotificationsNoneOutlined';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import SubMenu from '../SubMenu';
import SupportSidebar from './Support';

const AdminSidebar = ({
    open,
    dense,
    onMenuClick,
    menuItemColor
}) => {
    const pathname = useSelector(state =>
        state.router.location.pathname
    );

    const [menuCityManagement, setMenuCityManagement] = useState(false); // (Local state)

    const handleCityMenu = () => {
        setMenuCityManagement(!menuCityManagement);
    };

    return (
        <div>
            <SubMenu
                handleToggle={() => handleCityMenu()}
                isOpen={menuCityManagement}
                sidebarIsOpen={open}
                name={'Locations'}
                icon={<LocationCityIcon style={{ color: menuItemColor }} />}
                dense={dense}
                style={{ color: menuItemColor }}
            >
                <MenuItemLink
                    to={`/locations`}
                    primaryText={'Locations'}
                    leftIcon={<LocationIcon style={{ color: menuItemColor }} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    selected={pathname === '/locations'}
                    style={{ color: menuItemColor }}
                />
                <MenuItemLink
                    to={`/orders`}
                    primaryText={'Orders'}
                    leftIcon={<OrdersIcon style={{ color: menuItemColor }} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    style={{ color: menuItemColor }}
                    selected={pathname === '/orders'}
                />
                <MenuItemLink
                    to={`/user-groups`}
                    primaryText={'Private Groups'}
                    leftIcon={<UserGroupsIcon style={{ color: menuItemColor }} />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    style={{ color: menuItemColor }}
                    selected={pathname === '/user-groups'}
                />
            </SubMenu>
            <SupportSidebar
                    open={open}
                    dense={dense}
                    onMenuClick={onMenuClick}
                    menuItemColor={menuItemColor}
                />
            <MenuItemLink
                to={`/clients`}
                primaryText={'Clients'}
                leftIcon={<ClientsIcon style={{ color: menuItemColor }} />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                style={{ color: menuItemColor }}
                selected={pathname === '/clients'}
            />
            <MenuItemLink
                to={`/users`}
                primaryText={'Users'}
                leftIcon={<UsersIcon style={{ color: menuItemColor }} />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                style={{ color: menuItemColor }}
                selected={pathname === '/users'}
            />
            <MenuItemLink
                to={`/message-templates`}
                primaryText={'Message Templates'}
                leftIcon={<MessageIcon style={{ color: menuItemColor }} />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                style={{ color: menuItemColor }}
                selected={pathname === '/message-templates'}
            />
            <MenuItemLink
                to={`/terms-and-conditions`}
                primaryText='Terms And Conditions'
                leftIcon={<LibraryBooksIcon style={{ color: menuItemColor }} />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                style={{ color: menuItemColor }}
                selected={pathname === '/terms-and-conditions'}
            />
            <MenuItemLink
                to={`/announcements`}
                primaryText='Announcements'
                leftIcon={<NotificationsIcon style={{ color: menuItemColor }} />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                style={{ color: menuItemColor }}
                selected={pathname === '/announcements'}
            />
            <MenuItemLink
                to={`/diagnostic`}
                primaryText='Diagnostics'
                leftIcon={<DiagnosticsIcon style={{ color: menuItemColor }} />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                style={{ color: menuItemColor }}
                selected={pathname === '/diagnostic'}
            />
        </div>
    );
};

export default AdminSidebar;
