import React, { Fragment } from 'react';
import { List, SearchInput, Datagrid, TextField, EditButton, DeleteWithConfirmButton, DateField, Filter, NotFound } from 'react-admin';

import TemplatesMobileGrid from './TemplatesMobileGrid';
import CustomLabel from '../shared/CustomLabel';
import CustomButton from '../shared/CustomButton';

import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import VisibilityIcon from '@material-ui/icons/Visibility';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Tooltip, makeStyles, useMediaQuery, Button } from '@material-ui/core';
import ActionDropdown from '../shared/ActionDropdown';

const useStyles = makeStyles({
    search: { display: 'flex', justifyContent: 'flex-end' },
    icon: { fontSize: '22px', paddingLeft: '3px', color: '#607D8B', cursor: 'pointer' },
    button: { minWidth: '35px', paddingLeft: '22px' }
});

const MessageTemplateFilter = props => {
    const classes = useStyles();
    const history = useHistory();

    const handleClick = () => {
        history.push(`${props.basePath}/create`);
    };

    return (
        <Fragment>
            <CustomButton type="primary" fullWidth={true} label="Create new" basePath={'/message-templates'} onClick={handleClick} />
            <div className={classes.search}>
                <Filter {...props} variant="outlined">
                    <SearchInput source="templatesSearch" alwaysOn resettable />
                </Filter>
            </div>
        </Fragment>
    );
};

const CopyIcon = () => {
    const classes = useStyles();
    return (
        <Tooltip title={'Copy'} placement="right">
            <FileCopyOutlinedIcon className={classes.icon} />
        </Tooltip>
    )
};

const DeleteConfirmationDialog = ({ record = {}, ...props }) => {
    const { name } = record;
    const title = `Delete message template "${name}"`;

    return (
        <DeleteWithConfirmButton confirmTitle={title} {...props} record={record} />
    );
};

const CustomToolbar = ({ clientId, ...props }) => {
    const classes = useStyles();
    const history = useHistory();

    const { record } = props;
    const { id } = record;

    const onShow = () => {
        history.push(`/message-templates/${id}/show`);
    };

    const CustomCloneButton = ({ record }) => {
        const { name, subject, text } = record;
        return (
            <Button
                component={Link}
                to={{
                    pathname: '/message-templates/create',
                    state: { record: { clientId, name, subject, text } },
                }}
            >
                <CopyIcon />
            </Button>
        );
    }

    const RenderToolbar = () => {
        const permissions = useSelector(state =>
            state.permissions
        );

        if (clientId === null && permissions === 'admin') {
            return (
                <Fragment>
                    <ActionDropdown label="Actions" entity="template" {...props}/>
                </Fragment>
            );
        } else if (clientId !== null & record.client === null) {
            return (
                <Fragment>
                    {record.copyAllowed
                        ? <CustomCloneButton {...props} />
                        : <span style={{ minWidth: '64px', display: 'inline-block' }}></span>
                    }
                    <Tooltip title={'View'} placement="right">
                        <VisibilityIcon onClick={onShow} className={classes.icon} style={{ verticalAlign: 'middle' }} />
                    </Tooltip>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <ActionDropdown label="Actions" entity="template" {...props}/>
                </Fragment>
            );
        }
    };

    return (
        <Fragment>
            <RenderToolbar />
        </Fragment>
    )
};

const MessageTemplateList = ({ ...props }) => {
    const permissions = useSelector(state =>
        state.permissions
    );

    const clientId = useSelector(state =>
        state.user.clientId
    );

    const isXsmall = useMediaQuery(theme => theme.breakpoints.down("xs"));

    return (
        <Fragment>
            {permissions === 'admin' ? (
                <Fragment>
                    <CustomLabel title={'Message Templates'} />
                    <List actions={null} filters={<MessageTemplateFilter />} {...props} bulkActionButtons={false}>
                        {isXsmall ? (
                            <TemplatesMobileGrid clientId={clientId} />
                        ) : (
                                <Datagrid rowClick={null}>
                                    <TextField source="id" label={'ID'} />
                                    <TextField source="name" />
                                    <TextField source="client.name" label={'Company Name'} />
                                    <TextField source="languageName" />
                                    <DateField source="creationTime" showTime locales="nb-NO" />
                                    <DateField source="lastModificationTime" showTime locales="nb-NO" />
                                    <CustomToolbar clientId={clientId} />
                                </Datagrid>
                            )}
                    </List>
                </Fragment>
            ) : <NotFound />}
        </Fragment>
    );
};

export default MessageTemplateList;