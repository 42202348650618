import React, { useState, Fragment } from 'react';
import { Datagrid, TextField, DateField, useQuery, Pagination, Loading } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, makeStyles, Button } from '@material-ui/core/';
import { TextField as MessageTextField } from '@material-ui/core/';
import CustomButton from '../../shared/CustomButton';

import moment from 'moment';
import keyBy from 'lodash/keyBy';
import SendMessageDialog from '../SendMessageDialog';

const styles = {
    dialogPaper: { width: '50%', height: '65%', borderRadius: 0, padding: '0', fontFamily: 'Roboto', fontSize: '15px' },
    dialogTitle: { color: '#562219', paddingTop: '20px' },
    flexContainer: { display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' },
    button: { backgroundColor: '#72C948', boxShadow: 'none', '&:hover': { backgroundColor: '#72C948', opacity: .9, boxShadow: 'none' } },
    row: {
        display: 'flex', width: '100%', flexWrap: 'wrap',
        '& .MuiTextField-root': {
            '& .MuiInput-root': {
                '& textarea': {
                    color: 'black',
                    fontSize: '15px !important',
                    fontWeight: '400 !important'
                }
            }
        }
    },
    detailsRow: { marginLeft: '50px', marginBottom: '10px' },
    buttonRow: { width: '90%', backgroundColor: 'white', position: 'absolute', bottom: '1rem', left: 0, right: 0, marginLeft: '24px' }
};

const useStyles = makeStyles(styles);

const UserMessageLogsTab = ({ record }) => {
    const classes = useStyles();

    const { id } = record;
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sort, setSort] = useState({ field: 'creationTime ', order: 'DESC' });
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(null);
    const [editMode, setEditMode] = useState(false);

    const changePerPage = (value) => {
        setPage(1);
        setPerPage(value);
    };

    const changeSort = (field) => {
        setPage(1);
        const order = sort.order === 'ASC' ? 'DESC' : 'ASC';
        setSort({ field, order });
    };

    const { data, total, loading, error } = useQuery({
        type: 'GET_LIST',
        resource: 'messageList',
        payload: {
            pagination: { page, perPage },
            sort,
            filter: {
                'UserId': id
            }
        }
    });

    if (loading) {
        return <Loading />
    }

    if (error) {
        return <p>ERROR: {error}</p>
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    const openMessage = (id) => {
        const message = data.filter(el => el.id === id);
        setMessage(message[0]);
        handleClickOpen();
    };

    const MessageBody = () => {
        const sendAt = moment(message.sendAt).format('MMMM Do YYYY, HH:mm:ss');
        return (
            <Fragment>
                <div className={`${classes.row} ${classes.detailsRow}`}>
                    <span>From:</span>
                    <span style={{ marginLeft: '10px' }}>{message.from}</span>
                </div>
                <div className={`${classes.row} ${classes.detailsRow}`}>
                    <span>Date:</span>
                    <span style={{ marginLeft: '10px' }}>{sendAt}</span>
                </div>
                <div className={`${classes.row} ${classes.detailsRow}`}>
                    <span>Subject:</span>
                    <span style={{ marginLeft: '10px' }}>{message.subject}</span>
                </div>
                <div className={`${classes.row} ${classes.detailsRow}`}>
                    <span>To:</span>
                    <span style={{ marginLeft: '10px', marginBottom: '20px' }}>{message.to}</span>
                </div>
                <div className={`${classes.row} ${classes.detailsRow}`}>
                    <MessageTextField
                        multiline
                        value={message.text}
                        variant="standard"
                        fullWidth={true}
                        disabled
                    />
                </div>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <div style={{ height: 50 }}>
                <Button className={classes.button} variant="contained" color="primary" onClick={() => {
                    setEditMode(true);
                }}>Send new message</Button>
            </div>
            <Datagrid
                data={keyBy(data, 'id')}
                ids={data.map(({ id }) => id)}
                currentSort={sort}
                setSort={changeSort}
                rowClick={(e) => openMessage(e)}
            >
                <TextField source="id" />
                <TextField source="to" />
                <TextField source="subject" />
                <DateField source="sentOrReceivedAt" label="Sent/Received" showTime locales="nb-NO" />
                <TextField source="retries" />
                <DateField source="creationTime" label="Created" showTime locales="nb-NO" />
            </Datagrid>
            <Pagination
                page={page}
                perPage={perPage}
                setPage={setPage}
                setPerPage={changePerPage}
                total={total}
            />
            <SendMessageDialog
                open={editMode}
                title={`Send SMS to user ${record.userName}?`}
                setOpen={(val) => setEditMode(val)}
                userId={record.id}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={false}
                classes={{ paper: classes.dialogPaper }}
                aria-labelledby="scroll-dialog-title"
            >
                <DialogTitle id="scroll-dialog-title" className={classes.dialogTitle}>Message Details</DialogTitle>
                <DialogContent>
                    {message && (
                        <div className={classes.flexContainer} >
                            <div className={classes.row} style={{ paddingBottom: '10px', borderBottom: '1px solid #e0e0e0', }}>
                                <span style={{ fontWeight: '700' }}>Subject:</span>
                                <span style={{ marginLeft: '15px' }}>{message.subject}</span>
                            </div>
                            <div className={classes.row}>
                                <p style={{ fontWeight: '700', marginBottom: '0' }}>Text:</p>
                            </div>
                            <div className={classes.row} style={{ overflowY: 'scroll', maxHeight: '400px' }}>
                                <MessageBody text={message.text} />
                            </div>
                            <div className={classes.buttonRow}>
                                <CustomButton type="default" label="Close" onClick={handleClose} style={{ marginRight: '20px' }} />
                            </div>
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};

export default UserMessageLogsTab;