import React, { Fragment, useState } from 'react';

import ChangePassword from './ChangePassword'
import CustomButton from '../shared/CustomButton';

import config from '../../config/config';
import { useHistory } from 'react-router';

import MuiAlert from '@material-ui/lab/Alert';
import { AppBar, Toolbar, makeStyles, TextField, Snackbar } from '@material-ui/core';

import LogoImg from '../../assets/images/logo.svg';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';

const useStyles = makeStyles({
    appBar: { backgroundColor: '#562219' },
    toolbar: { display: 'flex', justifyContent: 'center', height: 50, minHeight: 'unset' },
    container: { position: 'absolute', top: 50, left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' },
    content: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column', textAlign: 'center', padding: '60px 30px', background: '#eee', fontSize: '1.1rem', color: '#434343', width: 480, height: '', '& .react-tel-input': { width: 'auto' } },
    field: { backgroundColor: 'white', width: '300px' },
    resetCode: { backgroundColor: 'white', width: '200px' },
});

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} style={{ backgroundColor: '#e57373' }} />;
};

const ResetPassword = props => {
    const classes = useStyles();
    const history = useHistory();

    const [screen, setScreen] = useState(0);

    const [emailValue, setEmailValue] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');
    // eslint-disable-next-line
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const [firstSnackbarOpen, setFirstSnackbarOpen] = useState(false);
    const [firstSnackbarMessage, setFirstSnackbarMessage] = useState('');
    const [secondSnackbarOpen, setSecondSnackbarOpen] = useState(false);
    const [secondSnackbarMessage, setSecondSnackbarMessage] = useState('');

    const [resetCode, setResetCode] = useState('');
    const [codeError, setCodeError] = useState(false);

    const [userId, setUserId] = useState('');

    const handleEmailChange = (value) => {
        setEmailValue(value);

        setEmailErrorText('');
        setEmailError(false);
    };

    const validateEmail = (e) => {
        if(emailValue.length === 0) {
            setEmailErrorText('This field is required')
            setEmailError(true);
            return true;
        }

        const validEmail = emailRegex.test(emailValue);
        if(!validEmail) {
            setEmailErrorText('Please enter a valid email address');
            setEmailError(true);
            return true
        } 

        setEmailErrorText('');
        setEmailError(false);
        return false;
    };

    const validateCode = (e) => {
        if(resetCode.length === 0) {
            setCodeError(true)
            return true;
        }

        setCodeError(false);
        return false;
    };

    const handleResetCodeChange = (value) => {
        setResetCode(value);
        setCodeError(false)
    };

    const onResetPassword = () => {
        const hasErrors = validateEmail();

        if(hasErrors) return;

        const params = {
            emailAddress: emailValue
        };

        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Account/SendPasswordResetCode`;

        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(params)
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                if(response && response.success) {
                    return setScreen(1);
                }

                if(response.error) {
                    setFirstSnackbarMessage(response.error.message);
                    setFirstSnackbarOpen(true);
                }
            });
    };

    const onCheckResetCode = () => {
        const hasErrors = validateCode();

        if(hasErrors) return;

        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Account/CheckPasswordResetCode?code=${resetCode}`;

        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                if(response && response.result) {
                    const { userId } = response.result;
                    setUserId(userId);

                    return setScreen(2);
                }

                if(response && response.error) {
                    let error = response.error.message;
                    if(error === '[Invalid password reset code]') error = 'Reset code is not valid';

                    setSecondSnackbarMessage(error);
                    setSecondSnackbarOpen(true);
                }
            });
    };

    const goToLogin = () => {
        history.push('/login');
    };

    const handleScreenChange = (value) => {
        setScreen(value);
    };

    return (
        <Fragment>
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <img src={LogoImg} alt="logo" style={{ maxWidth: 150 }} />
                </Toolbar>
            </AppBar>
            <div className={classes.container}>
                {screen === 0 && (
                    <div className={classes.content} style={{ height: 250 }}>
                        <LockOpenOutlinedIcon style={{ fontSize: '34px' }} />
                        <div style={{ fontWeight: 500, fontSize: '22px' }}>Forgot your password?</div>
                        <div>
                            Please enter your email address to request a password reset.
                        </div>
                        <div>
                            <TextField
                                className={classes.field}
                                label="Enter your email address"
                                required
                                type="text"
                                variant="outlined"
                                value={emailValue}
                                onChange={(e) => handleEmailChange(e.target.value)}
                            />
                            {emailError && <small style={{ display: 'block', color: '#f44336', fontSize: '13px', textAlign: 'left', margin: '5px 14px 0 14px' }}>{emailErrorText}</small>}
                            
                        </div>
                        <div style={{ width: '300px' }}>
                            <CustomButton type="primary" label="Reset password" onClick={onResetPassword} style={{ marginRight: '25px' }}/>
                            <CustomButton type="default" label="Cancel" onClick={goToLogin} />
                        </div>
                        <Snackbar open={firstSnackbarOpen} autoHideDuration={6000} onClose={() => setFirstSnackbarOpen(false)}>
                            <Alert onClose={() => setFirstSnackbarOpen(false)} severity="error">
                                {firstSnackbarMessage}
                            </Alert>
                        </Snackbar>
                    </div>
                )}
                {screen === 1 && (
                    <div className={classes.content} style={{ height: 250, width: 'max-content' }}>
                        <LockOpenOutlinedIcon style={{ fontSize: '34px' }} />
                        <div style={{ fontWeight: 500, fontSize: '22px' }}>Password reset</div>
                        <div style={{ textAlign: 'left', marginBottom: '15px', marginTop: '25px' }}>
                            Reset code sent to email <span style={{ fontWeight: '700' }}>{emailValue}</span>.
                            <br></br>
                            Please enter the reset code to continue.
                        </div>
                        <div>
                            <TextField
                                className={classes.resetCode}
                                label="Enter reset code"
                                required
                                type="text"
                                variant="outlined"
                                value={resetCode}
                                onChange={(e) => handleResetCodeChange(e.target.value)}
                            />
                            {codeError && <small style={{ display: 'block', color: '#f44336', fontSize: '13px', textAlign: 'left', margin: '5px 14px 0 14px' }}>This field is required</small>}

                        </div>
                        <div style={{ width: '300px', marginTop: '15px' }}>
                            <CustomButton type="primary" label="Check reset code" onClick={onCheckResetCode} style={{ marginRight: '25px' }}/>
                            <CustomButton type="default" label="Cancel" onClick={goToLogin} />
                        </div>
                        <Snackbar open={secondSnackbarOpen} autoHideDuration={6000} onClose={() => setSecondSnackbarOpen(false)}>
                            <Alert onClose={() => setSecondSnackbarOpen(false)} severity="error">
                                {secondSnackbarMessage}
                            </Alert>
                        </Snackbar>
                    </div>
                )}
                {screen === 2 && (
                    <div className={classes.content} style={{ height: 330, width: 'max-content' }}>
                        <LockOpenOutlinedIcon style={{ fontSize: '34px' }} />
                        <div style={{ fontWeight: 500, fontSize: '22px', marginBottom: '40px' }}>Change password</div>
                        <ChangePassword userId={userId} resetCode={resetCode} screenChange={handleScreenChange} />
                    </div>
                )}
                {screen === 3 && (
                    <div className={classes.content} style={{ height: 100 }}>
                        <LockOpenOutlinedIcon style={{ fontSize: '34px' }} />
                        <div style={{ fontWeight: 500, fontSize: '22px' }}>Password successfully changed!</div>
                        <div>
                            <span onClick={goToLogin} style={{ color: '#562219', fontWeight: 700, cursor: 'pointer' }}>Click here</span> to go back to login page.
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default ResetPassword;