import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from 'react-redux';
import { Datagrid, EmailField, List, TextField } from "react-admin";
import CustomLabel from '../shared/CustomLabel';
import CustomButton from '../shared/CustomButton';
import { Divider, Tabs, Tab, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { TextField as SearchField } from '@material-ui/core/';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { darkTheme, lightTheme } from "../layout/themes";
import { useHistory } from 'react-router-dom';
import ActionDropdown from '../shared/ActionDropdown';
import SuspendSwitch from './SuspendSwitch'
import { EditButton } from 'react-admin'

const UsersFilter = ({permissions, ...props}) => {

    const theme = useSelector(state =>
        state.theme === "dark" ? darkTheme.palette : lightTheme.palette
    );

    const useStyles = makeStyles({
        search: { display: 'flex', justifyContent: 'flex-end', marginRight: '20px', marginTop: '30px' },
        filterToolbar: { display: 'flex' },
        searchField: { position: 'relative', display: 'inline-block' },
        searchIcon: { margin: '6px 0 0 6px', fontSize: '30px', color: theme.secondary.main },
        dialogPaper: { minWidth: '40%', borderRadius: 0, padding: '20px 20px 40px 20px' },
    });
    const classes = useStyles();
    const history = useHistory();

    const { tab } = props;
    const { setFilters } = props;
    const oldSearchFilter = useSelector(state => state.admin.resources.users.list.params.filter.usersSearch);

    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setInputValue('');

        if (oldSearchFilter && oldSearchFilter !== inputValue) {
            return setInputValue(oldSearchFilter);
        }
        
        if (permissions === 'client') {
            return;
        }

        const isAdmin = (tab === 'administrator') ? true : false;
        const filter = { isAdmin };

        if ((tab === 'administrator' && isAdmin === true) || (tab === 'endUser' && isAdmin === false)) {
            return;
        }

        setFilters(filter);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);

    const handleClick = () => {
        if (permissions === 'customer') {
            return setOpen(true);
        }

        history.push('users/create');
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    const onSearch = (value) => {
        setInputValue(value);

        const usersSearch = value;
        const isAdmin = (tab === 'administrator') ? true : false;

        const filter = { isAdmin, usersSearch };
        setFilters(filter);
    };

    const CreateButton = () => {
        
        return (
            <CustomButton
                type="primary"
                fullWidth={true}
                label="Create new"
                basePath={'/users'}
                onClick={handleClick}
                style={{ margin: '10px 0 20px 10px' }}
            />
        );
        

        return <div style={{ width: '100%' }}></div>;
    };

    return (
        <Fragment>
            <div className={classes.filterToolbar}>
                {tab === 'administrator' ? (
                    <CreateButton />
                ) : <div style={{ width: '100%' }}></div>}
                <div className={classes.search}>
                    <div className={classes.searchField}>
                        <SearchField
                            label="Search"
                            onChange={(e) => onSearch(e.target.value)}
                            variant="outlined"
                            value={inputValue}
                            source="search"
                            type="search"
                            size="small"
                        />
                    </div>
                    <SearchIcon className={classes.searchIcon} />
                </div>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={false}
                classes={{ paper: classes.dialogPaper }}
                aria-labelledby="scroll-dialog-title"
            >
                <DialogTitle id="scroll-dialog-title" style={{ color: theme.secondary.main }}>Create New Customer Administrator</DialogTitle>
                <DialogContent>
                    {/* <CreateAdminDialog refreshGrid={handleClose} /> */}
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}

const FullNameField = ({ record = {} }) => <span>{record.name} {record.surname}</span>;

const AccessLevel = ({ record = {} }) => {
    const { accessLevel } = record;

    const levels = {
        0: 'Read Access',
        1: 'Edit Access',
        2: 'Full Access'
    };

    return (
        <span>
            {levels[accessLevel]}
        </span>
    );
};

class TabbedDataGrid extends React.Component {
    tabs = [
        { id: "administrator", name: "Administrators" },
        { id: "endUser", name: "End users" }
    ];

    state = { administrator: [], endUser: [] };

    activeTab = "administrator";

    static getDerivedStateFromProps(props, state) {
        const userType = props.filterValues.isAdmin ? 'administrator' : 'endUser';

        if (props.ids !== state[userType]) {
            return { ...state, [userType]: props.ids };
        }
        return null;
    }

    handleChange = (event, value) => {
        if (this.props.permissions === 'client') {
            return;
        }

        this.activeTab = value;
        const { setFilters } = this.props;
        const isAdmin = (value === 'administrator') ? true : false;

        const filter = { isAdmin };
        setFilters(filter);
    };

    componentDidMount() {
        if (this.props.permissions === 'client') {
            this.tabs = [
                { id: "administrator", name: "Administrators" }
            ];
        }

        const { isAdmin } = this.props.filterValues;
        this.activeTab = isAdmin ? 'administrator' : 'endUser';
    };

    componentWillUnmount() {
        this.activeTab = 'administrator';
    };

    render() {
        const { filterValues, ...props } = this.props;
        return (
            <Fragment>
                <Tabs
                    fullWidth={true}
                    value={filterValues.isAdmin ? 'administrator' : 'endUser'}
                    indicatorColor="primary"
                    onChange={this.handleChange}
                >
                    {this.tabs.map(choice => (
                        <Tab key={choice.id} label={choice.name} value={choice.id} />
                    ))}
                </Tabs>
                <Divider />
                <UsersFilter tab={filterValues.isAdmin ? 'administrator' : 'endUser'} {...props} />
                <div style={{ width: '100%' }}>
                    {this.activeTab === "administrator" && (
                        <Datagrid
                            {...props}
                            ids={this.state.administrator}
                            rowClick="edit"
                        >
                            <TextField source="id" />
                            <FullNameField source="name" label="Name" />
                            <EmailField source="emailAddress" />
                            <TextField source="phoneNumber" />
                            <TextField label="Type"> {this.props.isAdmin ? 'Administrator' : 'Support'} </TextField>
                            <AccessLevel label={'Access Level'} />
                            {this.props.permissions !== 'customer' && (
                                <TextField source="client.name" label={'Client'} />
                            )}
                            {(this.props.permissions === 'admin' && !this.props.isBikelyCustomer) && (
                                <TextField source="customer.name" label={'Customer'} />
                            )}
                            {this.props.permissions === 'customer' && this.props.customerAccessLevel === 0 ? null : (
                                <ActionDropdown label="Actions" entity="user" permissions={this.props.permissions} customerAccessLevel={this.props.customerAccessLevel}/>
                            )}
                        </Datagrid>
                    )}
                    {(this.activeTab === "endUser" && (this.props.permissions === "admin" || this.props.permissions === "customer")) && (
                        <Datagrid
                            {...props}
                            ids={this.state.endUser}
                            
                            // rowClick="edit"
                        >
                            <TextField source="id" />
                            <FullNameField source="name" label="Name" />
                            <TextField source="userName" label="Phone Number" />
                            <SuspendSwitch source="suspended" label="Status" {...props} />
                            <TextField source="fiatBalance" label="Balance"/>
                            <EditButton {...props} />
                        </Datagrid>
                    )}
                </div>
            </Fragment>
        );
    }
}

const UsersList = ({ ...props }) => {
    const permissions = useSelector(state =>
        state.permissions
    );

    const isBikelyCustomer = useSelector(state => state.bikelyCustomer);

    const customerAccessLevel = useSelector(state =>
        state.user.accessLevel
    );

    return (
        <Fragment>
            {permissions && (
                <Fragment>
                    <CustomLabel title={'Users'} style={{ marginBottom: '15px' }} />
                    <List
                        {...props}
                        filterDefaultValues={{ isAdmin: true }}
                        perPage={10}
                        bulkActionButtons={false}
                        actions={null}
                    >
                        <TabbedDataGrid
                            permissions={permissions}
                        />
                    </List>
                </Fragment>
            )}
        </Fragment>
    );
};

export default UsersList;
