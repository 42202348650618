import React, { useState, Fragment } from 'react';
import {
    Card, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Dialog,
    DialogActions, DialogTitle, DialogContent, Button, DialogContentText, FormControlLabel, Switch, IconButton
} from '@material-ui/core';
import { useNotify } from 'react-admin';
import { useSelector } from 'react-redux';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { darkTheme, lightTheme } from "../layout/themes";
import { makeStyles } from '@material-ui/core/styles';
import config from '../../config/config';
import DiagnosticsIcon from '@material-ui/icons/TimelineOutlined';
import momentz from 'moment-timezone';
import moment from 'moment';
import StatusChangeDialog from './StatusChangeDialog';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';

import Icon from '@material-ui/core/Icon';

import LongtailedLocker from '../../assets/icons/long-bicycle.svg'
import StandardLocker from '../../assets/icons/bicycle.svg'
import CargoLocker from '../../assets/icons/cargo.svg'
import EditIcon from '@material-ui/icons/Edit';

import PublicIcon from '@material-ui/icons/Public';
import PersonIcon from '@material-ui/icons/Person';


function getLockTypeName(lockType) {
    switch (lockType) {
        case 0:
            return "NOT Supported"
        case 1:
            return "PLC";
        case 2:
            return "NB Bikely";
        case 3:
            return "BLE Bikely";
    }
    return "Unknown";
}
const LocationsExpand = props => {
    const notify = useNotify();
    const theme = useSelector(state =>
        state.theme === "dark" ? darkTheme.palette : lightTheme.palette
    );
    const [promptOpen, setPromptOpen] = useState(false);
    const [doorId, setDoorId] = useState(null);
    const { permissions } = props;
    const hasAccess = (permissions === 'admin');

    const useStyles = makeStyles({
        root: {
            marginTop: 0,
            width: '100%',
            height: '100%',
            background: '#EFEFEF'

        },
        online: { color: '#80C783' },
        offline: { color: theme.errorRed },
        problem: { color: '#FE9878' },
        tableRow: { backgroundColor: '#F8F9FA', padding: 0 },
        statusSwitch: {
            '& .MuiTypography-body1': { fontSize: '0.875rem' }
        },
        statusField: { display: 'flex', alignItems: 'center', width: 'max-content' },
        deviceStatus: { borderRadius: '50%', width: '26px', height: '26px' },
        signalField: { height: '28px', width: '106px', textAlign: 'center', borderRadius: '4px', lineHeight: '2' }
    });

    const classes = useStyles();

    const onUnlockDoor = (id) => {
        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Door/AdminUnlock?id=${id}`;
        const request = new Request(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                const result = response.result;
                if (result?.success) {
                    return notify('Door unlocked', 'success');
                } else {
                    notify(`Error: ${response.error.message}`, 'warning');
                }
            })
            .catch(error => {
                notify(`Error: ${error.message}`, 'warning');
            });
    };

    const AlertDialog = ({ isOpen }) => {
        const handleClose = (id) => {
            if (typeof id === 'number') {
                onUnlockDoor(id);
                setDoorId(null);
            }
            setPromptOpen(false);
        };

        return (
            <Dialog
                open={isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Unlock the door"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to unlock this door?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} name="cancel" color="secondary">
                        Cancel
                    </Button>
                    <Button name="confirm" onClick={() => handleClose(doorId)} color="primary">
                        Unlock
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    if (props.record.doors.length === 0) {
        return <div style={{ padding: '20px' }}>No doors added to this location.</div>
    }

    const StatusSwitch = ({ record }) => {
        const { id, status } = record;
        const [active, setActive] = useState(status);
        const [dialogOpen, setDialogOpen] = useState(false);
        const [reason, setReason] = useState('');


        const handleStatusChange = () => {
            const token = localStorage.getItem('access_token');
            const endpoint = `${config.apiBaseUrl}/Door/Activate`;

            const request = new Request(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ id, description: reason, isActive: !active })
            });

            fetch(request)
                .then(res => res.json())
                .then(response => {
                    if (response.success) {
                        setActive(!active)
                        notify('Status successfully changed', 'success');
                    } else {
                        notify(response.error.message, 'error');
                    }
                    setDialogOpen(false);
                });
        };

        return (
            <>
                <FormControlLabel
                    className={classes.statusSwitch}
                    control={
                        <Switch
                            checked={active}
                            onChange={(e, val) => setDialogOpen(true)}
                            color="primary"
                        />
                    }
                    label={active ? 'Enabled' : 'Disabled'}
                />
                <StatusChangeDialog
                    open={dialogOpen}
                    title='Are you sure you want to change door status?'
                    setOpen={(val) => setDialogOpen(val)}
                    onChangeStatus={handleStatusChange}
                >
                    <TextField
                        label="Reason"
                        variant="outlined"
                        multiline
                        value={reason}
                        rows={4}
                        style={{ width: '100%', marginBottom: 20 }}
                        onChange={e => setReason(e.target.value)}
                    />
                </StatusChangeDialog>
            </>
        )
    };

    const DeviceStatus = ({ record = {} }) => {
        const classes = useStyles();

        const { lockType, deviceStatus, lastHeartBeat } = record;
        if (lockType === 1) return <span>-</span>; // don't show status if lockType is PLC

        const statusColor = {
            'ONLINE': '#34bfa3',
            'OFFLINE': '#b9b9b9'
        };

        const timezone = momentz.tz.guess();
        const time = moment.tz(lastHeartBeat, timezone).format('MMMM Do YYYY, HH:mm:ss');

        return (
            <Fragment>
                <Tooltip title={`LAST HEARTBEAT ${time}`} placement="right">
                    <div className={classes.statusField}>
                        <div style={{ backgroundColor: `${statusColor[deviceStatus]}` }} className={classes.deviceStatus}></div>
                        <span style={{ marginLeft: '5px' }}>{deviceStatus === 'ONLINE' ? 'Online' : 'Offline'}</span>
                    </div>
                </Tooltip>
            </Fragment>
        );
    };

    const SignalStrength = ({ record = {} }) => {
        const classes = useStyles();

        const { lockType, deviceSignalStrengh } = record;
        if (lockType === 1) return <span>-</span>; // don't show signal strength if lockType is PLC

        const statusColor = {
            'EXCELLENT': '#34bfa3',
            'FAIR': '#14c5dc',
            'POOR': '#db574d',
            'UNKNOWN': '#eaeaea'
        };

        return (
            <div style={{ backgroundColor: statusColor[deviceSignalStrengh], color: deviceSignalStrengh === 'UNKNOWN' ? '#5c5b61' : 'white' }} className={classes.signalField}>
                {deviceSignalStrengh}
            </div>
        );
    };

    const LocalAccessKey = ({ record = {} }) => {
        const { localAccessKey, localAccessKeyName, lockType } = record;
        if (lockType === 1) return <span>PLC</span>;
        return <span>{`${localAccessKeyName} - ${localAccessKey}`}</span>
    };

    const SlotOrderStatus = ({ record = {} }) => {
        const { currentOrderId, subscriptionUserId } = record;

        if (subscriptionUserId !== 0) {
            return <a href={`/#/users/${subscriptionUserId}`} >Subscribed to {`${subscriptionUserId}`}</a>
        }

        if (currentOrderId === 0) {
            return <span>Locker is empty</span>
        }
        return <a href={`/#/orders/${currentOrderId}/show`} >Order {`${currentOrderId}`}</a>
    }

    const DiagnosticsButton = (val) => {
        const { doorId } = val;
        const history = useHistory();
        return (
            <DiagnosticsIcon
                style={{
                    color: 'rgba(51, 51, 52, 0.87)',
                    cursor: 'pointer'
                }}
                onClick={() => history.push(`/diagnostic/${doorId}/show`)}
            />
        )
    };

    const EditDoorLink = ({ record = {} }) => {
        const history = useHistory();
        const goToLocation = () => {
            history.push(`/doors/${record.id}`)
        }

        return (
            <Tooltip title={"Edit door"}>
                <IconButton>
                    <EditIcon onClick={goToLocation} />
                </IconButton>
            </Tooltip>
        );
    };

    const getDoorTypeIcon = (doorType) => {
        if (doorType === 0) {
            return <Tooltip title="Cargo">
                <Icon>
                    <img src={CargoLocker} style={{ maxWidth: 24 }} />
                </Icon>
            </Tooltip>
        }
        if (doorType === 1) {
            return <Tooltip title="Standard">
                <Icon>
                    <img src={StandardLocker} style={{ maxWidth: 24 }} />
                </Icon>
            </Tooltip>
        }
        if (doorType === 2) {
            return <Tooltip title="Long tailed">
                <Icon>
                    <img src={LongtailedLocker} style={{ maxWidth: 24 }} />
                </Icon>
            </Tooltip>
        }
        if (doorType === 3) {
            return <Tooltip title="Family">
                <Icon>
                    <img src={LongtailedLocker} style={{ maxWidth: 24 }} />
                </Icon>
            </Tooltip>
        }
        if (doorType === 4) {
            return <Tooltip title="E-Rack">
                <Icon>
                    <img src={LongtailedLocker} style={{ maxWidth: 24 }} />
                </Icon>
            </Tooltip>
        }


    }

    const StatusField = ({ record = {} }) => {
        const { status } = record;
        return (
            <span>
                {status ? 'Enabled' : 'Disabled'}
            </span>
        );
    };
    console.log(props.record.doors);
    return (
        <Fragment>
            <Card className={classes.root}>
                <Table size="medium">
                    <TableHead>
                        <TableRow>
                            <TableCell>{'ID'}</TableCell>
                            <TableCell>{'Availibity'}</TableCell>
                            <TableCell>{'Type'}</TableCell>
                            <TableCell>{'Name'}</TableCell>
                            <TableCell>{'Status'}</TableCell>
                            <TableCell>{'Device Status'}</TableCell>
                            <TableCell>{'Signal'}</TableCell>
                            <TableCell>{'Battery'}</TableCell>
                            <TableCell>{'Note'}</TableCell>
                            <TableCell>{'Local Access Key'}</TableCell>
                            <TableCell>{'Order'}</TableCell>
                            {hasAccess && <TableCell>{'Unlock'}</TableCell>}
                            {permissions === 'admin' && <TableCell>{'Actions'}</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.record.doors.map(door =>
                            <TableRow
                                key={door.id}
                            >
                                <TableCell>{door.id}</TableCell>
                                <TableCell>
                                    <>
                                        {getDoorTypeIcon(door.doorType)}

                                        <Icon>
                                            {door.subscriptionUserId === 0 ?
                                                <Tooltip title="Available For all users">
                                                    <PublicIcon style={{ color: '#5353b5' }} />
                                                </Tooltip> :
                                                <Tooltip title="Has subscription">
                                                    <PersonIcon style={{ color: '#7b7c2f' }} />
                                                </Tooltip>}
                                        </Icon>
                                    </>

                                </TableCell>
                                <TableCell>{getLockTypeName(door.lockType)}</TableCell>
                                <TableCell>{door.name}</TableCell>
                                <TableCell>
                                    {hasAccess ? (
                                        <StatusSwitch record={door} />
                                    ) : (
                                        <StatusField record={door} />
                                    )}
                                </TableCell>
                                <TableCell>
                                    <DeviceStatus record={door} />
                                </TableCell>
                                <TableCell>
                                    <SignalStrength record={door} />
                                </TableCell>
                                <TableCell>
                                    {`${door.deviceBatteryLevel}%`}
                                </TableCell>
                                <TableCell>{door.description}</TableCell>
                                <TableCell>
                                    <LocalAccessKey record={door} />
                                </TableCell>
                                <TableCell>
                                    <SlotOrderStatus record={door} />
                                </TableCell>
                                {/* {hasAccess && ( */}
                                <TableCell>
                                    <Tooltip title="Unlock door">
                                        <IconButton>
                                            <LockOpenIcon style={{ color: 'rgba(51, 51, 52, 0.87)', cursor: 'pointer' }} onClick={() => { setPromptOpen(true); setDoorId(door.id) }} />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                {/* )} */}
                                {permissions === 'admin' &&
                                    <TableCell>
                                        <Tooltip title="Check door diagnostics">
                                            <IconButton>
                                                <DiagnosticsButton doorId={door.id} />
                                            </IconButton>
                                        </Tooltip>
                                        <EditDoorLink record={door} />
                                    </TableCell>
                                }
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Card>
            <AlertDialog isOpen={promptOpen} />
        </Fragment>
    );
};
export default LocationsExpand;
