import React, { Fragment } from "react";
import { useSelector } from 'react-redux';

import { TextInput, email, required } from "react-admin";
import { darkTheme, lightTheme } from "../../../../layout/themes";

import { makeStyles } from "@material-ui/core/styles";
import FormLabel from '@material-ui/core/FormLabel';

export default function CreateAdministratorDetails (props) {
    const labelColor = useSelector(state =>
        state.theme === "dark" ?
            darkTheme.palette.iconsColor
            : lightTheme.palette.iconsColor
    );

    const styles = {
        formLabel: { marginTop: 20, marginBottom: 10, fontWeight: 700, color: labelColor },
        flexContainer: { width: '100%' },
        select: { maxWidth: '35%' },
        row: { display: 'flex', marginBottom: 40, width: '100%' }
    };

    const useStyles = makeStyles(styles);
    const classes = useStyles();

    const validateEmail = [email(), required()];

    return (
        <Fragment>
            <FormLabel component="legend" className={classes.formLabel}>Details</FormLabel>
            <div className={classes.flexContainer}>
                <div className={classes.row}>
                    <TextInput
                        source="user.userName"
                        label="Username"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        validate={required()}
                        style={{ marginRight: '100px' }}
                    />
                    <TextInput
                        source="user.emailAddress"
                        label="Email Address"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        validate={validateEmail}
                    />
                </div>
                <div className={classes.row}>
                    <TextInput
                        source="user.name"
                        label="Name"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        validate={required()}
                        style={{ marginRight: '100px' }}
                    />
                    <TextInput
                        source="user.surname"
                        label="Last Name"
                        fullWidth={true}
                        variant="standard"
                        className={classes.select}
                        validate={required()}
                    />
                </div>
            </div>
        </Fragment>
    );
};
