import React, { useState, useEffect, Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useNotify, useQuery, Pagination } from 'react-admin';

import { Card, TextField, Table, TableBody, TableCell, TableHead, TableRow, Button } from '@material-ui/core';
import config from '../../config/config';

import styles from './styles.scss';

const EventsPage = () => {
    const [loader1, setLoader1] = useState(true);
    const [loader2, setLoader2] = useState(true);

    const [eventsStructureData, setEventsStructureData] = useState({});
    const [totalResults, setTotalResults] = useState(0);

    const [locationEventsStructureData, setLocationEventsStructureData] = useState({});
    const [totalLocationResults, setTotalLocationResults] = useState(0);


    const [locationIdSearch, setLocationIdSearch] = useState('');
    const [doorIdSearch, setDoorIdSearch] = useState('');
    const [page,setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);

    const renderEvents = () => {
        const token = localStorage.getItem('access_token');

        (() => {
            setLoader1(true);
            const endpoint = `${config.apiBaseUrl}/Dashboard/GetEvents`;
            const request = new Request(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    page: page,
                    pageSize: perPage,
                    // from: filter.startDate,
                    // to: filter.endDate,
                    Location: locationIdSearch,
                    Door: doorIdSearch
                    // period: filter.period,
                    // clientId: filter.clientId,
                    // timeZone: momentz.tz.guess()
                })
            });

            fetch(request)
                .then(res => res.json())
                .then(response => {
                    const result = response.result;
                    setEventsStructureData({ ...eventsStructureData, data: result.items, noData: false });
                    setTotalResults(result.totalCount);
                    setLoader1(false);
                });
        })();

        (() => {
            setLoader2(true);
            const endpoint = `${config.apiBaseUrl}/Dashboard/GetLocationEvents`;
            const request = new Request(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    page: page,
                    pageSize: perPage,
                    // from: filter.startDate,
                    // to: filter.endDate,
                    Location: locationIdSearch,
                    // period: filter.period,
                    // clientId: filter.clientId,
                    // timeZone: momentz.tz.guess()
                })
            });

            fetch(request)
                .then(res => res.json())
                .then(response => {
                    const result = response.result;
                    console.log('-------->', result)
                    setLocationEventsStructureData({ ...eventsStructureData, data: result.items, noData: false });
                    setTotalLocationResults(result.totalCount);
                    setLoader2(false);
                });
        })();
    }

    useEffect(() => {
        renderEvents();
    }, [])

    const getDoorStatus = (doorEntity) => {
        let status = 'Unchenged';
        const statusChangeProp = doorEntity.propertyChanges.find(d => d.propertyName === "Status");
        if (statusChangeProp) {
            return statusChangeProp.newValue === "true" ? "Enabled" : "Disabled";
        }
        return status;
    }

    const getPropertyName = (locationEntity) => {
        
        for(let i=0; i < locationEntity.propertyChanges.length; i++) {
            const _pc = locationEntity.propertyChanges[i];
            if(_pc.propertyName === "IsActive") {
                return "Active"
            }
            if(_pc.propertyName === "AccessControlType") {
                return "Access Location Type"
            }
        }
        return "";
    }

    const getOldValue = (locationEntity) => {
        
        for(let i=0; i < locationEntity.propertyChanges.length; i++) {
            const _pc = locationEntity.propertyChanges[i];
            if(_pc.propertyName === "IsActive") {
                return _pc.originalValue === "true" ? "Activated" : "Deactivated";
            }
            if(_pc.propertyName === "AccessControlType") {
                return _pc.originalValue === "0" ? "Public" : "Private";
            }
        }
        return "";
    }

    const getNewValue = (locationEntity) => {
        
        for(let i=0; i < locationEntity.propertyChanges.length; i++) {
            const _pc = locationEntity.propertyChanges[i];
            if(_pc.propertyName === "IsActive") {
                return _pc.newValue === "true" ? "Activated" : "Deactivated";
            }
            if(_pc.propertyName === "AccessControlType") {
                return _pc.newValue === "0" ? "Public" : "Private";
            }
        }
        return "";
    }

    

    const getReason = (doorEntity) => {
        let status = 'N/A';
        const statusChangeProp = doorEntity.propertyChanges.find(d => d.propertyName === "Description");
        if (statusChangeProp) {
            return statusChangeProp.newValue;
        }
        return status;
    }

    const searchLocation = (location, door) => {
        renderEvents(location, door);
    }

    const locationEventListTemplate = () => {
        if (locationEventsStructureData.noData) {
            return (<div className={'loaderWrapper'}>
                <div className={'loader'}>No data</div>
            </div>)
        }
        return (
            <>
                <Table sx={{ minWidth: 650 }} size='small'>
                    <TableHead sx={{ fontSize: 24, backgroundColor: '#000500' }}>
                        <TableRow >
                            <TableCell sx={{ fontSize: 24 }} >Location Id</TableCell>
                            <TableCell sx={{ fontSize: 24 }} >Location</TableCell>
                            <TableCell sx={{ fontSize: 24 }} >Property</TableCell>
                            <TableCell sx={{ fontSize: 24 }} >From</TableCell>
                            <TableCell sx={{ fontSize: 24 }} >To</TableCell>
                            <TableCell sx={{ fontSize: 24 }}> By User</TableCell>
                            <TableCell sx={{ fontSize: 24 }} >Timestamp</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {locationEventsStructureData.data.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell >{row.locationId}</TableCell>
                                <TableCell >{row.locationName}</TableCell>
                                <TableCell >{getPropertyName(row.entity)}</TableCell>
                                <TableCell >{getOldValue(row.entity)}</TableCell>
                                <TableCell >{getNewValue(row.entity)}</TableCell>
                                <TableCell component="th" scope="row">
                                    {row.userName}
                                </TableCell>
                                <TableCell >{(new Date(row.entity.changeTime)).toLocaleString()}</TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Pagination
                    page={page}
                    perPage={perPage}
                    setPage={(s) => { setPage(s); renderEvents();}}
                    setPerPage={(s) => { setPage(1); setPerPage(s); renderEvents(); }}
                    total={totalResults}
                />
            </>
        )
    }

    const eventListTemplate = () => {
        if (eventsStructureData.noData) {
            return (<div className={'loaderWrapper'}>
                <div className={'loader'}>No data</div>
            </div>)
        }
        return (
            <>
                <Table sx={{ minWidth: 650 }} size='small'>
                    <TableHead sx={{ fontSize: 24, backgroundColor: '#000500' }}>
                        <TableRow >
                            <TableCell sx={{ fontSize: 24 }} >Location Id</TableCell>
                            <TableCell sx={{ fontSize: 24 }} >Location</TableCell>
                            <TableCell sx={{ fontSize: 24 }} >Door Id</TableCell>
                            <TableCell sx={{ fontSize: 24 }} >Door Name</TableCell>
                            <TableCell sx={{ fontSize: 24 }} >Status</TableCell>
                            <TableCell sx={{ fontSize: 24 }} >Comment</TableCell>
                            <TableCell sx={{ fontSize: 24 }}> By User</TableCell>
                            <TableCell sx={{ fontSize: 24 }} >Timestamp</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {eventsStructureData.data.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell >{row.locationId}</TableCell>
                                <TableCell >{row.locationName}</TableCell>
                                <TableCell >{row.entity.entityId}</TableCell>
                                <TableCell >{row.doorName}</TableCell>
                                <TableCell >{getDoorStatus(row.entity)}</TableCell>
                                <TableCell >{getReason(row.entity)}</TableCell>
                                <TableCell component="th" scope="row">
                                    {row.userName}
                                </TableCell>
                                <TableCell >{(new Date(row.entity.changeTime)).toLocaleString()}</TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Pagination
                    page={page}
                    perPage={perPage}
                    setPage={(s) => { setPage(s); renderEvents();}}
                    setPerPage={(s) => { setPage(1); setPerPage(s); renderEvents(); }}
                    total={totalResults}
                />
            </>
        )
    }
    return (
        <div className='container'>
            <div className='charts-container'>
                <div className='main-charts'>
                    <Card variant="outlined" className={'door-events-card'}>
                        <div style={{ display: 'flex', gap: '10px', marginBottom: 10, marginTop: 10, margin: 10 }}>
                            <TextField
                                id="location_search"
                                label="Location"
                                variant="outlined"
                                className={styles.search}
                                onChange={(e) => {
                                    setLocationIdSearch(e.target.value)
                                }}
                            />
                            <TextField
                                id="door_search"
                                label="Door"
                                variant="outlined"
                                className={styles.search}
                                onChange={(e) => {
                                    setDoorIdSearch(e.target.value)
                                }}
                            />
                            <Button className={styles.button} variant="contained" color="primary" onClick={() => {
                                searchLocation(locationIdSearch, doorIdSearch)
                            }}>Search</Button>
                        </div>
                        <span className='card-header'>Door Events</span>
                        {loader1 ? (
                            <div className={'loaderWrapper'}>
                                <CircularProgress className={'loader'} />
                            </div>
                        ) :
                            eventListTemplate()
                        }
                        <span className='card-header'>Location Events</span>
                        {loader2 ? (
                            <div className={'loaderWrapper'}>
                                <CircularProgress className={'loader'} />
                            </div>
                        ) :
                            locationEventListTemplate()
                        }
                    </Card>
                </div>
            </div>
        </div>

    );
}

export default EventsPage;