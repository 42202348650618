import { makeStyles } from "@material-ui/core/styles";
import RichTextInput from 'ra-input-rich-text';
import React, { Fragment, useState } from "react";
import { Edit, FormDataConsumer, ImageField, ImageInput, NotFound, required, SimpleForm, TextInput, BooleanInput } from "react-admin";
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import CustomLabel from '../shared/CustomLabel';
import EditDateTimeInput from "./components/EditDateTimeInput";
import EditToolbar from './components/EditToolbar';
import ShortDescriptionInput from "./components/ShortDescriptionInput";


const useStyles = makeStyles({
    container: { paddingBottom: 150 },
    richTextInput: { '& .ql-editor': { minHeight: 150 } },
    imageInput: { maxWidth: 200 },
    isPublished: { marginTop: 30 }
});

const ImagePreview = ({ record, source }) => {
    if (typeof (record) === "string") {
        record = {
            [source]: record
        }
    }
    return <ImageField record={record} source={source} />
};

const AnnouncementsEdit = props => {
    const classes = useStyles();
    const permissions = useSelector(state =>
        state.permissions
    );
    const { params: { id } } = useRouteMatch();
    const announcement = useSelector(state => state.admin.resources.announcements.data[id]);
    const [scheduledTime, setScheduledTime] = useState(new Date());

    return (
        <Fragment>
            {permissions === 'admin' ? (
                <div className={classes.container}>
                    <CustomLabel title={`Announcement "${announcement?.title}"`} />
                    <Edit {...props}>
                        <SimpleForm
                            toolbar={<EditToolbar scheduledTime={scheduledTime} />}
                        >
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    <>
                                        <ImageInput
                                            source="image"
                                            label="Image"
                                            placeholder={formData.image ? 'Change image' : 'Drop a picture to upload, or click to select it.'}
                                            className={classes.imageInput}
                                            accept="image/png, image/jpeg"
                                        >
                                            <ImagePreview />
                                        </ImageInput>
                                    </>
                                }
                            </FormDataConsumer>
                            <TextInput
                                source="title"
                                variant="standard"
                                validate={required()}
                            />
                            <ShortDescriptionInput />
                            <RichTextInput
                                source="content"
                                variant={classes.richTextInput}
                                className={classes.richTextInput}
                                fullWidth={true}
                                validate={required()}
                                toolbar={[
                                    [{ 'size': [] }],
                                    ['bold', 'italic', 'underline'],
                                    [{ 'script': 'super' }, { 'script': 'sub' }],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    ['link'], //'image'
                                    ['clean']
                                ]}
                            />
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    <>
                                        <BooleanInput source="isScheduled" label="Schedule" disabled={!formData.isPublished} />
                                        <EditDateTimeInput isScheduled={formData.isScheduled} isPublished={formData.isPublished} {...rest} callback={val => setScheduledTime(val)} />
                                    </>
                                }
                            </FormDataConsumer>
                            <BooleanInput source="isPublished" label="Publish" className={classes.isPublished} />
                        </SimpleForm>
                    </Edit>
                </div>
            ) : <NotFound />}
        </Fragment>
    );
};

export default AnnouncementsEdit;