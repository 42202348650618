import React, { Fragment } from "react";
import { useSelector } from 'react-redux';
import { Create, FormTab, TabbedForm, NotFound, SaveButton, Toolbar } from "react-admin";
import { darkTheme, lightTheme } from "../../layout/themes";
import CustomLabel from '../../shared/CustomLabel';
import CustomButton from '../../shared/CustomButton';

import ClientsDetailsTab from './clients-tabs/ClientsDetailsTab';

import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";

const styles = {
    createToolbar: { display: 'flex', justifyContent: 'flex-end' }
};

const useStyles = makeStyles(styles);

export default function ClientsCreate(props) {
    const labelColor = useSelector(state =>
        state.theme === "dark" ?
            darkTheme.palette.iconsColor
            : lightTheme.palette.iconsColor
    );

    const permissions = useSelector(state =>
        state.permissions
    );
    
    const redirect = (basePath, id, data) => {
        // after save redirect to client edit - properties tab
        return `/clients/${id}/properties`;
    };

    const CreateToolbar = ({ basePath, ...props }) => {
        const classes = useStyles();
        const history = useHistory();

        const onCancel = (e) => {
            e.preventDefault();
            history.push('/clients');
        };

        return (
            <div>
                <Toolbar {...props} className={classes.createToolbar}>
                    <CustomButton type="default" label="Cancel" onClick={onCancel} style={{ marginTop: '30px', marginRight: '20px' }}/>
                    <SaveButton basePath={basePath} />
                </Toolbar>
            </div>
        );
    };

    return (
        <Fragment>
            {permissions === 'admin' ? (
                <Fragment>
                    <CustomLabel title={'New Client'} />
                    <Create {...props}>
                        <TabbedForm redirect={redirect} toolbar={<CreateToolbar />}>
                            <FormTab label="Details">
                                <ClientsDetailsTab labelColor={labelColor} />
                            </FormTab>
                            <FormTab label="Administrators" disabled />
                        </TabbedForm>
                    </Create>
                </Fragment>
            ) : <NotFound />}
        </Fragment>
    );
};
