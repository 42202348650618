import React from 'react';
import { Route } from 'react-router-dom';
import Settings from './settings/Settings';
import ClientInfo from './components/clients/client/ClientInfo';
import InvitePage from './components/anonimous/InvitePage';
import AdminConfiguration from './components/admin-configuration/AdminConfiguration';
import ResetPassword from './components/password-reset/ResetPassword';
import TermsAndConditionsForm from './components/termsAndConditions/TermsAndConditions';
import SupportOrderList from './components/orders/SupportOrderList';
import EventsPage from './components/dashboard/EventsPage';

export default [
    <Route exact path="/events" render={()=> <EventsPage/> }/>,
    <Route exact path="/support" render={()=> <SupportOrderList />} />,
    <Route exact path="/configuration" render={() => <AdminConfiguration />} />,
    <Route exact path="/settings" render={() => <Settings />} />,
    <Route exact path="/client-info" render={() => <ClientInfo />} />,
    <Route path="/invite" noLayout render={() => <InvitePage />} />,
    <Route path="/reset-password" noLayout render={() => <ResetPassword />} />,
    <Route exact path="/terms-and-conditions" render={() => <TermsAndConditionsForm />} />,
];