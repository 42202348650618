import React, { Fragment, useState, useEffect } from 'react';
import { Edit, TabbedForm, FormTab, useNotify, NotFound } from 'react-admin';

import config from '../../config/config';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import { useRouteMatch, useHistory } from 'react-router-dom';

import CustomTitle from '../shared/CustomTitle';
import CustomButton from '../shared/CustomButton';
import LocationsEditDetailsTab from './locations-tabs/LocationsEditDetailsTab';
import LocationsDoorsTab from './locations-tabs/LocationsDoorsTab';
import LocationsAccessControlTab from './locations-tabs/LocationsAccessControlTab';
import LocationsPricingTab from './locations-tabs/LocationsPricingTab';
import LocationsOrdersTab from './locations-tabs/LocationsOrdersTab';

import { makeStyles } from '@material-ui/core';

const styles = {
    saveBtn: {
        display: 'flex', justifyContent: 'flex-end', margin: '20px 16px 20px 0', '& button': {
            marginLeft: '20px !important'
        }
    },
    editHeader: { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }
};

const useStyles = makeStyles(styles);

let openFrom, openTo, allDay, groupIds = [];

const CustomSaveBtn = ({ changeLocationName, ...props }) => {
    const classes = useStyles();

    const { activeTab } = props;
    const notify = useNotify();
    const history = useHistory();

    const maintenance = useSelector(state =>
        state.maintenance
    );

    const formState = useFormState();
    const { valid } = formState;

    const locationMap = useSelector(state => state.locationMap);

    const onCancel = (e) => {
        e.preventDefault();
        history.push('/locations');
    };

    const getTabData = (activeTab) => {
        const { id } = formState.values;
        const formData = formState.values;

        let data = {};

        switch (activeTab) {
            case 'details':
                const { name, description, locationType, countryId, addressType, street, municipality, postCode, postName } = formData;
                let latitude, longitude;
                if (locationMap.latitude) {
                    latitude = locationMap.latitude;
                    longitude = locationMap.longitude;
                } else if (formData.latitude) {
                    latitude = formData.latitude;
                    longitude = formData.longitude;
                }

                data = { name, description, locationType, countryId, addressType, street, municipality, postCode, postName, latitude, longitude, id };
                break
            case 'access-control':
                const { accessControlType, clientId, isActive } = formData;
                data = { accessControlType, clientId, isActive, openFrom, openTo, allDay, groupIds, id };
                break
            case 'pricing':
                const { startPriceAmount, startPriceDuration, mainPriceAmount, MaxDailyAmount } = formData;
                data = { startPriceAmount, startPriceDuration, mainPriceAmount, MaxDailyAmount, id };
                break
            default:
                return;
        }

        return data;
    };

    const save = () => {
        if (!valid && activeTab.name === 'details') {
            return notify('Form is not valid. Please check for errors', 'warning');
        }

        const token = localStorage.getItem('access_token');

        if (activeTab.name === 'access-control') {
            const maintenanceEndpoint = `${config.apiBaseUrl}/Location/SetMaintenance`;

            const request = new Request(maintenanceEndpoint, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(maintenance)
            });

            fetch(request)
                .then(res => res.json())
                .then(response => {
                    return;
                });
        }

        const data = getTabData(activeTab.name);

        const endpoint = `${config.apiBaseUrl}/Location/${activeTab.method}`;
        const request = new Request(endpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                    if (activeTab.name === 'details') {
                        changeLocationName(data.name);
                    }
                    notify('Saved successfully');
                }

                if (response && response.error) {
                    notify(`Error: ${response.error.message}`, 'warning');
                }
            })
            .catch(error => {
                notify(`Error: ${error.message}`, 'warning');
            });
    };

    return (
        <div>
            <div className={classes.saveBtn}>
                <CustomButton type="default" label="Cancel" onClick={onCancel} />
                <CustomButton type="save" label="Save" fullWidth={true} onClick={save} />
            </div>
        </div>
    );
};

const getTimePickerData = props => {
    if (props.openFrom) openFrom = props.openFrom;
    if (props.openTo) openTo = props.openTo;
    if (props.allDay !== undefined) allDay = props.allDay;
};

const getGroups = ({ groups }) => {
    groupIds = groups;
};

const LocationEdit = (props) => {
    const classes = useStyles();
    const permissions = useSelector(state =>
        state.permissions
    );

    const pathname = useSelector(state =>
        state.router.location.pathname
    );

    const activeTab = () => {
        if (pathname.includes('/pricing')) return { name: 'pricing', method: 'saveLocationPrice' };
        if (pathname.includes('/access-control')) return { name: 'access-control', method: 'saveAccessControl' };
        if (pathname.includes('/doors')) return { name: 'doors', method: 'Update' };
        if (pathname.includes('/orders')) return { name: 'orders', method: 'Update' };
        return { name: 'details', method: 'Update' };
    };

    const { params: { id } } = useRouteMatch();

    const location = useSelector(state => state.admin.resources.locations.data[id]);
    const [name, setName] = useState(location ? location.name : '');

    const hasAccess = (permissions === 'admin');

    useEffect(() => {
        setName(location ? location.name : '');
    }, [location]);

    const changeName = (newVal) => {
        setName(newVal);
    };

    return (
        <>
            {permissions === 'admin' ? (
                <Fragment>
                    <div className={classes.editHeader}>
                        <CustomTitle title={`Edit Location "${name}"`} />
                    </div>
                    <Edit undoable={false} {...props}>
                        <TabbedForm
                            toolbar={
                                (((permissions === 'admin') && activeTab().name !== 'doors' && activeTab().name !== 'pricing' && activeTab().name !== 'orders')
                                    || ((permissions === 'client') && activeTab().name === 'access-control')
                                ) ? (
                                    <CustomSaveBtn activeTab={activeTab()} changeLocationName={changeName} />
                                ) : null
                            }
                        >
                            <FormTab label="Details">
                                <LocationsEditDetailsTab
                                    id={id}
                                    permissions={permissions}
                                    hasAccess={hasAccess}
                                    viewAccess={true}
                                />
                            </FormTab>

                            <FormTab label="Access Control" path="access-control">
                                <LocationsAccessControlTab
                                    callback={getTimePickerData}
                                    getGroups={getGroups}
                                    permissions={permissions}
                                    readOnly={false}
                                    canUpdateStatus={hasAccess}
                                />
                            </FormTab>

                            <FormTab label="Pricing" path="pricing">
                                <LocationsPricingTab
                                    permissions={permissions}
                                    readOnly={false}
                                />
                            </FormTab>

                            <FormTab label="Doors" path="doors">
                                <LocationsDoorsTab
                                    permissions={permissions}
                                    readOnly={false}
                                />
                            </FormTab>

                            <FormTab label="Orders" path="orders">
                                <LocationsOrdersTab permissions={permissions} activeTab={activeTab()} />
                            </FormTab>
                        </TabbedForm>
                    </Edit>
                </Fragment>
            ) : <NotFound />}
        </>
    );
};

export default LocationEdit;
