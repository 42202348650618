import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Card, TextField, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BarChart from '../charts/BarChart';
import config from '../../config/config';
import { darkTheme, lightTheme } from "../layout/themes";
import ClearIcon from '@material-ui/icons/Clear';
import dates from '../../utils/dates';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setChartData } from "../../utils/charts";
import momentz from 'moment-timezone';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../assets/react_dates_overrides.css';
import { DateRangePicker } from 'react-dates';
import CustomTabs from '../shared/CustomTabs';
import { Loading, useAuthState } from 'react-admin';
import styles from './styles.scss';
import { Bar } from "react-chartjs-2";
import SupportOrderList from '../orders/SupportOrderList';


const sortData = (data) => {
    data.sort((a, b) => {
        if (a.name < b.name) return -1;
        return a.name > b.name ? 1 : 0;
    });

    return data;
};

const Dashboard = () => {
    const { loading, authenticated } = useAuthState();
    const permissions = useSelector(state => state.permissions);
    const [permissionsLoaded, setPermissionsLoaded] = useState(false);

    useEffect(() => {
        if (permissions) {
            setPermissionsLoaded(true);
        }
    }, [permissions]);

    if (loading || !permissionsLoaded) {
        return <Loading />;
    }
    if (authenticated && permissions) {
        if (permissions === 'support') {
            return <SupportOrderList />;
        }
        return <DashboardContent permissions={permissions} />;
    }
};

const DashboardContent = ({ permissions }) => {
    const clientId = useSelector(state => state.user.clientId);
    const theme = useSelector(state =>
        state.theme === "dark" ? darkTheme.palette : lightTheme.palette
    );


    const storeClientId = useSelector(state =>
        state.user.clientId
    );

    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState({ name: 'All' });
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState({ name: 'All' });
    const [clientsDisabled, setClientsDisabled] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const [previousTab, setPreviousTab] = useState(null);
    const [datePickerOpen, setDatePickerOpen] = useState(false);

    const [dateRange, setDateRange] = useState({
        startDate: moment(),
        endDate: null
    });
    const [focusedInput, setFocusedInput] = useState(null);
    const [showClearFilter, setShowClearFilter] = useState(false);
    const [resetClients, setResetClients] = useState(0);

    const [loader1, setLoader1] = useState(true);
    const [loader2, setLoader2] = useState(true);
    const [loader3, setLoader3] = useState(true);
    const [loader4, setLoader4] = useState(true);
    const [loader5, setLoader5] = useState(true);
    const [loader6, setLoader6] = useState(true);
    const [loader7, setLoader7] = useState(true);

    const [numOfOrders, setNumOfOrders] = useState({
        title: 'All orders history',
        horizontalBar: false,
        barWidth: 0.5,
        cornerRadius: 4,
        showXAxes: true,
        hideYAxes: false,
        barColor: '#9d9d9d',
        maxValueColor: '#60BC34',
        hideGrid: false,
        dataLabels: false
    });

    const [revenuePerClient, setRevenuePerClient] = useState({
        title: clientId ? 'Revenue' : 'Revenue per client',
    });

    const [orderLength, setOrderLength] = useState({
        title: 'Order length',
    });

    const [peakRentalHours, setPeakRentalHours] = useState({});
    const [userStructureData, setUserStructureData] = useState({});
    const [transactionData, setTransactionData] = useState({});
    const [topLocationsData, setTopLocationsData] = useState({ noData: true });

    const tabsData = [
        { label: 'Today' },
        { label: 'Yesterday' },
        { label: 'This week' },
        { label: 'Last week' },
        { label: 'This month' },
        { label: 'Last month' },
        { label: 'All time' },
        { label: 'Custom rangeee', hidden: true }
    ];

    const getClients = async () => {
        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Client/GetAll?skipCount=0&maxResultCount=1000`;
        const request = new Request(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        await fetch(request)
            .then(res => res.json())
            .then(response => {
                if (response && response.result) {
                    let data = response.result.items.map(client => {
                        return {
                            id: client.id,
                            name: client.name
                        }
                    });
                    data = sortData(data);
                    setClients([]); // quick fix
                    setClients([{ name: 'All' }, ...data]);
                }
            });
    };

    const getClient = async (clientId) => {
        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Client/Get?id=${clientId}`;
        const request = new Request(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        await fetch(request)
            .then(res => res.json())
            .then(response => {
                if (response && response.result) {
                    const { id, name } = response.result;

                    setClients([{ id, name }]);
                    setSelectedClient({ id, name });
                }
            });
    };

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (!token) return;

        (() => {
            let endpoint = `${config.apiBaseUrl}/Dashboard/GetAllLocations`;
            if (clientId) {
                endpoint = `${endpoint}?ClientId=${clientId}`;
            }

            const request = new Request(endpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            fetch(request)
                .then(res => res.json())
                .then(response => {
                    if (response && response.result) {
                        let data = response.result.map(el => {
                            return {
                                id: el.id,
                                name: el.name
                            }
                        });

                        data = sortData(data);
                        setLocations([{ name: 'All' }, ...data]);
                    }
                });
        })();

        if (permissions === 'admin') {
            getClients();
        } else {
            getClient(clientId);
        }

        let today = getToday();
        reRenderCharts(today);
        // eslint-disable-next-line
    }, []);

    const getToday = () => {
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        return {
            startDate: date.toISOString(),
            endDate: ''
        };
    };

    const activeTabCallback = (value) => {
        if (value === 7) {
            setPreviousTab(activeTab);
        } else {
            setPreviousTab(null);
        }

        setActiveTab(value);
        if (value !== 7) {
            const filter = getActiveFilter(value);
            reRenderCharts({ ...filter, locationId: selectedLocation.id, clientId: selectedClient.id });
        }
    }

    const getActiveFilter = value => {
        let filter = {};
        switch (value) {
            case 6:
                filter = dates.allTime;
                break;
            case 0:
                filter = dates.today;
                break;
            case 1:
                filter = dates.yesterday;
                break;
            case 2:
                filter = dates.thisWeek;
                break;
            case 3:
                filter = dates.lastWeek;
                break;
            case 4:
                filter = dates.thisMonth;
                break;
            case 5:
                filter = dates.lastMonth;
                break;

            case 7:
                const start = dateRange.startDate;
                start._d.setHours(0, 0, 0, 0);

                let end = dateRange.endDate;
                if (!end) end = moment();

                end._d.setHours(23, 59, 59, 999);

                filter = {
                    startDate: start._d.toISOString(),
                    endDate: end._d.toISOString()
                };
                break;

            default:
                filter = getToday();
                break;
        }
        return filter;
    };

    const reRenderCharts = (filter) => {
        if (!filter.locationId) filter.locationId = '';

        if (!filter.clientId && filter.clientId !== '') {
            if (selectedClient?.id) {
                filter.clientId = selectedClient.id;
            } else if (storeClientId) {
                filter.clientId = storeClientId;
            }
            else filter.clientId = '';
        }

        const token = localStorage.getItem('access_token');

        // numOfOrdersPerLocation
        (() => {
            setLoader1(true);
            const endpoint = `${config.apiBaseUrl}/Dashboard/OrderHistoryChart`;
            const request = new Request(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    from: filter.startDate,
                    to: filter.endDate,
                    period: filter.period,
                    locationId: filter.locationId,
                    clientId: filter.clientId,
                    timeZone: momentz.tz.guess()
                })
            });

            fetch(request)
                .then(res => res.json())
                .then(response => {
                    const result = response.result.chartData;
                    let chartData = setChartData(result);
                    let noData = true;

                    chartData.data.forEach(item => {
                        if (item > 0) noData = false;
                    });

                    if (!noData) {
                        if (chartData.labels.length < 5) {
                            let dataLabels = [];
                            for (let i = 0; i < 5; i++) {
                                if (chartData.labels[i]) {
                                    dataLabels[i] = chartData.labels[i];
                                } else {
                                    dataLabels[i] = '';
                                }
                            }
                            chartData.labels = dataLabels;
                        }
                    }
                    setNumOfOrders({ ...numOfOrders, ...chartData, noData });
                    setLoader1(false);
                }).catch(e => console.log(e));
        })();

        // orderLength
        (() => {
            setLoader3(true);
            const endpoint = `${config.apiBaseUrl}/Dashboard/GetOrderLength`;
            const request = new Request(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    from: filter.startDate,
                    to: filter.endDate,
                    locationId: filter.locationId,
                    period: filter.period,
                    clientId: filter.clientId,
                    timeZone: momentz.tz.guess()
                })
            });

            fetch(request)
                .then(res => res.json())
                .then(response => {
                    const result = response.result;
                    setOrderLength({ ...orderLength, data: result, noData: false });
                    setLoader3(false);
                });
        })();

        // userStructurePie
        (() => {
            setLoader4(true);
            const endpoint = `${config.apiBaseUrl}/Dashboard/GetUserStructure`;
            const request = new Request(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    from: filter.startDate,
                    to: filter.endDate,
                    locationId: filter.locationId,
                    period: filter.period,
                    clientId: filter.clientId,
                    timeZone: momentz.tz.guess()
                })
            });

            fetch(request)
                .then(res => res.json())
                .then(response => {
                    const result = response.result.chartData;
                    const labels = Object.keys(result);
                    const listData = [];
                    for (let m = 0; m < labels.length; m += 1) {
                        listData.push({ "name": labels[m], "value": result[labels[m]] })
                    }
                    setUserStructureData({ ...userStructureData, data: listData, noData: false });
                    setLoader4(false);
                });
        })();

        // peakRentalHours
        (() => {
            setLoader2(true);
            const endpoint = `${config.apiBaseUrl}/Dashboard/PeakRentalHours`;
            const request = new Request(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    from: filter.startDate,
                    to: filter.endDate,
                    locationId: filter.locationId,
                    period: filter.period,
                    clientId: filter.clientId,
                    timeZone: momentz.tz.guess()
                })
            });

            fetch(request)
                .then(res => res.json())
                .then(response => {
                    setPeakRentalHours({ ...peakRentalHours, data: response.result, noData: false });
                    setLoader2(false);
                });
        })();

        //GetRevenuePerClient
        (() => {
            setLoader5(true);
            const endpoint = `${config.apiBaseUrl}/Dashboard/GetRevenuePerClient`;
            const request = new Request(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    from: filter.startDate,
                    to: filter.endDate,
                    locationId: filter.locationId,
                    clientId: filter.clientId,
                    period: filter.period,
                    timeZone: momentz.tz.guess()
                })
            });

            fetch(request)
                .then(res => res.json())
                .then(response => {
                    const result = response.result.chartData;
                    setRevenuePerClient({ ...revenuePerClient, data: result, noData: false });
                    setLoader5(false);
                });
        })();

        //GetTransactions
        (() => {
            setLoader6(true);
            const endpoint = `${config.apiBaseUrl}/Dashboard/GetTransactions`;
            const request = new Request(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    from: filter.startDate,
                    to: filter.endDate,
                    locationId: filter.locationId,
                    clientId: filter.clientId,
                    period: filter.period,
                    timeZone: momentz.tz.guess()
                })
            });

            fetch(request)
                .then(res => res.json())
                .then(response => {
                    setTransactionData({ ...transactionData, data: response.result, noData: false });
                    setLoader6(false);
                }).catch(e => { console.log(e) });
        })();

        // GetTopLocations
        (() => {
            setLoader7(true);
            const endpoint = `${config.apiBaseUrl}/Dashboard/GetTopLocations`;
            const request = new Request(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    from: filter.startDate,
                    to: filter.endDate,
                    locationId: filter.locationId,
                    clientId: filter.clientId,
                    period: filter.period,
                    timeZone: momentz.tz.guess()
                })
            });

            fetch(request)
                .then(res => res.json())
                .then(response => {
                    const _tl = Object.keys(response.result.chartData).map((k, v) => { return { name: k, count: response.result.chartData[k] } });
                    console.log(_tl);

                    setTopLocationsData({ ...topLocationsData, data: _tl, noData: false });
                    setLoader7(false);
                });
        })();

    };

    // Handlers & Click Methods

    const handleDateChange = ({ startDate, endDate }) => {
        if (startDate && endDate) {
            startDate._d.setHours(0, 0, 0, 0);
            endDate._d.setHours(23, 59, 59, 999);
            const start = startDate._d.toISOString();
            const end = endDate._d.toISOString();
            reRenderCharts({ startDate: start, endDate: end, locationId: selectedLocation.id, clientId: selectedClient.id });
            setDatePickerOpen(false);
        }
    };


    const locationFilterChange = (e, value) => {
        let filter = getActiveFilter(activeTab);
        let location = value;

        if (location.name === 'All') {
            location.id = null;
        }

        setShowClearFilter(true);
        setSelectedLocation(value.name === 'All' ? { name: 'All' } : value);
        reRenderCharts({ ...filter, locationId: location.id, clientId: selectedClient.id });
    };

    const clientFilterChange = async (e, value) => {
        let filter = getActiveFilter(activeTab);
        let client = value;

        if (client.name === 'All') {
            client.id = '';
        }

        setShowClearFilter(true);
        setSelectedClient(value.name === 'All' ? { name: 'All' } : value);
        await getLocations(client.id);

        reRenderCharts({ ...filter, clientId: client.id });
    };


    const getLocations = async (clientIdx) => {
        const clientId = clientIdx || '';
        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Dashboard/GetAllLocations?ClientId=${clientId}`;

        const request = new Request(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        await fetch(request)
            .then(res => res.json())
            .then(response => {
                if (response && response.result) {
                    let data = response.result;
                    data = sortData(data);

                    setLocations([]); // quick fix
                    setLocations([{ name: "All" }, ...data]);
                    setSelectedLocation({ name: 'All' });
                }
            });
    };

    const onClearFilters = async () => {
        setShowClearFilter(false);

        await getLocations();
        setResetClients(resetClients + 1);
        setSelectedClient({ name: 'All' });

        const filter = getActiveFilter(activeTab);
        reRenderCharts({ ...filter, locationId: null, clientId: '' });
    };

    // Templates
    const numOfOrdersTemplate = () =>
        numOfOrders.noData ? (
            <div className={'loaderWrapper'}>
                <div className={'loader'}>No data</div>
            </div>
        ) : (
            <Fragment>
                <div style={{ height: '99%' }}>
                    <BarChart {...numOfOrders} />
                </div>
            </Fragment>
        );

    const revenuePerClientTemplate = () => {
        if (userStructureData.noData) {
            return (<div className={'loaderWrapper'}>
                <div className={'loader'}>No data</div>
            </div>)
        }
        const lastPeriod = revenuePerClient.data["Previous"];
        let change = 0;
        if (lastPeriod !== 0) {
            change = (revenuePerClient.data["Total"] - lastPeriod) * 100 / lastPeriod;
        }

        change = change !== 0 ? change.toFixed(1) : 0;
        let changeString = change === 0 ? 'No Change' : (`${change > 0 ? '+' : ''}${change}% ${change > 0 ? 'increase' : 'decrese'} from last period ${revenuePerClient.data["Previous"]} ${config.currency}`);
        return (
            <>
                <div>
                    {revenuePerClient.data["Total"]} {config.currency}
                </div>
                <div className='lastPeriod'>
                    {changeString}
                </div>
            </>)
    }
    const peakRentalHoursTemplate = () => {
        if (peakRentalHours.noData) {
            return (<div className={'loaderWrapper'}>
                <div className={'loader'}>No data</div>
            </div>)
        }
        // create bar and line datasets
        const orders = peakRentalHours.data.find(r => r.dataSet === "Orders").chartData;
        //const occupancy = peakRentalHours.data.find(r => r.dataSet === "Occupancy").chartData;
        const chartData = setChartData(orders);
        //const lineDate = setChartData(occupancy);

        const options = {
            cornerRadius: 4,
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                datalabels: { // datalabels (data on the bar)
                    display: true,
                    color: 'black',
                    align: 'end',
                    anchor: 'end',
                    formatter: (value, { dataIndex }) => {
                        // if (props.title === 'Order length' || props.bottomLineLabels) {
                        //     return props.dataLabelValues[dataIndex]
                        // }
                        // if (props.dataLabelsAppend) {
                        //     return value + props.dataLabelsAppend;
                        // }
                        return value;
                    },
                }
            },
            scales: {
                xAxes: [{
                    display: true,
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        stepSize: 1,
                        autoSkip: true,
                        maxTicksLimit: 7,
                        maxRotation: 0,
                        minRotation: 0
                    },
                    gridLines: {
                        display: false
                    },
                    stacked: false
                }],
                yAxes: [{
                    id: 'y',
                    label: 'y',
                    display: false,
                    gridLines: {
                        display: true
                    },
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        stepSize: 1,
                        autoSkip: true,
                        maxTicksLimit: 7
                    },
                    stacked: false
                }, {
                    id: 'y1',
                    label: 'y1',
                    display: false,
                    gridLines: {
                        display: true
                    },
                    position: 'right',
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 100,
                        stepSize: 20,
                        autoSkip: true,
                        maxTicksLimit: 100
                    },
                    stacked: false
                }],


            }
        };

        const data = {
            datasets: [
                // {
                //     type: 'line',
                //     barPercentage: 1,
                //     data: lineDate.data.map(l => l.toFixed(0)),
                //     backgroundColor: '#000000',
                //     borderWidth: 4,
                //     borderColor: `#000000`,
                //     fill: false,
                //     label: 'Occupancy - % of available lockers in use',
                //     yAxisID: 'y1',
                //     pointRadius: 0
                // }, 
                {
                    type: 'bar',
                    barPercentage: 1,
                    data: chartData.data,
                    backgroundColor: '#DFD9CB',
                    borderWidth: 0,
                    label: 'Orders started at time of day',
                    yAxisID: 'y'
                },

            ],
            labels: chartData.labels // axis labels
        };
        return (

            <Bar data={data} options={options} />
        )

    }

    const orderLengthTemplate = () => {
        if (orderLength.noData) {
            return (<div className={'loaderWrapper'}>
                <div className={'loader'}>No data</div>
            </div>)
        }
        const current = orderLength.data.find(d => d.label === "Average");
        const lastPeriod = orderLength.data.find(d => d.label === "PreviousAvg");

        let change = 0;
        if (lastPeriod.value !== 0) {
            change = (current.value - lastPeriod.value) * 100 / lastPeriod.value;
        }

        change = change !== 0 ? change.toFixed(1) : 0;
        let changeString = change === 0 ? 'No Change' : (`${change > 0 ? '+' : ''}${change}% ${change > 0 ? 'increase' : 'decrese'} from last period ${lastPeriod.displayText}`);

        return (
            <>
                <div>
                    {current.displayText}
                </div>
                <div className='lastPeriod'>
                    {changeString}
                </div>
            </>
        )
    }

    const numberOfOrdersTemplate = () => {
        if (orderLength.noData || !orderLength.data) {
            return (<div className={'loaderWrapper'}>
                <div className={'loader'}>No data</div>
            </div>)
        }
        const current = orderLength.data.find(d => d.label === "Count");
        const lastPeriod = orderLength.data.find(d => d.label === "PreviousCount");

        let change = 0;
        if (lastPeriod.value !== 0) {
            change = (current.value - lastPeriod.value) * 100 / lastPeriod.value;
        }

        change = change !== 0 ? change.toFixed(1) : 0;
        let changeString = change === 0 ? 'No Change' : (`${change > 0 ? '+' : ''}${change}% ${change > 0 ? 'increase' : 'decrese'} from last period ${lastPeriod.displayText}`);

        return (
            <>
                <div>
                    {current.displayText}
                </div>
                <div className='lastPeriod'>
                    {changeString}
                </div>
            </>
        )
    }

    const userStructureListTemplate = () => {
        if (userStructureData.noData) {
            return (<div className={'loaderWrapper'}>
                <div className={'loader'}>No data</div>
            </div>)
        }
        return (
            <Table sx={{ minWidth: 650 }} size='small'>
                <TableHead sx={{ fontSize: 24, backgroundColor: '#000500' }}>
                    <TableRow >
                        <TableCell sx={{ fontSize: 24 }}>User base</TableCell>
                        <TableCell sx={{ fontSize: 24 }} align="right">Number</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userStructureData.data.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    const transactionListTemplate = () => {
        if (transactionData.noData) {
            return (<div className={'loaderWrapper'}>
                <div className={'loader'}>No data</div>
            </div>)
        }
        return (
            <Table sx={{ minWidth: 650, overflow: 'overlay' }} size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>OrderId</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell align="right">Revenue</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactionData.data.map((row) => (
                        <TableRow
                            key={row.OrderId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {(new Date(row.date)).toLocaleString()}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.orderId}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.status}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.amount}
                            </TableCell>
                            <TableCell align="right">{row.revenue}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    const topLocationListTemplate = () => {
        if (topLocationsData.noData) {
            return (<div className={'loaderWrapper'}>
                <div className={'loader'}>No data</div>
            </div>)
        }
        return (
            <Table sx={{ minWidth: 650, overflow: 'overlay' }} size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Location</TableCell>
                        <TableCell>Order Counts</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {topLocationsData.data.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.count}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    return (
        <div className='container'>
            <div className={'header'}>
                <div>
                    {clients.length > 0 &&
                        <div style={{ marginRight: '70px' }}>
                            <Autocomplete
                                id="clientsAutocomplete"
                                options={clients}
                                defaultValue={clients[0]}
                                disableClearable
                                getOptionLabel={option => option.name}
                                style={{ width: (permissions === 'admin') ? 200 : 300 }}
                                renderInput={params => <TextField {...params} label="Client" variant="standard" />}
                                onChange={clientFilterChange}
                                key={resetClients}
                                disabled={permissions === 'client' || clientsDisabled}
                            />
                        </div>
                    }
                    <div style={{ marginRight: '30px' }}>
                        {locations.length > 0 &&
                            <Autocomplete
                                id="locationsAutocomplete"
                                options={locations}
                                defaultValue={locations[0]}
                                disableClearable
                                getOptionLabel={option => option.name}
                                style={{ width: (permissions === 'admin') ? 200 : 300 }}
                                renderInput={params => <TextField {...params} label="Location" variant="standard" />}
                                onChange={locationFilterChange}
                            />
                        }
                    </div>
                    {((selectedClient.id || selectedLocation.id) && showClearFilter && permissions === 'admin') && (
                        <div
                            onClick={onClearFilters}
                            className={'clearFilter'}
                        >
                            <ClearIcon className={'clearIcon'} />
                            <p>
                                Clear filters
                            </p>
                        </div>
                    )}
                </div>

                <div>
                    <CustomTabs
                        tabsData={tabsData}
                        defaultTab={0}
                        activeTab={activeTabCallback}
                        datePickerOpen={(value) => setDatePickerOpen(value)}
                        pickerOpen={datePickerOpen}
                        tabStyle={{ minWidth: 85, width: 'auto' }}
                        customRange={true}
                    />
                </div>

                {datePickerOpen &&
                    <div className={'dateRangeContainer'}>
                        <DateRangePicker
                            startDate={dateRange.startDate} // momentPropTypes.momentObj or null,
                            startDateId="startDate" // PropTypes.string.isRequired,
                            endDate={dateRange.endDate} // momentPropTypes.momentObj or null,
                            endDateId="endDate" // PropTypes.string.isRequired,
                            onDatesChange={({ startDate, endDate }) => setDateRange({ startDate, endDate })} // PropTypes.func.isRequired,
                            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={input => setFocusedInput(input)} // PropTypes.func.isRequired,
                            isOutsideRange={() => false}
                            firstDayOfWeek={1}
                            displayFormat={() => "DD/MM/YYYY"}
                            onClose={(dates) => handleDateChange(dates)}
                        />
                    </div>
                }
            </div>

            <div className='charts-container'>
                <div className='main-charts'>
                    <Card variant="outlined" className={'chart historyChart'}>
                        {loader1 ? (
                            <div className={'loaderWrapper'}>
                                <CircularProgress className={'loader'} />
                            </div>
                        ) : numOfOrdersTemplate()}
                    </Card>

                    <div className='small-card-container'>
                        <Card variant="outlined" className={'small-card'}>
                            <span className='card-header'>Revenue</span>
                            {loader5 ? (
                                <div className={'loaderWrapper'}>
                                    <CircularProgress className={'loader'} />
                                </div>
                            ) : revenuePerClientTemplate()}
                        </Card>
                        <Card variant="outlined" className={'small-card'}>
                            <span className='card-header'>Average parked duration</span>
                            {loader3 ? (
                                <div className={'loaderWrapper'}>
                                    <CircularProgress className={'loader'} />
                                </div>
                            ) : orderLengthTemplate()}
                        </Card>
                        <Card variant="outlined" className={'small-card'}>
                            <span className='card-header'>Number of orders</span>
                            {loader4 ? (
                                <div className={'loaderWrapper'}>
                                    <CircularProgress className={'loader'} />
                                </div>
                            ) : numberOfOrdersTemplate()}
                        </Card>
                    </div>
                    <div>
                    <Card variant="outlined" className={'chart transactionTable'}>
                        <span className='card-header'>Transactions</span>
                        <div className='card-content'>
                            {loader6 ? (
                                <div className={'loaderWrapper'}>
                                    <CircularProgress className={'loader'} />
                                </div>
                            ) : transactionListTemplate()}
                        </div>
                    </Card>
                    </div>

                </div>
                <div className='side-charts'>
                    <Card variant='outlined' className={'chart transactionTable'}>
                        <span className='card-header'>Top 10 Locations</span>
                        <div className='card-content'>
                            {loader7 ? (<div className={'loaderWrapper'}>
                                <CircularProgress className={'loader'} />
                            </div>) : topLocationListTemplate()}
                        </div>
                    </Card>
                    <Card variant="outlined" className={'chart orderStructure'}>
                        <span className='card-header'>Users</span>
                        {loader4 ? (
                            <div className={'loaderWrapper'}>
                                <CircularProgress className={'loader'} />
                            </div>
                        ) : userStructureListTemplate()}
                    </Card>
                    <Card variant="outlined" className={'chart peakRental'}>
                        <span className='card-header'>Usage structure</span>
                        {loader2 ? (
                            <div className={'loaderWrapper'}>
                                <CircularProgress className={'loader'} />
                            </div>
                        ) : peakRentalHoursTemplate()}
                    </Card>
                </div>
            </div>
        </div >
    );
};

export default Dashboard;
