import React from 'react'
import { useSelector } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles, Button } from '@material-ui/core';
import { darkTheme, lightTheme } from "../layout/themes";

const CustomButton = props => {
    const theme = useSelector(state =>
        state.theme === "dark" ? darkTheme.palette : lightTheme.palette
    );

    const useStyles = makeStyles({
        fullWidth: { marginTop: '20px', width: '100%', display: 'flex', justifyContent: 'flex-start' },
        button: {
            height: '36px', width: '', borderRadius: '5px', cursor: 'pointer', fontWeight: 700, outline: 'none', boxShadow: 'none', '&:hover': { boxShadow: 'none' }
        },
        primary: {
            backgroundColor: theme.primary.main, color: 'white', border: 'none', padding: '5px 23px',
            '&:hover': { opacity: .9, backgroundColor: theme.primary.main}
        },
        secondary: {
            backgroundColor: '#62c3333b', color: theme.primary.main, border: `1px solid ${theme.primary.main}`, width: 104,
            '&:hover': { backgroundColor: '#62c33352' }
        },
        warning: {
            backgroundColor: 'transparent', color: theme.warning.main, border: `1px solid rgba(220, 0, 78, 0.5)`, width: 150,
            '&:hover': { backgroundColor: 'rgba(220, 0, 78, 0.1)' }
        },
        default: {
            backgroundColor: '#fff', border: `1px solid ${theme.secondary.main}`, color: theme.secondary.main, width: 104,
            '&:hover': { backgroundColor: '#eee' }
        },
        disabled: { backgroundColor: 'rgba(0, 0, 0, 0.12)', pointerEvents: 'none', color: 'rgba(0, 0, 0, 0.26)' }
    });

    const classes = useStyles();
    let template = <button className={`${classes.button} ${classes[props.type]} ${props.disabled && classes.disabled}`} style={props.style} onClick={props.onClick} disabled={props.disabled}>{props.label}</button>;

    if (props.fullWidth) {
        template =
            <div className={classes.fullWidth}>
                <button className={`${classes.button} ${classes[props.type]} ${props.disabled && classes.disabled}`} style={props.style} onClick={props.onClick} disabled={props.disabled}>{props.label}</button>
            </div>;
    }

    if (props.type === 'save') {
        template =
            <Button
                variant="contained"
                disabled={props.disabled}
                className={`${classes.button} ${classes.primary} ${props.disabled && classes.disabled}`}
                startIcon={<SaveIcon />}
                style={{ fontSize: '.875rem', width: 103 }}
                onClick={props.onClick}
            >
                {props.label}
            </Button>
    }

    return template;
}

export default CustomButton;
