const typography = {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
        'Roboto',
        '-apple-system',
        '"Segoe UI"',
        'Arial',
        'sans-serif',
    ].join(','),
};

export const lightTheme = {
    palette: {
        background: {
            paper: "#fff",
            default: "#fff"
        },
        primary: {
            main: '#62C333'
        },
        secondary: {
            main: '#008a38'
        },
        warning: {
            main: '#dc004e'
        },
        sidebarColor: '#D1E5C7',
        menuItemsColor: '#899F7F',
        errorRed: '#db574d'
    },
    typography: typography,
    overrides: {
        MuiPopover: {
            root: {
                paper: {
                    width: '205px',
                    minHeight: '150px'
                }
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiListItem: {
            button: {
                '&:hover': {
                    backgroundColor: '#60BC34 !important'
                }
            }
        },
        MuiMenu: {
            paper: {
                marginTop: 34
            }
        },
        MuiMenuItem: {
            root: {
                paddingTop: '10px !important',
                paddingBottom: '10px !important'
            },
            dense: {
                fontSize: '0.92rem'
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: 'none !important'
            },
            elevation8: {
                maxHeight: '22% !important'
            },
            root: {
                // paddingRight: '0 !important'
            }
        },
        RaMenuItemLink: {
            active: {
                backgroundColor: '#62C333 !important',
                color: 'white !important',
                '& svg.MuiSvgIcon-root': {
                    color: 'white !important'
                }
            },
            '&:hover': {
                color: 'white !important'
            }
        },
        MuiSvgIcon: {
            root: {
                fontSize: '1.6rem'
            }
        },
        RaListToolbar: {
            toolbar: {
                justifyContent: 'flex-start',
                marginBottom: '15px',
                marginTop: '0'
            }
        },
        RaList: {
            main: {
                border: '1px solid rgba(224, 224, 224, 1)',
                borderRadius: 4
            }
        },
        RaAppBar: {
            toolbar: {
                '& button': {
                    '&:not(:nth-child(1))': {
                        display: 'none'
                    }
                },
                '& div': {
                    '& button': {
                        padding: '0 20px',
                        borderRadius: '0',
                        backgroundColor: '#431A13'
                    }
                },
                paddingRight: '0'
            }
        },
        RaFilterFormInput: {
            spacer: {
                display: 'none'
            }
        },
        MuiDrawer: {
            docked: {
                position: 'sticky',
                top: 72
            }
        },
        MuiButton: {
            root: {
                textTransform: 'none'
            },
            containedPrimary: {
                color: '#fff',
                backgroundColor: '#62C333',
                boxShadow: 'none',
                // width: '104px',
                borderRadius: '5px',
                '&:hover': {
                    backgroundColor: '#62C333',
                    opacity: .9,
                    boxShadow: 'none'
                }
            }
        },
        MuiToolbar: {
            gutters: {
                padding: '0 !important'
            }
        },
        RaToolbar: {
            defaultToolbar: {
                justifyContent: 'flex-end'
            }
        },
        RaBulkActionsToolbar: {
            toolbar: {
                paddingLeft: '15px !important',
                paddingRight: '15px !important'
            }
        },
        RaSaveButton: {
            button: {
                marginTop: 50,
                marginRight: 16,
                marginBottom: 20,
                float: 'right'
            }
        },
        MuiInputBase: {
            input: {
                whiteSpace: 'pre-line'
            }
        },
        RaBulkDeleteWithUndoButton: {
            deleteButton: {
                color: '#db574d'
            }
        },
        RaDeleteWithUndoButton: {
            deleteButton: {
                color: '#db574d'
            }
        },
        RaAutocompleteSuggestionList: {
            suggestionsContainer: {
                boxShadow: '-9px 3px 28px -4px rgba(145,145,145,1)'
            }
        }
    }
};

//to find other colors selectors see https://material-ui.com/customization/palette/
export const darkTheme = {
    palette: {
        type: 'dark', // Switching the dark mode on is a single property value change.
        primary: {
            main: '#607D8B', // tab-navigation-border, selected-value, checkboxes, button-hover
            // dark: '#002884', // toolbar-top, button-hover
            contrastText: '#fff'
        },
        secondary: { // used to style action buttons & grid links
            main: '#607D8B'
        },
        warning: {
            main: '#dc004e'
        },
        sidebarColor: '',
        menuItemsColor: '#fff',
        errorRed: '#db574d'
    },
    typography: typography,
    overrides: {
        MuiPopover: {
            root: {
                paper: {
                    width: '205px',
                    minHeight: '150px'
                }
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiButton: { // override the styles of all instances of this component
            textPrimary: { // Name of the rule
                color: '#fff', // Some CSS
            },
            root: {
                textTransform: 'none'
            },
            containedPrimary: {
                color: '#fff',
                backgroundColor: '#62C333',
                boxShadow: 'none',
                // width: '104px',
                borderRadius: '5px',
                '&:hover': {
                    backgroundColor: '#62C333',
                    opacity: .9,
                    boxShadow: 'none'
                }
            }
        },
        MuiDrawer: {
            docked: {
                position: 'sticky',
                top: 72
            }
        },
        MuiTableCell: {
            sizeSmall: {
                '& a': { // links inside grid
                    color: '#fff'
                }
            }
        },
        RaBulkDeleteWithUndoButton: {
            deleteButton: {
                color: '#db574d'
            }
        },
        RaDeleteWithUndoButton: {
            deleteButton: {
                color: '#db574d'
            }
        },
        MuiListItem: {
            button: {
                '&:hover': {
                    backgroundColor: '#454545 !important'
                }
            }
        },
        MuiMenuItem: {
            root: {
                paddingTop: '10px !important',
                paddingBottom: '10px !important'
            },
            dense: {
                fontSize: '0.92rem'
            }
        },
        RaMenuItemLink: {
            active: {
                backgroundColor: '#454545 !important'
            }
        },
        MuiSvgIcon: {
            root: {
                fontSize: '1.6rem'
            }
        },
        RaListToolbar: {
            toolbar: {
                justifyContent: 'flex-start',
                marginBottom: '15px',
                marginTop: '0'
            }
        },
        RaFilterFormInput: {
            spacer: {
                display: 'none'
            }
        },
        RaAppBar: {
            toolbar: {
                '& button': {
                    '&:not(:nth-child(1))': {
                        display: 'none'
                    }
                },
                '& div': {
                    '& button': {
                        padding: '0 20px',
                        borderRadius: '0',
                        backgroundColor: '#4b6471'
                    }
                },
                paddingRight: '0'
            }
        },
        MuiPaper: {
            root: {
                // paddingRight: '0 !important'
            },
            elevation8: {
                maxHeight: '22% !important'
            }
        },
        MuiInputBase: {
            input: {
                whiteSpace: 'pre-line'
            }
        },
        MuiToolbar: {
            gutters: {
                padding: '0 !important'
            }
        },
        RaToolbar: {
            defaultToolbar: {
                justifyContent: 'flex-end'
            }
        },
        RaBulkActionsToolbar: {
            toolbar: {
                paddingLeft: '15px !important',
                paddingRight: '15px !important'
            }
        },
        RaSaveButton: {
            button: {
                marginTop: 20,
                marginRight: 16,
                marginBottom: 20,
                float: 'right'
            }
        },
        RaAutocompleteSuggestionList: {
            suggestionsContainer: {
                boxShadow: '-9px 3px 28px -4px rgba(145,145,145,1)'
            }
        }
    }
};