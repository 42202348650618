import { Card, makeStyles, TextField as TextFieldMaterial } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import keyBy from 'lodash/keyBy';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Datagrid, Pagination, TextField, useQuery } from 'react-admin';
import { DateRangePicker } from 'react-dates';
import { useHistory } from 'react-router-dom';
import config from '../../config/config';
import { setChartData } from '../../utils/charts';
import dates from '../../utils/dates';
import PieChart from '../charts/PieChart';
import CustomLabel from '../shared/CustomLabel';
import CustomTabs from '../shared/CustomTabs';
import { useSelector, useDispatch } from 'react-redux';
import { updateFilters } from '../../redux/diagnostics/actions';


const useStyles = makeStyles({
    chart: {
        width: '49%',
        height: 300,
        maxHeight: 300,
        maxWidth: 752,
        minWidth: 300,
        margin: '20px 0',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.1), 0px 1px 3px 0px rgba(0,0,0,0.1)',
    },
    loaderWrapper: { width: '100%', height: '100%', display: 'flex' },
    loader: { height: 100, margin: 'auto', width: 100 },
    dateRangeContainer: {
        top: 185, position: 'absolute', right: '5%', zIndex: 3,
    },
    chartsSection: {
        margin: '20px 0'
    },
    locationFilter: {
        width: 250,
        height: 75,
        '& .MuiAutocomplete-inputRoot': {
            height: 50
        }
    },
    filters: {
        display: 'flex',
        flexDirection: 'row'
    }
});

const SuccessLabel = () => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            Successes <CheckCircleOutlinedIcon fontSize={'small'} style={{ color: '#62C333', paddingLeft: 2 }} />
        </div>
    )
};

const WarningLabel = () => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            Warnings <ErrorOutlineOutlinedIcon fontSize={'small'} style={{ color: '#FFC107', paddingLeft: 2 }} />
        </div>
    )
};

const ErrorLabel = () => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            Errors <ErrorIcon fontSize={'small'} style={{ color: '#F44336', paddingLeft: 2 }} />
        </div>
    )
};

const ItemsList = props => {
    const classes = useStyles();
    const token = localStorage.getItem('access_token');
    const history = useHistory();
    const dispatch = useDispatch();
    const diagnosticsFilters = useSelector(state => state.diagnostics);

    const [pieChartOptions, setPieChartOptions] = useState({
        title: 'All doors summary',
        backgroundColor: ['#ffc847', '#df4c52', '#7dd181'], // warning / error / success
        percentageDataValues: true
    });

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sort, setSort] = useState({ field: 'id', order: 'DESC' });
    const [pieChartLoader, setPieChartLoader] = useState(false);
    const [locations, setLocations] = useState([]);

    const [dateRange, setDateRange] = useState({
        startDate: diagnosticsFilters.activeTab === 4 ? diagnosticsFilters.from : moment().startOf('day'),
        endDate: diagnosticsFilters.activeTab === 4 ? diagnosticsFilters.to : null
    });

    const [locationIdFilter, setLocationIdFilter] = useState(diagnosticsFilters.locationId);
    const [filterParams, setFilterParams] = useState({
        locationId: diagnosticsFilters.locationId,
        usedFrom: diagnosticsFilters.activeTab !== 1 ? diagnosticsFilters.from : dateRange.startDate,
        usedTo: diagnosticsFilters.to
    });

    const [activeTab, setActiveTab] = useState(diagnosticsFilters.activeTab);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [focusedInput, setFocusedInput] = useState(null);
    const [defaultLocation, setDefaultLocation] = useState(null);

    const tabsData = [
        { label: 'All time' },
        { label: 'Today' },
        { label: 'This week' },
        { label: 'This month' },
        { label: 'Custom rangeee', hidden: true }
    ];

    const formFilterRules = (locationId, usedFrom, usedTo) => {
        const locationFilter = {
            field: 'Door.LocationId',
            operator: 'equals',
            value: locationId
        };
        const usedFromFilter = {
            field: 'UsedFrom',
            operator: 'gte',
            value: usedFrom
        };
        const usedToFilter = {
            field: 'UsedTo',
            operator: 'lte',
            value: usedTo
        };

        const filterRules = [];
        if (locationId) filterRules.push(locationFilter);
        if (usedFrom) filterRules.push(usedFromFilter);
        if (usedTo) filterRules.push(usedToFilter);
        return filterRules
    };

    const { data, total, loading, error } = useQuery({
        type: 'GET_LIST',
        resource: 'diagnostic',
        payload: {
            pagination: { page, perPage },
            sort,
            filterRules: formFilterRules(diagnosticsFilters.locationId, filterParams.usedFrom, filterParams.usedTo),
        }
    }, [diagnosticsFilters]);

    useQuery(
        {
            type: 'GET_LIST',
            resource: 'locations',
            payload: {
                pagination: {
                    page: 1,
                    perPage: 1000000
                },
                sort: {
                    field: 'name',
                    order: 'ASC'
                }
            }
        },
        {
            onSuccess: ({ data }) => {
                const loc = data.filter(item => item.id === diagnosticsFilters.locationId);
                if (loc.length > 0) {
                    setDefaultLocation(loc[0]);
                } 
                setLocations([{ name: 'All' }, ...data]);
            }
        }
    );

    const changePerPage = (value) => {
        setPage(1);
        setPerPage(value);
    };

    const changeSort = (field) => {
        setPage(1);
        const order = sort.order === 'ASC' ? 'DESC' : 'ASC';
        setSort({ field, order });
    };

    const fetchPieChartData = () => {
        setPieChartLoader(true);
        const endpoint = `${config.apiBaseUrl}/Diagnostic/GetDiagnosticStatistic`;
        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                from: filterParams?.usedFrom,
                to: filterParams?.usedTo,
                locationId: locationIdFilter
            })
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                const result = response.result;
                let chartData = setChartData(result);
                let noData = true;
                chartData.data.forEach(item => {
                    if (item > 0) noData = false;
                });
                setPieChartOptions({ ...pieChartOptions, ...chartData, noData });
                setPieChartLoader(false);
            });
    };

    const SummaryChart = () => {
        return (
            pieChartOptions.noData ?
                <div className={classes.loaderWrapper}>
                    <div className={classes.loader}>No data</div>
                </div>
                :
                <PieChart {...pieChartOptions} />
        )
    };

    const locationFilterChange = (e, value) => {
        let location = value;

        setPage(1);
        setLocationIdFilter(location?.id);
        setFilterParams({
            locationId: location?.id,
            usedFrom: filterParams.usedFrom,
            usedTo: filterParams.usedTo
        });
    };

    const onFilterTabChange = (value) => {
        setActiveTab(value);

        if (value !== 4) {
            const filter = getActiveFilter(value);
            setPage(1);
            setFilterParams({
                locationId: diagnosticsFilters.locationId,
                usedFrom: filter.startDate,
                usedTo: filter.endDate
            });
        }
    }

    const onDatepickerClose = ({ startDate, endDate }) => {
        if (startDate && endDate) {
            startDate._d.setHours(0, 0, 0, 0);
            endDate._d.setHours(23, 59, 59, 999);

            setDateRange({ startDate, endDate });
            setPage(1);
            setFilterParams({
                locationId: diagnosticsFilters.locationId,
                usedFrom: startDate,
                usedTo: endDate
            });
            setDatePickerOpen(() => false);
        }
    };

    const getActiveFilter = value => {
        let filter = {};
        switch (value) {
            case 0:
                filter = dates.allTime;
                break;

            case 2:
                filter = dates.thisWeek;
                break;

            case 3:
                filter = dates.thisMonth;
                break;

            case 4:
                const start = dateRange.startDate;
                start._d.setHours(0, 0, 0, 0);

                let end = dateRange.endDate;
                if (!end) end = moment();

                end._d.setHours(23, 59, 59, 999);

                filter = {
                    startDate: start._d.toISOString(),
                    endDate: end._d.toISOString()
                };
                break;

            default:
                filter = getToday();
                break;
        }
        return filter;
    };

    const getToday = () => {
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        return {
            startDate: date.toISOString(),
            endDate: ''
        };
    };

    useEffect(() => {
        dispatch(updateFilters({
            locationId: filterParams.locationId,
            from: filterParams.usedFrom,
            to: filterParams.usedTo,
            activeTab: activeTab
        }));
        fetchPieChartData();
    }, [filterParams]);

    return (
        <>
            <CustomLabel title={'Diagnostics'} style={{ marginBottom: 15 }} />
            <div className={classes.filters}>
                {locations.length > 0 &&
                    <Autocomplete
                        options={locations}
                        defaultValue={defaultLocation || locations[0]}
                        disableClearable
                        getOptionLabel={option => option.name}
                        className={classes.locationFilter}
                        renderInput={params => <TextFieldMaterial {...params} label="Location" variant="outlined" style={{ height: 15 }} />}
                        onChange={locationFilterChange}
                    />
                }
                <div style={{ marginLeft: 'auto' }}>
                    <CustomTabs
                        tabsData={tabsData}
                        defaultTab={activeTab}
                        activeTab={onFilterTabChange}
                        datePickerOpen={(value) => setDatePickerOpen(value)}
                        pickerOpen={datePickerOpen}
                        tabStyle={{ minWidth: 85, width: 'auto' }}
                        customRange={true}
                    />
                </div>
                {datePickerOpen &&
                    <div className={classes.dateRangeContainer}>
                        <DateRangePicker
                            startDate={dateRange.startDate} // momentPropTypes.momentObj or null,
                            startDateId="startDate" // PropTypes.string.isRequired,
                            endDate={dateRange.endDate} // momentPropTypes.momentObj or null,
                            endDateId="endDate" // PropTypes.string.isRequired,
                            onDatesChange={({ startDate, endDate }) => setDateRange({ startDate, endDate })} // PropTypes.func.isRequired,
                            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={input => setFocusedInput(input)} // PropTypes.func.isRequired,
                            isOutsideRange={() => false}
                            firstDayOfWeek={1}
                            displayFormat={() => "DD/MM/YYYY"}
                            onClose={(val) => onDatepickerClose(val)}
                        />
                    </div>
                }
            </div>
            {data?.length ?
                <>
                    <Datagrid
                        rowClick={id => history.push(`/diagnostic/${id}/show`)}
                        data={keyBy(data, 'id')}
                        ids={data.map(({ id }) => id)}
                        currentSort={sort}
                        setSort={changeSort}
                        style={{ border: '1px solid rgba(224, 224, 224, 1)' }}
                    >
                        <TextField source="id" label="ID" />
                        <TextField source="door.name" label="Door" />
                        <TextField source="location.name" label="Location" />
                        <TextField source="successes" label={<SuccessLabel />} sortable={false} />
                        <TextField source="warnings" label={<WarningLabel />} sortable={false} />
                        <TextField source="errors" label={<ErrorLabel />} sortable={false} />
                    </Datagrid>
                    <Pagination
                        page={page}
                        perPage={perPage}
                        setPage={setPage}
                        setPerPage={changePerPage}
                        total={total}
                        style={{ border: '1px solid rgba(224, 224, 224, 1)', borderTop: 'none' }}
                    />
                    <div className={`${classes.chartsSection} charts`}>
                        <CustomLabel title={'Charts'} style={{ marginBottom: 15 }} />
                        <Card variant="outlined" className={classes.chart}>
                            {pieChartLoader ? (
                                <div className={classes.loaderWrapper}>
                                    <CircularProgress className={classes.loader} />
                                </div>
                            ) : <SummaryChart />}
                        </Card>
                    </div>
                </> : <div style={{ margin: 'auto', marginTop: '10%' }}>No diagnostics data</div>
            }
        </>
    )
};

export default ItemsList;
