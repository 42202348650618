import { Card, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import FalseIcon from '@material-ui/icons/CancelOutlined';
import TrueIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import keyBy from 'lodash/keyBy';
import moment from 'moment';
import momentz from 'moment-timezone';
import React, { useEffect, useState } from "react";
import { Datagrid, Pagination, TextField, useQuery } from "react-admin";
import { DateRangePicker } from 'react-dates';
import { useRouteMatch } from 'react-router-dom';
import config from "../../config/config";
import { setChartData } from "../../utils/charts";
import dates from '../../utils/dates';
import PieChart from "../charts/PieChart";
import CustomLabel from "../shared/CustomLabel";
import CustomTabs from '../shared/CustomTabs';
import { useSelector } from 'react-redux';


const useStyles = makeStyles({
    chart: {
        width: '49%',
        height: 300,
        maxHeight: 300,
        maxWidth: 752,
        minWidth: 300,
        margin: '20px 0',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.1), 0px 1px 1px 0px rgba(0,0,0,0.1), 0px 1px 3px 0px rgba(0,0,0,0.1)',
    },
    loaderWrapper: { width: '100%', height: '100%', display: 'flex' },
    loader: { height: 100, margin: 'auto', width: 100 },
    dateRangeContainer: {
        top: 185, position: 'absolute', right: '5%', zIndex: 3,
    },
    chartsSection: { margin: '20px 0' },
    filters: {
        display: 'flex',
        flexDirection: 'row',
        height: 75
    }
});

export default function ItemShow(props) {
    const classes = useStyles();
    const token = localStorage.getItem('access_token');
    const route = useRouteMatch();
    const doorId = route.params.id;
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sort, setSort] = useState({ field: 'id', order: 'DESC' });
    const diagnosticsFilters = useSelector(state => state.diagnostics);

    const [dateRange, setDateRange] = useState({
        startDate: diagnosticsFilters.activeTab === 4 ? diagnosticsFilters.from : moment().startOf('day'),
        endDate: diagnosticsFilters.activeTab === 4 ? diagnosticsFilters.to : null
    });
    const [filterParams, setFilterParams] = useState({
        doorId,
        usedFrom: diagnosticsFilters.activeTab !== 1 ? diagnosticsFilters.from : dateRange.startDate,
        usedTo: diagnosticsFilters.to
    });

    const [activeTab, setActiveTab] = useState(diagnosticsFilters.activeTab);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [focusedInput, setFocusedInput] = useState(null);

    const [pieChartOptions, setPieChartOptions] = useState({
        title: 'Summary',
        backgroundColor: ['#ffc847', '#df4c52', '#7dd181'], // warning / error / success
        percentageDataValues: true
    });
    const [pieChartLoader, setPieChartLoader] = useState(false);

    const tabsData = [
        { label: 'All time' },
        { label: 'Today' },
        { label: 'This week' },
        { label: 'This month' },
        { label: 'Custom rangeee', hidden: true }
    ];

    const formFilterRules = (usedFrom, usedTo) => {
        const doorFilter = {
            field: 'DoorId',
            operator: 'equals',
            value: doorId
        };
        const usedFromFilter = {
            field: 'CreationTime',
            operator: 'gte',
            value: usedFrom
        };
        const usedToFilter = {
            field: 'CreationTime',
            operator: 'lte',
            value: usedTo
        };

        const filterRules = [];
        filterRules.push(doorFilter);
        if (usedFrom) filterRules.push(usedFromFilter);
        if (usedTo) filterRules.push(usedToFilter);
        return filterRules;
    };

    const fetchPieChartData = () => {
        setPieChartLoader(true);
        const endpoint = `${config.apiBaseUrl}/Diagnostic/GetDiagnosticStatistic`;
        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                DoorId: doorId,
                from: filterParams?.usedFrom,
                to: filterParams?.usedTo
            })
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                const result = response.result;
                let chartData = setChartData(result);
                let noData = true;
                chartData.data.forEach(item => {
                    if (item > 0) noData = false;
                });
                setPieChartOptions({ ...pieChartOptions, ...chartData, noData });
                setPieChartLoader(false);
            });
    };

    const { data, total, loading, error } = useQuery({
        type: 'GET_LIST',
        resource: 'getDiagnosticsPerDoor',
        payload: {
            pagination: { page, perPage },
            sort,
            filterRules: formFilterRules(filterParams.usedFrom, filterParams.usedTo)
        }
    }, [filterParams]);

    const changePerPage = (value) => {
        setPage(1);
        setPerPage(value);
    };

    const changeSort = (field) => {
        setPage(1);
        const order = sort.order === 'ASC' ? 'DESC' : 'ASC';
        setSort({ field, order });
    };

    const onFilterTabChange = (value) => {
        setActiveTab(value);

        if (value !== 4) {
            const filter = getActiveFilter(value);
            setFilterParams({
                usedFrom: filter.startDate,
                usedTo: filter.endDate
            });
        }
    }

    const onDatepickerClose = ({ startDate, endDate }) => {
        if (startDate && endDate) {
            startDate._d.setHours(0, 0, 0, 0);
            endDate._d.setHours(23, 59, 59, 999);

            setDateRange({ startDate, endDate });

            setFilterParams({
                usedFrom: startDate,
                usedTo: endDate
            });
            setDatePickerOpen(() => false);
        }
    };

    const getActiveFilter = value => {
        let filter = {};
        switch (value) {
            case 0:
                filter = dates.allTime;
                break;

            case 2:
                filter = dates.thisWeek;
                break;

            case 3:
                filter = dates.thisMonth;
                break;

            case 4:
                const start = dateRange.startDate;
                start._d.setHours(0, 0, 0, 0);

                let end = dateRange.endDate;
                if (!end) end = moment();

                end._d.setHours(23, 59, 59, 999);

                filter = {
                    startDate: start._d.toISOString(),
                    endDate: end._d.toISOString()
                };
                break;

            default:
                filter = getToday();
                break;
        }
        return filter;
    };

    const getToday = () => {
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        return {
            startDate: date.toISOString(),
            endDate: ''
        };
    };

    useEffect(() => {
        fetchPieChartData();
    }, [filterParams]);

    const SummaryChart = () => {
        return (
            pieChartOptions.noData ?
                <div className={classes.loaderWrapper}>
                    <div className={classes.loader}>No data</div>
                </div>
                :
                <PieChart {...pieChartOptions} />
        )
    }

    const DateTimeField = ({ record = {} }) => {
        const { diagnosticDateTime } = record;

        const timezone = momentz.tz.guess();
        const time = moment.tz(diagnosticDateTime, timezone).format('DD.MM.YYYY, HH:mm:ss');

        return (
            <span>{time}</span>
        );
    };

    const StatusField = ({ record = {} }) => {
        const { status } = record;

        return (
            <>
                {status === 0 &&
                    <span style={{ color: '#F44336' }}>Error</span>
                }
                {status === 1 &&
                    <span style={{ color: '#FFC107' }}>Warning</span>
                }
                {status === 2 &&
                    <span style={{ color: '#62C333' }}>Success</span>
                }
            </>
        );
    };

    const IsNewUserField = ({ record = {} }) => {
        const { isNewUser } = record;

        return (
            <>
                {isNewUser ?
                    <TrueIcon style={{ color: '#98c9a3', width: 20, height: 20 }} />
                    :
                    <FalseIcon style={{ color: '#b1a7a6', width: 20, height: 20 }} />

                }
            </>
        )
    };

    return (
        <>
            <CustomLabel title={`Diagnostics - door id: ${doorId}`} style={{ marginBottom: 20 }} />
            <div className={classes.filters}>
                <div style={{ marginLeft: 'auto' }}>
                    <CustomTabs
                        tabsData={tabsData}
                        defaultTab={activeTab}
                        activeTab={onFilterTabChange}
                        datePickerOpen={(value) => setDatePickerOpen(value)}
                        pickerOpen={datePickerOpen}
                        tabStyle={{ minWidth: 85, width: 'auto' }}
                        customRange={true}
                    />
                </div>
                {datePickerOpen &&
                    <div className={classes.dateRangeContainer}>
                        <DateRangePicker
                            startDate={dateRange.startDate} // momentPropTypes.momentObj or null,
                            startDateId="startDate" // PropTypes.string.isRequired,
                            endDate={dateRange.endDate} // momentPropTypes.momentObj or null,
                            endDateId="endDate" // PropTypes.string.isRequired,
                            onDatesChange={({ startDate, endDate }) => setDateRange({ startDate, endDate })} // PropTypes.func.isRequired,
                            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={input => setFocusedInput(input)} // PropTypes.func.isRequired,
                            isOutsideRange={() => false}
                            firstDayOfWeek={1}
                            displayFormat={() => "DD/MM/YYYY"}
                            onClose={(val) => onDatepickerClose(val)}
                        />
                    </div>
                }
            </div>
            {(data && data.length) ? (
                <>
                    <Datagrid
                        data={keyBy(data, 'id')}
                        ids={data.map(({ id }) => id)}
                        currentSort={sort}
                        setSort={changeSort}
                        style={{ border: '1px solid rgba(224, 224, 224, 1)' }}
                    >
                        <TextField source="id" label="ID" />
                        <TextField source="doorName" label="Door" />
                        <TextField source="locationName" label="Location" />
                        <DateTimeField source="diagnosticDateTime" locales="nb-NO" />
                        <StatusField label="Status" />
                        <IsNewUserField label="New user" />
                    </Datagrid>
                    <Pagination
                        page={page}
                        perPage={perPage}
                        setPage={setPage}
                        setPerPage={changePerPage}
                        total={total}
                        style={{ border: '1px solid rgba(224, 224, 224, 1)', borderTop: 'none' }}
                    />
                    <div className={`${classes.chartsSection} charts`}>
                        <CustomLabel title={'Charts'} style={{ marginBottom: 15 }} />
                        <Card variant="outlined" className={classes.chart}>
                            {pieChartLoader ? (
                                <div className={classes.loaderWrapper}>
                                    <CircularProgress className={classes.loader} />
                                </div>
                            ) : <SummaryChart />}
                        </Card>
                    </div>
                </>
            ) : <div style={{ margin: 'auto', marginTop: '10%'}}>No diagnostics data</div>}
        </>
    );
};
