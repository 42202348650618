import {
    Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Portal, Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { darkTheme, lightTheme } from "../../components/layout/themes";
import CustomButton from '../../components/shared/CustomButton';
import config from '../../config/config';


const PasswordTooltip = (
    <div style={{ fontSize: '11px' }}>
        <p>Minimum password length is 8 characters.</p>
        <p>Password must contain only alphanumeric characters and any of the special characters - !@#$& -</p>
        <p>Password must contain at least one upper case and one lower case letter.</p>
        <p>Password must contain at least one numeric or special character.</p>
    </div>
);

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ChangePasswordDialog = ({ isOpen, closeDialog, onError, onSave }) => {
    const token = localStorage.getItem('access_token');
    const themeType = useSelector(state => state.theme);
    const theme = themeType === 'light' ? lightTheme : darkTheme;

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [currentPasswordHasError, setCurrentPasswordHasError] = useState(false);
    const [newPasswordHasError, setNewPasswordHasError] = useState(false);
    const [confirmPasswordHasError, setConfirmPasswordHasError] = useState(false);
    
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onSubmit = () => {
        const endpoint = `${config.apiBaseUrl}/Profile/ChangePassword`;
        const request = new Request(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                currentPassword,
                newPassword
            })
        });
        fetch(request)
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                    onSave();
                } else {
                    if (response.error?.message === '[Identity.Password mismatch]') {
                        setErrorMessage('Current password is incorrect');
                        setCurrentPasswordHasError(true);
                    } else {
                        setErrorMessage(response.error?.message);
                    }
                    setErrorSnackbarOpen(true);
                }
            })
            .catch(error => {
                onError('An error occurred, please try again later.');
            });
    };

    const resetValidations = () => {
        setCurrentPasswordHasError(false);
        setNewPasswordHasError(false);
        setConfirmPasswordHasError(false);
    };

    const validate = () => {
        const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[.#$!*@&])[A-Za-z\d#$.!*@&]{8,}$|^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])[A-Za-z\d#.$!*@&]{8,}$/;
        resetValidations();

        if (currentPassword.length === 0) {
            setCurrentPasswordHasError(true);
            return false;
        }
        if (!newPassword.match(passRegex)) {
            setNewPasswordHasError(true);
            return false;
        }
        if (newPassword !== confirmPassword) {
            setConfirmPasswordHasError(true);
            return false;
        }

        onSubmit();
    };

    return (
        <>
            <Dialog
                open={isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='sm'
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">Change password</DialogTitle>
                <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
                    <TextField
                        id="standard-password-input"
                        label="Current Password"
                        type="password"
                        error={currentPasswordHasError}
                        required={true}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        style={{ marginBottom: 20, width: 250 }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <TextField
                            id="standard-new-password-input"
                            label="New Password"
                            type="password"
                            error={newPasswordHasError}
                            required={true}
                            onChange={(e) => setNewPassword(e.target.value)}
                            style={{ marginBottom: 20, width: 250 }}
                        />
                        <Tooltip title={PasswordTooltip}>
                            <InfoOutlinedIcon style={{ width: 20, marginLeft: 10 }} />
                        </Tooltip>
                    </div>
                    <TextField
                        id="standard-password-confirm-input"
                        label="Confirm Password"
                        type="password"
                        error={confirmPasswordHasError}
                        required={true}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        style={{ marginBottom: 20, width: 250 }}
                    />
                    <div style={{ color: theme.palette.errorRed, fontSize: 13, marginTop: -10, marginBottom: 10 }}>
                        {confirmPasswordHasError && 'New & confirm passwords do not match.'}
                    </div>
                </DialogContent>
                <DialogActions style={{ paddingBottom: 20, paddingRight: 20, marginTop: 40 }}>
                    <CustomButton type="default" label="Cancel" onClick={closeDialog} />
                    <CustomButton type="primary" label='Change password' onClick={validate} style={{ minWidth: 120, marginLeft: 15 }} />
                </DialogActions>
            </Dialog>
            <Portal>
                <Snackbar
                    open={errorSnackbarOpen}
                    autoHideDuration={4000}
                    onClose={() => setErrorSnackbarOpen(false)}
                >
                    <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </Portal>
        </>
    );
};

export default ChangePasswordDialog;