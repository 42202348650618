export const UPDATE_LOCATION_PRICING = 'UPDATE_LOCATION_PRICING';
export const UPDATE_WAS_ACTIVE = 'UPDATE_WAS_ACTIVE';

export const updateLocationPricing = location => ({
    type: UPDATE_LOCATION_PRICING,
    payload: location
});

export const updateWasActive = isActive => ({
    type: UPDATE_WAS_ACTIVE,
    payload: isActive
});