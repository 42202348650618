import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import RichTextInput from 'ra-input-rich-text';
import React, { Fragment, useState } from "react";
import { BooleanInput, Create, FormDataConsumer, ImageField, ImageInput, NotFound, required, SimpleForm, TextInput } from "react-admin";
import { useSelector } from 'react-redux';
import CustomLabel from '../shared/CustomLabel';
import CreateToolbar from "./components/CreateToolbar";
import ShortDescriptionInput from "./components/ShortDescriptionInput";


const useStyles = makeStyles({
    container: { paddingBottom: 150 },
    richTextInput: { '& .ql-editor': { minHeight: 150 } },
    imageInput: { maxWidth: 200 },
    isPublished: { marginTop: 30 }
});

// const fileSizeValidation = (message = 'Required') =>
//     value => {
//         console.log(value);
//         return undefined
//     }

const AnnouncementsCreate = props => {
    const classes = useStyles();
    const permissions = useSelector(state =>
        state.permissions
    );
    const [scheduledTime, setScheduledTime] = useState(new Date());

    return (
        <Fragment>
            {permissions === 'admin' ? (
                <div className={classes.container}>
                    <CustomLabel title={'New Announcement'} />
                    <Create {...props}>
                        <SimpleForm
                            toolbar={<CreateToolbar scheduledTime={scheduledTime} />}
                        >
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    <ImageInput
                                        source="images"
                                        label="Image"
                                        // maxSize={40000}
                                        // validate={fileSizeValidation()}
                                        placeholder={formData.images?.image ? 'Change image' : 'Drop a picture to upload, or click to select it.'}
                                        className={classes.imageInput}
                                        accept="image/png, image/jpeg"
                                    >
                                        <ImageField source="image" url="image" title="title" />
                                    </ImageInput>
                                }
                            </FormDataConsumer>
                            <TextInput
                                source="title"
                                variant="standard"
                                validate={required()}
                            />
                            <ShortDescriptionInput />
                            <RichTextInput
                                source="content"
                                variant={classes.richTextInput}
                                className={classes.richTextInput}
                                fullWidth={true}
                                validate={required()}
                                toolbar={[
                                    [{ 'size': [] }],
                                    ['bold', 'italic', 'underline'],
                                    [{ 'script': 'super' }, { 'script': 'sub' }],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    ['link'], // 'image'
                                    ['clean']
                                ]}
                            />
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    <>
                                        <BooleanInput source="isScheduled" label="Schedule" disabled={!formData.isPublished} />
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DateTimePicker
                                                autoOk
                                                disablePast={true}
                                                ampm={false}
                                                inputVariant="standard"
                                                value={scheduledTime}
                                                onChange={setScheduledTime}
                                                label="Schedule Time"
                                                validate={required()}
                                                disabled={!formData.isScheduled || !formData.isPublished}
                                                {...rest}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </>
                                }
                            </FormDataConsumer>
                            <BooleanInput source="isPublished" label="Publish" className={classes.isPublished} />
                        </SimpleForm>
                    </Create>
                </div>
            ) : <NotFound />}
        </Fragment>
    );
};

export default AnnouncementsCreate;