import React, { Fragment } from 'react';
import { Bar, HorizontalBar } from "react-chartjs-2";
import config from "../../config/config";

// Do not delete (used to show data labels if datalabels: {display: true })
// eslint-disable-next-line
import ChartDataLabels from 'chartjs-plugin-datalabels';
require('./RoundedBar.js');
/////////////////////         PROPS        /////////////////////
/*

props: {
    //required
    data: [], 
    labels: [], 
    title: strig,
    barColor: string || []
    // optional
    horizontalBar: boolean,
    legend: boolean
    barWidth: 0-1, 
    barBorder: number (px),
    label: string,
    labels: [],
    formatValues: boolean, // 10000 -> 10 000
    maxXTicks: number // used for hiding some labels,
    maxYTicks: number,
    dataLabels: boolean,
    dataLabelsColor: string,
    dataLabelsAlign: 'start' / 'center' / 'end',
    dataLabelsAnchor: 'start' / 'center' / 'end',
    showXAxes: boolean,
    stackedXAxes: boolean,
    hideYAxes: boolean,
    stackedYAxes: boolean,
    hideGrid: boolean,
    titlePosition: 'left' / 'top' / 'right' / 'bottom',
    titleFontSize: number,
    maxValueColor: sztring // if present, max value will have different bar color
}

*/
////////////////////////////////////////////////////////////////

const BarChart = props => {
    let colors = null;

    if (props.maxValueColor) {
        const data = props.data;
        const max = Math.max(...data);

        colors = props.data.map((el, index) => {
            if (el === max) return props.maxValueColor;
            return props.barColor;
        });
    }

    const data = {
        datasets: [{
            barPercentage: props.barWidth || 1, // width of the bar 
            data: props.data, // data (array of values)
            backgroundColor: colors || props.barColor, // colors of the bars (could be array)
            borderWidth: props.barBorder || 0, // border of the bars
            label: props.label
        }],
        labels: props.labels // axis labels
    };

    const options = {
        cornerRadius: props.cornerRadius != null ?  props.cornerRadius : 0,
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            datalabels: { // datalabels (data on the bar)
                display: props.dataLabels || false,
                color: props.dataLabelsColor || 'black',
                align: props.dataLabelsAlign || 'end',
                anchor: props.dataLabelsAnchor || 'end',
                formatter: (value, { dataIndex } ) => {
                    // console.log(value);
                    if (props.title === 'Order length' || props.bottomLineLabels) {
                        return props.dataLabelValues[dataIndex]
                    }
                    if (props.dataLabelsAppend) {
                        return value + props.dataLabelsAppend;
                    }
                    if (props.formatValues) {
                        if (props.title.toLowerCase().includes('revenue')) {
                            const val = Number(value).toLocaleString('no');
                            return `${val} ${config.currency}`;
                        }
                    }
                    return value;
                },
            }
        },
        scales: {
            xAxes: [{
                display: props.showXAxes || false,
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    stepSize: props.xStep || 1,
                    autoSkip: true,
                    maxTicksLimit: props.maxXTicks || null,
                    maxRotation: 0,
                    minRotation: 0
                },
                gridLines: {
                    display: props.showGrid || false
                },
                stacked: props.stackedXAxes || false
            }],
            yAxes: [{
                display: props.hideYAxes ? false : true,
                gridLines: {
                    display: props.hideGrid ? false : true
                },
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    stepSize: props.yStep || 1,
                    autoSkip: true,
                    maxTicksLimit: props.maxYTicks || null
                },
                stacked: props.stackedYAxes || false
            }]
        },
        title: {
            display: props.title ? true : false,
            text: props.title,
            position: props.titlePosition || 'top',
            fontSize: props.titleFontSize || 18
        },
        legend: {
            display: props.legend || false
        },
        layout: {
            padding: props.padding || 30,
        }
    }

    return (
        <Fragment>
            {props.horizontalBar ? (
                <HorizontalBar data={data} options={options} />
            ) : (
                    <Bar data={data} options={options} />
                )}
        </Fragment>
    );
};

export default BarChart;