import React, { useState, Fragment } from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, required, useQuery, useNotify, Toolbar, SaveButton, NotFound } from 'react-admin';

import { useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';

import RichTextInput from 'ra-input-rich-text';
import CustomTitle from '../shared/CustomTitle';
import CustomButton from '../shared/CustomButton';

import { useMediaQuery, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    editToolbar: {
        display: 'flex', justifyContent: 'flex-end', '& button': {
            margin: '0 0 0 20px !important'
        }
    }
});

const EditToolbar = ({ isXsmall, ...props }) => {
    const classes = useStyles();
    const history = useHistory();

    const onCancel = (e) => {
        e.preventDefault();
        history.push('/message-templates');
    };

    return (
        <Toolbar {...props} className={classes.editToolbar} style={{ position: isXsmall ? 'relative' : null, bottom: isXsmall ? '20' : null }}>
            <CustomButton type="default" label="Cancel" onClick={onCancel} />
            <SaveButton />
        </Toolbar>
    );
};

const MessageTemplatesEdit = (props) => {
    const [languages, setLanguages] = useState([]);
    const permissions = useSelector(state =>
        state.permissions
    );

    const isXsmall = useMediaQuery(theme => theme.breakpoints.down("xs"));

    const notify = useNotify();

    const { params: { id } } = useRouteMatch();

    const template = useSelector(state => state.admin.resources['message-templates'].data[id]);
    const name = template ? template.name : '';

    useQuery(
        {
            type: 'GET_FORM_DATA',
            resource: 'Language/GetLanguages'
        },
        {
            onSuccess: ({ data }) => {
                const values = data.map(item => {
                    return {
                        id: item.id,
                        name: item.displayName
                    }
                });
                setLanguages(values);
            },
            onFailure: (error) => notify(error.message, 'warning')
        }
    );

    return (
        <Fragment>
            {permissions === 'admin' ? (
                <Fragment>
                    <CustomTitle title={`Message Template "${name}"`} />
                    <Edit {...props}>
                        <SimpleForm toolbar={<EditToolbar isXsmall={isXsmall} />}>
                            <TextInput
                                source="name"
                                variant="standard"
                                style={{ width: isXsmall ? '80%' : '40%' }}
                                disabled={permissions === 'client'}
                            />
                            <SelectInput
                                source="languageId"
                                variant="standard"
                                choices={languages}
                                disabled={permissions === 'client'}
                            />
                            <TextInput
                                source="subject"
                                label="Template subject"
                                // validate={required()}
                                variant="standard"
                                style={{ width: isXsmall ? '90%' : '40%', marginBottom: '40px' }}
                            />
                            <RichTextInput
                                source="text"
                                label="Template text"
                                validate={required()}
                                variant="standard"
                                fullWidth={true}
                            />
                        </SimpleForm>
                    </Edit>
                </Fragment>
            ) : <NotFound />}
        </Fragment>
    );
}

export default MessageTemplatesEdit;