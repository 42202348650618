import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { SaveButton, Toolbar, useNotify } from "react-admin";
import { useForm, useFormState } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import config from "../../../config/config";
import CustomButton from "../../shared/CustomButton";


const useStyles = makeStyles({
    toolbar: {
        display: 'flex', justifyContent: 'flex-end', alignItems: 'center', position: 'fixed',
        width: '100%', bottom: 0, right: 0, padding: '10px 0!important',
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 25%)'
    }
});

const EditToolbar = ({ basePath, scheduledTime, ...props }) => {
    const classes = useStyles();
    const history = useHistory();
    const notify = useNotify();
    const form = useForm();
    const formState = useFormState();
    const { valid } = formState;

    const onCancel = (e) => {
        e.preventDefault();
        history.push('/announcements');
    };

    const onUpdate = (base64data = null) => {
        let date = new Date(scheduledTime).toISOString();
        if (!formState.values.isScheduled) {
            date = new Date().toISOString();
        }

        const formData = { ...formState.values, scheduledTime: date, image: base64data };
        const token = localStorage.getItem('access_token');
        const endpoint = `${config.apiBaseUrl}/Announcement/Update`;

        const request = new Request(endpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(formData)
        });

        fetch(request)
            .then(res => res.json())
            .then(response => {
                if (response.success) {
                    notify('Announcement saved successfully', 'success');
                    history.push('/announcements');
                }

                if (response && response.error) {
                    notify(`Error: ${response.error.message}`, 'warning');
                }
            })
            .catch(error => {
                notify(`Error: ${error.message}`, 'warning');
            });
    };

    const handleSubmit = () => {
        if (!valid) {
            return form.submit();
        }

        const image = formState.values.image;
        if (typeof image === "string") {
            return onUpdate(image);
        }

        const blob = image?.rawFile;

        if (blob) {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                return onUpdate(base64data);
            }
        } else {
            onUpdate();
        }
    };

    return (
        <div>
            <Toolbar {...props} className={classes.toolbar}>
                <CustomButton type="default" label="Cancel" onClick={onCancel} style={{ marginRight: 20 }} />
                <SaveButton basePath={basePath} handleSubmitWithRedirect={handleSubmit} style={{ margin: 0, marginRight: 40 }} />
            </Toolbar>
        </div>
    );
};

export default EditToolbar;
