import React from 'react';
import { Admin } from 'react-admin';

import { store } from './redux/store';
import { Provider } from 'react-redux';
import { createHashHistory } from 'history';

import { Login, Layout } from './components/layout/index.js';
import useGetResources from './resources/useGetResources';

import authProvider from './auth/authProvider';
import customDataProvider from "./dataProvider/customDataProvider";

import customRoutes from './routes';
//import LiveState from './components/live-status/live';
import Dashboard from './components/dashboard/Dashboard';

const history = createHashHistory();

const App = () => {
    const resources = useGetResources();

    return (
        <Admin
            title="Safe Bikely"
            dataProvider={customDataProvider}
            authProvider={authProvider}
            loginPage={Login}
            layout={Layout}
            customRoutes={customRoutes}
            dashboard={Dashboard}
            history={history}
        >
            {resources}
        </Admin>
    );
};

export default () => <Provider store={store}><App /></Provider>;